import React from 'react';
import PropTypes from 'prop-types';
import axios from "axios";
import {apiHost} from "../../common/config";
import store from "../../store/store";

class FileBox extends React.Component {


    downloadFile() {
        window.open(`${apiHost}/file/download/${this.props.id}`);
    }

    render() {
        const openTypes = ['pdf', 'video', 'image'];

        return (
            <div className="items-result__item rounded-lg" key={`file-${this.props.id}`}>
                {
                    openTypes.includes(this.props.file.type)
                        ?
                        <a href={`./library/${this.props.id}`} target="_blank">
                            <img className="" src={this.props.image}/>
                        </a>
                        :
                            <img className="" src={this.props.image}/>


                }
                <div className="items-result__item-title justify-between">
                    <i className={`icon-file-${this.props.file.type}`}></i>
                    <span>
                        {
                            openTypes.includes(this.props.file.type)
                                ?
                                <a href={`./library/${this.props.id}`} target="_blank">
                                    {this.props.name} <small className="whitespace-no-wrap">({this.props.file.size})</small>
                                </a>
                                :
                                <>
                                    {this.props.name} <small className="whitespace-no-wrap">({this.props.file.size})</small>
                                </>

                        }
                    </span>
                    { this.props.file.canDownload &&

                        <a onClick={() => this.downloadFile()}
                           title="Скачать"
                           className="icon-download ml-auto cursor-pointer"></a>
                    }
                </div>
            </div>
        )
    }
}

FileBox.propTypes = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    file: PropTypes.object.isRequired,
}

FileBox.defaultProps = {
    id: 0,
    name: '',
    image: '',
    file: {
        src: '',
        type: '',
        size: '',
    },
}

export default FileBox;


import React, {Component} from 'react';
import ScrollArea from 'react-scrollbar';
import './widget-news-scroll.scss';
import { connect } from 'react-redux';
import store from '../../store/store';
import axios from 'axios';
import {apiHost} from '../../common/config';

const requestUrl = `${apiHost}/news/get/widget`;

class WidgetNews extends Component
{
    componentDidMount() {
        if (!this.props.news.length) {
            axios.get(
                requestUrl
            ).then(res => {
                store.dispatch({
                    type: 'getNewsWidget',
                    widgetNewsData: res.data
                })
            });
        }
    }

    render() {

        return (
            <div className="widget-scroll">
                <h1 className="widget-title">Новости компании</h1>

                <ScrollArea
                    speed={0.8}
                    className="area"
                    contentClassName="content"
                    horizontal={false}
                >
                    {
                        this.props.news.map((news, index) => {
                            return (
                                <div key={index}>
                                    <span className="widget-date">{news.date}</span>
                                    <p>{news.text}</p>
                                </div>
                            );
                        })
                    }
                </ScrollArea>
            </div>
        )
    }
};

const mapStateToProps = (state) => {
    return {
        news: state.news.widgetNewsData
    }
};

export default connect(mapStateToProps)(WidgetNews);
import React from 'react';
import {NavLink} from 'react-router-dom';
import {apiHost} from '../../../common/config';
import store from '../../../store/store';
import Layout from '../../../layouts/authorized';
import './news.scss';
import axios from 'axios';

class Page extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            item: {}
        };
    }

    componentDidMount() {
        const slug = this.props.location.pathname.substring(this.props.location.pathname.lastIndexOf('/') + 1);

        axios.get(
            `${apiHost}/news/get/item/${slug}`,
            {
                withCredentials: true
            }
        ).then(res => {
            this.setState({item: res.data});
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }

    render() {
        const  {item} = this.state;

        return (
            <Layout>
                <h2 className="page-title page-title--breadcrumbs">{item.name}</h2>
                <ul className="breadcrumbs">
                    <li><NavLink to="/lk/news">Новости компании</NavLink></li>
                </ul>
                <div className="blue-light-box">
                    <div className="">
                        <small>{item.date}</small>
                        <h3 className="my-4 blue-light-box__title">{item.name}</h3>
                    </div>
                    <img alt={item.name} className="float-right ml-4 mb-2 w-full md:w-1/2 lg:w-3/12" src={item.img} />
                    <div className="text-justify" dangerouslySetInnerHTML={{__html: item.text}} />
                </div>
            </Layout>
        );
    }
}


export default Page;

import React from 'react';
import Modal from '../../components/modal/modal';
import Textarea from "../UI/Textarea/Textarea";
import Select from "../UI/Select/Select";
import Form from "../forms/forms";
import {apiHost} from "../../common/config";
import _ from 'lodash';
import axios from "axios";
import store from "../../store/store";

class EditSaleModal extends Form {

    requestUrl = `${apiHost}/sale/edit/1753`;

    constructor(props) {
        super(props);

        this.state = {
            editSaleModalOpen: false,
            showForm: true,
            showButtonClose: true,
            submitDisabled: false,
            designList: [],
            startDate: new Date(),
            coveringList: [],
            uploadedStatus: false,
        }
    }

    showEditModal(event) {
        event.preventDefault();
        this.setState({editSaleModalOpen: true})
        axios.get(
            `${apiHost}/sale/get/formData`,
            {withCredentials: true}
        ).then(res => {
            this.setState({
                coveringList: res.data.covering,
                designList: res.data.design
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }

    closeEditModal = () => {
        this.setState({
            editSaleModalOpen: false,
            showForm: true,
            showButtonClose: true,
            designList: [],
            startDate: new Date(),
            coveringList: [],
            uploadedStatus: false,
        })
    };

    checkSendButton(event) {
        switch (event.target.name) {
            case 'subject':
                this.setState({filledSubject: event.target.value}, this.fillSubmitButtonFunc);
                break;
            case 'message':
                this.setState({filledMessage: event.target.value}, this.fillSubmitButtonFunc);
                break;
            default:
                break;
        }
    }

    submitFunc = () => {
        const formData = new FormData();
        const subject = this.state.selectedSubject.value;

        formData.append('subjectCode', subject);
        formData.append('message', this.state.filledMessage);

        this.validateForm(formData);
    }

    submitRequestCalllback = () => {
        this.setState({
            showForm: false,
            submitButtonFunc: false,
            showButtonClose: false
        });
    }


    fillSubmitButtonFunc() {
        if (this.state.filledMessage !== '') {
            this.setState({submitButtonFunc: this.submitFunc})
        } else {
            this.setState({submitButtonFunc: false})
        }
    }

    handleChangeSubject(selectedOption) {
        this.setState({selectedSubject: selectedOption});
    }

    render() {
        const {showForm, selectedSubject} = this.state;

        return (
            <>
                <button className="Menu__feedback flex"
                        onClick={(event) => this.showEditModal(event)}>
                    <span>Задать вопрос</span>
                    <i className="text-2xl ml-2 icon-question-feedback opacity-50"/></button>

                <Modal isOpen={this.state.editSaleModalOpen} onClose={this.closeEditModal} title="Обратная связь"
                       onSubmit={this.state.submitButtonFunc} hideCloseButton={!this.state.showButtonClose}>
                </Modal>
            </>
        );
    }
}

export default EditSaleModal;

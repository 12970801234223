import React from 'react';
import {apiHost} from '../../../common/config';
import store from '../../../store/store';
import Layout from '../../../layouts/authorized';
import './news.scss';
import axios from 'axios';
import Button from "../../../components/UI/Button/Button";
import {NavLink, withRouter} from "react-router-dom";
import Pagination from "../../../components/pagination/pagination";
import queryString from 'query-string';
import NewsForm from "./news-form";
import ScrollArea from 'react-scrollbar';

class Page extends React.Component {

    countForPage = 6;

    constructor(props) {
        super(props);

        let params = queryString.parse(this.props.location.search);

        this.state = {
            page: parseInt(params.page) || 1,
            news: [],
            isMarketing: false,
            newsCount: 0,
        };
    }

    fetch() {
        axios.get(
            `${apiHost}/news/get/list/info`,
            {withCredentials: true}
        )
            .then(res => {
                this.setState({...res.data});

                axios.get(
                    `${apiHost}/news/get/list/${this.state.page}/${this.countForPage}`,
                    {
                        withCredentials: true
                    }
                ).then(res => {
                    if (this.state.isMarketing) {
                        this.setState({news: res.data});
                    } else {
                        this.setState({news: this.state.news.concat(res.data)});
                    }
                }).catch(error => {
                    store.dispatch({
                        type: 'addResponseStatus',
                        status: error.response.data.error
                    })
                });

            })
            .catch(error => {
                store.dispatch({
                    type: 'addResponseStatus',
                    status: error.response.data.error
                })
            });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.page !== prevState.page) {
            this.fetch();
        }
    }

    componentDidMount() {
        this.fetch();
    }
    handleGetPage(event) {
        event.preventDefault();

        this.setState({page: this.state.page + 1});
    }

    navigateToNewsPage(page)
    {
        this.setState({page});
        this.props.history.push(`/lk/news/?page=${page}`);
    }

    render() {
        return (
            <Layout>
                <h2 className="page-title">Новости компании</h2>

                {this.state.isMarketing ? this.renderMarketingList() : this.renderSimpleList()}
            </Layout>
        )
    }

    renderSimpleList() {
        return (
            <>
                <div className="news-result w-full">
                    {
                        this.state.news.map((item, index) => (
                            <div className="news-result__item box-shadow" key={`news-${item.id}`}>
                                <div className="news-result__item-content clearfix">
                                    <img className="" src={item.img}/>

                                    <small>{item.date}</small>
                                    <div className="news-result__item-title">
                                        {item.name}
                                    </div>
                                    <div className="news-result__item-text">
                                        {item.text}
                                    </div>
                                </div>
                                <div
                                    className="flex pt-4 text-center w-full justify-center bottom-content">
                                    {
                                        <NavLink to={`/lk/news/${item.id}`}
                                                 className="px-3 no-underline">Подробнее</NavLink>
                                    }
                                </div>
                            </div>
                        ))
                    }
                </div>

                <div className="w-full my-8 text-center">
                    {
                        this.state.newsCount > this.countForPage * this.state.page
                            ? <a href="#" onClick={(event) => this.handleGetPage(event)}
                                 className="btn btn-blue uppercase">Показать ещё</a>
                            : ''

                    }
                </div>
            </>
        );
    }

    resetForm(event) {
        return false;

        this.props.history.push(`/lk/news/`);

        if (this.state.page !== 1) {
            this.setState({page: 1});
        } else {
            this.fetch();
        }

    }

    renderMarketingList() {
        return (
            <>
                <div className="news-result w-full flex-col">
                    <div className="white-box__content w-full">
                        <div className="news-short">
                            {
                                this.state.news.map((item, index) => (
                                    <div className="news-short__item news-short__item__border-bottom" key={`news-${item.id}`} >
                                        <img alt={item.name} className="" src={item.img}/>

                                        <small>{item.date}</small>
                                        <br/>
                                        <NavLink to={`/lk/news/${item.id}`} className="news-short__item-title">
                                            {item.name}
                                        </NavLink>
                                        <NavLink to={`/lk/news/${item.id}`} className="news-short__item-text">
                                            {item.text}
                                        </NavLink>
                                        <div className="text-right mt-1">
                                            <div className="news-result__stat">
                                                <a className="text-essilor-blue text-xs underline cursor-pointer">Новость просмотрели</a>
                                                <div className={`news-result__stat-dropdown ${!item.stats.length ? `news-result__stat-dropdown-small` : ``}`}>
                                                    {
                                                        (!item.stats.length)
                                                            ?
                                                            <div className="text-center pt-2">Нет данных</div>
                                                            :
                                                            <ScrollArea
                                                                speed={0.8}
                                                                className="area"
                                                                contentClassName="content"
                                                                horizontal={false}
                                                            >
                                                                {
                                                                    item.stats.map((stat, index) => {
                                                                        return (
                                                                            <div key={index} className="news-result__stat-dropdown-item">
                                                                                <span className="block font-bold">{stat.name}</span>
                                                                                <span className="block">{stat.role}</span>
                                                                            </div>
                                                                        );
                                                                    })
                                                                }
                                                            </ScrollArea>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                    <Pagination totalPages={this.state.newsCount/this.countForPage}
                                currentPage={this.state.page} onNavigate={(page) => this.navigateToNewsPage(page)}/>
                </div>

                <NewsForm onSubmit={(event) => this.resetForm(event)}/>

            </>
        );
    }
}


export default withRouter(Page);

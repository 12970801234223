import React from 'react';
import axios from "axios";

import Layout from '../../../layouts/authorized';
import {apiHost} from '../../../common/config';
import store from "../../../store/store";
import Select from "../../../components/UI/Select/Select";
import './sales-stat.scss';
import StatTableCell from './components/cell.component';
import StatTableCellCurrency from './components/cell.currency.component';
import StatTableCellASP from './components/cell.asp.component';
import StatTableCellVsASP from "./components/cell.vs.asp.component";
import TotalLineChartComponent from './components/total.linechart.component';
import DoublePieChartComponent from './components/double.piechart.component';


class Page extends React.Component {

    constructor(props) {
        super(props);
        const {defaultYear, currentMonth} = this.getDefaultDates();

        const defaultQuarters = {
            one: 'q_1',
            two: 'q_1'
        };

        const defaultMonths = {
            one: 'm_' + currentMonth,
            two: 'm_' + currentMonth
        };

        this.state = {
            headers: {
                currentYear: '',
                year: '',
                quarter: {
                    one: '',
                    two: ''
                },
                month: {
                    one: '',
                    two: ''
                },
                legalEntity: ''
            },
            errors: {
                entities: false
            },
            tableData: [],
            entities: [],
            years: [
                {value: '2021', label: '2021'},
                {value: '2020', label: '2020'},
                {value: '2019', label: '2019'}
            ],
            quarters: [
                {value: 'q_1', label: 'квартал I'},
                {value: 'q_2', label: 'квартал II'},
                {value: 'q_3', label: 'квартал III'},
                {value: 'q_4', label: 'квартал IV'}
            ],
            months: [
                {value: 'm_1', label: 'январь'},
                {value: 'm_2', label: 'февраль'},
                {value: 'm_3', label: 'март'},
                {value: 'm_4', label: 'апрель'},
                {value: 'm_5', label: 'май'},
                {value: 'm_6', label: 'июнь'},
                {value: 'm_7', label: 'июль'},
                {value: 'm_8', label: 'август'},
                {value: 'm_9', label: 'сентябрь'},
                {value: 'm_10', label: 'октябрь'},
                {value: 'm_11', label: 'ноябрь'},
                {value: 'm_12', label: 'декабрь'}
            ],
            filter: {
                legalEntity: '',
                year: defaultYear,
                quarter: defaultQuarters,
                month: defaultMonths,
            },
            searching: false,
        };


        this.defaultYearOption = {value: defaultYear, label: defaultYear};
        this.defaultQuartersOption = {
            one: {value: defaultQuarters.one, label: 'квартал I'},
            two: {value: defaultQuarters.one, label: 'квартал I'}
        };
        this.defaultMonthsOption = {
            one: {value: defaultMonths.one, label: 'январь'},
            two: {value: defaultMonths.one, label: 'январь'}
        };

    }

    getDefaultDates() {
        const currentYear = new Date().getFullYear(),
            defaultYear = new Date().getFullYear() - 1,
            currentMonth = new Date().getMonth() + 1;

        return {currentYear, defaultYear, currentMonth};
    }


    componentDidMount() {
        this.getEntities();
    }


    getTableData(filter) {
        filter.currentYear = new Date().getFullYear();

        axios.get(
            `${apiHost}/external1c/stat/sale`,
            {
                withCredentials: true,
                params: {
                    filter: JSON.stringify(filter)
                }
            }
        ).then(res => {
            const {filter} = this.state;

            this.setState({
                tableData: res.data,
                headers: {
                    currentYear: filter.currentYear,
                    year: filter.year,
                    quarter: {
                        one: filter.quarter.one,
                        two: filter.quarter.two
                    },
                    month: {
                        one: filter.month.one,
                        two: filter.month.two
                    },
                    legalEntity: filter.legalEntity
                }
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        }).finally(() => {
            this.setState({
                searching: false
            });
        });

    }


    getEntities() {
        axios.get(
            `${apiHost}/entity/getlist`,
            {withCredentials: true,}
        ).then(res => {
            const { entities } = this.state;
            this.setState({
                entities: [...entities, ...res.data]
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });

    }


    handleChangeEntity(selectedOption) {
        const {filter} = this.state;
        filter.legalEntity = selectedOption.value;
        this.setState({filter: filter, errors: {entities: false}});
    }


    handleChangeYear(selectedOption) {
        const {filter} = this.state;
        filter.year = selectedOption.value;
        this.setState({filter});
    }


    handleChangeQuarter(selectedOption, type) {
        const {filter} = this.state;

        filter.quarter.one = selectedOption.value;
        filter.quarter.two = selectedOption.value;

        this.setState({filter});
    }


    handleChangeMonth(selectedOption, type) {
        const {filter} = this.state;

        filter.month.one = selectedOption.value;
        filter.month.two = selectedOption.value;

        this.setState({filter});
    }


    handleSearch = () => {
        const {filter} = this.state;

        if (filter.legalEntity) {
            this.setState({
                searching: true
            });

            this.getTableData(filter);
        } else {
            this.setState({
                errors: {entities: true}
            });
        }
    }


    getValue(list, key) {
        const value = list.filter((item) => (item.value == key)).map((item) => (item.label));
        return value[0];
    }


    cutYear(year) {
        return year.toString().substring(2);
    }


    render() {
        const {headers, tableData, quarters, months, filter} = this.state;

        return (
            <Layout>
                <h2 className="page-title">Статистика продажи</h2>


                <div className="w-full md:flex md:justify-between md:w-3/4">
                    <div className="md:w-6/12 md:mr-5">
                        <Select
                            name="entities"
                            label="Выберите партнёра"
                            placeholder="Не выбрано"
                            errors={this.state.errors}
                            options={this.state.entities}
                            className="w-full"
                            onChange={(selectedOption) => this.handleChangeEntity(selectedOption)}
                        />
                    </div>
                    <div className="md:w-6/12 md:ml-5">
                        <Select
                            name="year"
                            label="Выберите год для сравнения"
                            placeholder="Не выбрано"
                            options={this.state.years}
                            defaultValue={this.defaultYearOption}
                            className="w-full"
                            onChange={(selectedOption) => this.handleChangeYear(selectedOption)}
                        />
                    </div>
                </div>

                <div className="w-full md:flex md:justify-between md:w-3/4">
                    <div className="md:w-6/12 md:mr-5">
                        <Select
                            name="quarter"
                            label="Выберите квартал для сравнения"
                            placeholder="Не выбрано"
                            options={this.state.quarters}
                            defaultValue={this.defaultQuartersOption.one}
                            className="w-full"
                            onChange={(selectedOption) => this.handleChangeQuarter(selectedOption, 'one')}
                        />
                    </div>
                    <div className="md:w-6/12 md:ml-5">
                        <Select
                            name="month"
                            label="Выберите месяц для сравнения"
                            placeholder="Не выбрано"
                            options={this.state.months}
                            defaultValue={this.defaultMonthsOption.one}
                            className="w-full"
                            onChange={(selectedOption) => this.handleChangeMonth(selectedOption, 'one')}
                        />
                    </div>
                </div>

                <div className="w-full md:flex md:justify-between md:w-3/4 mb-10 mt-3">
                    <div className="md:w-6/12 md:mr-5">
                        <div className="w-full md:flex md:justify-between">
                            <button disabled={this.state.searching ? "disabled" : ""}
                                    className="btn btn-narrow btn-blue w-full md:w-6/12 md:mr-5"
                                    onClick={this.handleSearch}>Найти</button>

                        </div>
                    </div>
                </div>

                {tableData.length > 0 ?
                    <>
                        <div className="custom-table head-border">
                            <table>
                                <thead>
                                <tr>
                                    <th rowSpan="3">Группа</th>
                                    <th colSpan="4">
                                        <span className='normal-case'>
                                            Сравнение выбранного года с текущим (до текущей даты)
                                        </span>
                                    </th>
                                    <th colSpan="4">
                                        <span className='normal-case'>
                                            Сравнение выбранного квартала между выбранным и текущим годами (до текущей даты)
                                        </span>
                                    </th>
                                    <th colSpan="4">
                                        <span className='normal-case'>
                                            Сравнение выбранного месяца между выбранным и текущим годами (до текущей даты)
                                        </span>
                                    </th>
                                </tr>
                                <tr>
                                    <th colSpan="2">{headers.currentYear}/{headers.year}</th>
                                    <th colSpan="2"></th>
                                    <th colSpan="2">
                                        {this.getValue(quarters, headers.quarter.one)}
                                    </th>
                                    <th colSpan="2"></th>
                                    <th colSpan="2">
                                        {this.getValue(months, headers.month.one)}
                                    </th>
                                    <th colSpan="2"></th>
                                </tr>
                                <tr>
                                    <th>шт.</th>
                                    <th>руб.</th>
                                    <th>ASP{this.cutYear(headers.currentYear)}</th>
                                    <th>VS ASP{this.cutYear(headers.year)}.%</th>
                                    <th>шт.</th>
                                    <th>руб.</th>
                                    <th>ASP{this.cutYear(headers.currentYear)}</th>
                                    <th>VS ASP{this.cutYear(headers.year)}.%</th>
                                    <th>шт.</th>
                                    <th>руб.</th>
                                    <th>ASP{this.cutYear(headers.currentYear)}</th>
                                    <th>VS ASP{this.cutYear(headers.year)}.%</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    tableData.map((row) => {
                                        return (
                                            <tr className={row.total ? 'sales-stat-row-wrapper_complex' : null}>
                                                <td className={row.type === 'covering' ? 'text-right italic' : null}>{row.group}</td>
                                                <StatTableCell
                                                    title_one={headers.currentYear}
                                                    title_two={headers.year}
                                                    count_one={row.ydt_1_count}
                                                    count_two={row.ydt_2_count}
                                                />
                                                <StatTableCellCurrency
                                                    title_one={headers.currentYear}
                                                    title_two={headers.year}
                                                    count_one={row.ydt_1_sum}
                                                    count_two={row.ydt_2_sum}
                                                />
                                                <StatTableCellASP
                                                    count={row.ydt_1_count}
                                                    amount={row.ydt_1_sum}
                                                />
                                                <StatTableCellVsASP
                                                    currentYearCount={row.ydt_1_count}
                                                    currentYearAmount={row.ydt_1_sum}
                                                    yearCount={row.ydt_2_count}
                                                    yearAmount={row.ydt_2_sum}
                                                />
                                                <StatTableCell
                                                    title_one={`${headers.currentYear} ${this.getValue(quarters, headers.quarter.one)}`}
                                                    title_two={`${headers.year} ${this.getValue(quarters, headers.quarter.two)}`}
                                                    count_one={row.qdt_1_count}
                                                    count_two={row.qdt_2_count}
                                                />
                                                <StatTableCellCurrency
                                                    title_one={`${headers.currentYear} ${this.getValue(quarters, headers.quarter.one)}`}
                                                    title_two={`${headers.year} ${this.getValue(quarters, headers.quarter.two)}`}
                                                    count_one={row.qdt_1_sum}
                                                    count_two={row.qdt_2_sum}
                                                />
                                                <StatTableCellASP
                                                    count={row.qdt_1_count}
                                                    amount={row.qdt_1_sum}
                                                />
                                                <StatTableCellVsASP
                                                    currentYearCount={row.qdt_1_count}
                                                    currentYearAmount={row.qdt_1_sum}
                                                    yearCount={row.qdt_2_count}
                                                    yearAmount={row.qdt_2_sum}
                                                />
                                                <StatTableCell
                                                    title_one={`${headers.currentYear} ${this.getValue(months, headers.month.one)}`}
                                                    title_two={`${headers.year} ${this.getValue(months, headers.month.two)}`}
                                                    count_one={row.mdt_1_count}
                                                    count_two={row.mdt_2_count}
                                                />
                                                <StatTableCellCurrency
                                                    title_one={`${headers.currentYear} ${this.getValue(months, headers.month.one)}`}
                                                    title_two={`${headers.year} ${this.getValue(months, headers.month.two)}`}
                                                    count_one={row.mdt_1_sum}
                                                    count_two={row.mdt_2_sum}
                                                />
                                                <StatTableCellASP
                                                    count={row.mdt_1_count}
                                                    amount={row.mdt_1_sum}
                                                />
                                                <StatTableCellVsASP
                                                    currentYearCount={row.mdt_1_count}
                                                    currentYearAmount={row.mdt_1_sum}
                                                    yearCount={row.mdt_2_count}
                                                    yearAmount={row.mdt_2_sum}
                                                />
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        </div>
                        <TotalLineChartComponent year={headers.year} legalEntity={headers.legalEntity} />

                        <DoublePieChartComponent
                            title='Доля PAL+MID'
                            searching={this.state.searching}
                            url={`/external1c/stat/sale/chart/pal_mid`}
                            year={headers.year}
                            legalEntity={headers.legalEntity}
                        />

                        <DoublePieChartComponent
                            title='Доля Photo'
                            searching={this.state.searching}
                            url={`/external1c/stat/sale/chart/photo`}
                            year={headers.year}
                            legalEntity={headers.legalEntity}
                        />

                        <DoublePieChartComponent
                            title='Доля Crizal'
                            searching={this.state.searching}
                            url={`/external1c/stat/sale/chart/crizal`}
                            year={headers.year}
                            legalEntity={headers.legalEntity}
                        />

                        <DoublePieChartComponent
                            title='Доля Blue Blocker'
                            searching={this.state.searching}
                            url={`/external1c/stat/sale/chart/blue_blocker`}
                            year={headers.year}
                            legalEntity={headers.legalEntity}
                        />

                        <DoublePieChartComponent
                            title='Линзы по индексам'
                            searching={this.state.searching}
                            url={`/external1c/stat/sale/chart/indexes`}
                            year={headers.year}
                            legalEntity={headers.legalEntity}
                        />

                    </>
                    : null
                }

            </Layout>
        );
    }
}

export default Page;

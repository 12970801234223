import React from "react";
import {NavLink} from "react-router-dom";

const PromoList = (props) => {
    if (props.items.length === 0) {
        return '';
    }

    return (

        <div className="items-result w-full">
            {
                props.items.map(item =>
                    (
                        <div className="items-result__item item-white box-shadow" key={`item-${item.id}`}>
                            <img src={item.image}/>
                            <div className="flex justify-between">
                                {
                                    item.inJoinedPromo ?
                                        <div className="label label__green">Участвую</div>
                                        : item.inInvitedPromo ?
                                        <div className="label label__blue">Новая акция</div>
                                        : item.isNew ?
                                            <div className="label label__blue">Новая акция</div>
                                            :
                                            <div className="label">&nbsp;</div>
                                }
                                <div className="flex items-center item-white__time">
                                            <span className="pr-3 text-right">
                                                {item.dateText}
                                            </span>
                                </div>
                            </div>
                            <div className="content flex-col flex">
                                <h2 className="title">{item.name}</h2>
                                <div className="description">
                                    {item.text}
                                </div>
                            </div>
                            <div
                                className="flex pt-4 text-left  w-full mt-auto bottom-content">
                                <NavLink to={`/lk/promo/detail/${item.id}`}>
                                    Подробнее</NavLink>
                            </div>
                        </div>
                    )
                )
            }

        </div>
    );
}

export default PromoList;

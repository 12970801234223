import React from 'react'
import Select2 from 'react-select'
import './Select.scss';

const Select = props => {
    let propsNew = {...props};
    propsNew.id = props.name;

    let hasError = false;

    if (props.hasOwnProperty('errors') && props.errors.hasOwnProperty(props.name) && props.errors[props.name]) {
        hasError = true;
    }

    return (
        <div className={`selectInput ${propsNew.className}`}>
            <label htmlFor={propsNew.id}>
                {props.label}
                {hasError ? (<i className="icon-alert-triangle error-icon"></i>) : ''}
            </label>
            <Select2
                {...propsNew}
                id={propsNew.id}
                classNamePrefix="selectInput"
            />
        </div>
    )
}

export default Select
import React from "react";

const ProfileProgressCircleSvg = (props) => {

    const value = props.value || 0;
    const valueMax = props.valueMax || 9999;
    const filledValue = Math.ceil((244 * value / valueMax) + 250);

    return (
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 230 230">
            <style type="text/css">
                {`
                .ppbc-blue {fill:url(#ppbc-gradient-blue);}
                .ppbc-grey {fill:#DBDADA;}
                .ppbc-dark-grey {fill:#B2B2B2;}
                .ppbc-blue-bar {fill:#BBBFD7;}
                .ppbc-orange {fill:none; stroke:url(#ppbc-gradient-orange); stroke-width:28; stroke-miterlimit:10;}
                .ppbc-blue {fill:none; stroke:url(#ppbc-gradient-blue); stroke-width:28; stroke-miterlimit:10;}
                .ppbc-white {fill:#FFFFFF; filter:url(#ppbc-filter-shadow); }
                `}
            </style>
            <filter id="ppbc-filter-shadow" height="130%">
                <feGaussianBlur in="SourceAlpha" stdDeviation="3"/>
                <feOffset dx="0" dy="0" result="offsetblur"/>
                <feComponentTransfer>
                    <feFuncA type="linear" slope="0.5"/>
                </feComponentTransfer>
                <feMerge>
                    <feMergeNode/>
                    <feMergeNode in="SourceGraphic"/>
                </feMerge>
            </filter>
            <linearGradient id="ppbc-gradient-blue" gradientUnits="userSpaceOnUse" x1="115" y1="261.2756" x2="115"
                            y2="31.2756"
                            gradientTransform="matrix(1 0 0 -1 0 261.2756)">
                <stop offset="0" style={{stopColor: '#75A9DE'}}/>
                <stop offset="1" style={{stopColor: '#3A84D0'}}/>
                <stop offset="1" style={{stopColor: '#3A84D0'}}/>
            </linearGradient>
            <linearGradient id="ppbc-gradient-orange" gradientUnits="userSpaceOnUse" x1="35.0512" y1="50.3792"
                            x2="195.1494"
                            y2="180.2955">
                <stop offset="0" style={{stopColor: '#E8552B'}}/>
                <stop offset="1" style={{stopColor: '#FFCF24'}}/>
            </linearGradient>

            <circle className="ppbc-grey" cx="115" cy="115" r="115"/>
            <circle className="ppbc-dark-grey" cx="115" cy="115" r="102.2"/>
            <ellipse className="ppbc-blue" transform="rotate(90, 115, 115)" cx="115"
                     cy="115" rx="89.1" ry="89.1"
                     style={{strokeDasharray: `${filledValue}%`, strokeDashoffset: '250%'}}/>
            <circle className="ppbc-white" cx="115" cy="115" r="76.7"/>
        </svg>
    )
}

export default ProfileProgressCircleSvg;

import React from 'react';

const SVG = ({
                 fill="none",
                 width="53",
                 height="12",
                 viewBox="0 0 52 12",
                 className="",
                 style={}
             }) => (
    <svg fill={fill} width={width} style={style} height={height} viewBox={viewBox} className={`svg-icon ${className || ""}`} xmlns="http://www.w3.org/2000/svg">
        <path d="M51.5921 5.42665L46.2197 0.237454C45.8919 -0.0791515 45.3603 -0.0791515 45.0325 0.237454C44.7046 0.554125 44.7046 1.06747 45.0325 1.38414L48.9719 5.18918H0.839355C0.37578 5.18918 -9.03606e-05 5.55223 -9.03606e-05 5.99999C-9.03606e-05 6.44769 0.37578 6.8108 0.839355 6.8108H48.9719L45.0326 10.6158C44.7048 10.9325 44.7048 11.4459 45.0326 11.7625C45.1965 11.9207 45.4114 12 45.6262 12C45.841 12 46.0559 11.9207 46.2198 11.7625L51.5921 6.57333C51.92 6.25666 51.92 5.74332 51.5921 5.42665Z" fill="#797979"/>
    </svg>
);

export default SVG;

import React from 'react';
import {NavLink} from 'react-router-dom';
import _ from "lodash";
import axios from 'axios';

import {apiHost} from '../../../common/config';
import store from '../../../store/store';
import FileList from "../../../components/file-list/file-list";
import TableEntitySaleForce from "../../../components/table-entity/table-entity-saleforce";
import TableEntityOwner from "../../../components/table-entity/table-entity-owner";
import './promo.scss';

class Page extends React.Component {

    constructor(props) {
        super(props);

        const path = this.props.location.pathname.split('/');
        this.id = path[4];

        this.state = {
            item: {}
        };

        this.formOpticsRef = React.createRef();
    }

    submitJoinPromo = (event, url) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        formData.append('promoId', this.id);

        this.submitForm(formData, url);
    };

    submitReleasePromo = () => {
        const formData = new FormData(this.formOpticsRef.current);
        formData.append('promoId', this.id);
        formData.append('isRelease', true);

        this.submitForm(formData, '/promotions/send/promo/join');
    }

    submitForm(formData, url) {
        axios(`${apiHost}${url}`,
            {
                method: "post",
                data: formData,
                withCredentials: true
            }
        ).then(res => {
            store.dispatch({
                type: 'addResponseStatus',
                status: {
                    code: 200,
                    message: res.data.message
                }
            });
            this.fetchData();
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }



    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        axios.get(
            `${apiHost}/promotions/get/${this.id}`,
            {
                withCredentials: true
            }
        ).then(res => {
            this.setState({item: res.data});
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }

    render() {
        const {item} = this.state;

        return (
            Object.keys(item).length > 0 &&
            <>
                <h2 className="page-title page-title--breadcrumbs">{item.name}</h2>
                <ul className="breadcrumbs">
                    <li><NavLink to="/lk/promo">Текущие акции</NavLink></li>
                </ul>

                {
                    (item.isOwner || item.isManager) &&
                    <div className="blue-light-box w-full p-0">
                        <div className="white-box__header">Описание</div>
                        <div className="white-box__content">
                            <img className="md:float-left mr-0 mb-4 md:mr-4 mb-2 w-full md:w-1/2 lg:w-4/12" src={item.img}/>
                            <div className="flex justify-between mb-4 items-baseline">
                                <h2 className="text-2xl uppercase">{item.dateText}</h2>
                                {
                                    item.isJoinedPromo &&
                                    <div className="label label__blue">Участвую</div>

                                }
                            </div>

                            <div className="text-justify" dangerouslySetInnerHTML={{__html: item.text}}/>
                        </div>
                    </div>
                }

                <div className="flex flex-col lg:flex-row w-full mt-6 ">

                    {
                        item.isManager &&
                        <div className="w-full lg:w-1/2 lg:mr-4 mb-6">
                            <form onSubmit={event => this.submitJoinPromo(event, '/promotions/send/promo/invite')}>
                                <TableEntitySaleForce
                                    data={_.sortBy(item.userEntities, ['entityName', 'userName'])}
                                    checkedAll={false}
                                />
                            </form>
                        </div>
                    }
                    {
                        item.isOwner &&
                        <div className="w-full lg:w-1/2 lg:mr-4 mb-6">

                            <form onSubmit={event => this.submitJoinPromo(event, '/promotions/send/promo/join')}
                            ref={this.formOpticsRef}>
                                <TableEntityOwner
                                    data={_.sortBy(item.userOptics, [(o) => o.userName])}
                                    checkedAll={false}
                                    currentPromo={item}
                                    releaseHandler={this.submitReleasePromo}
                                />
                            </form>
                        </div>
                    }

                    {
                        item.files &&
                        <div className="blue-light-box w-full lg:w-1/2">
                            <div className="white-box__header">Файлы акции</div>
                            <div className="white-box__content">
                                <FileList files={item.files} showType={true}/>
                            </div>
                        </div>
                    }
                </div>


            </>
        );
    }

}


export default Page;

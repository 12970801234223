import React from 'react';
import {NavLink} from 'react-router-dom';
import './education.scss';
import axios from "axios";
import {apiHost} from "../../../common/config";
import './education-trainer-course-detail-homework.scss';
import ReactPlayer from "react-player";

class Page extends React.Component {

    constructor(props) {
        super(props);

        const path = this.props.location.pathname.split('/');

        this.courseCode = path[5];
        this.fileId = path[7];

        this.state = {
            course: {},
            video: {},
            isRequestSend: false,
            played: 0
        };

        this.fileRef = React.createRef();
    }

    componentDidMount() {

        const getCourse = function (courseCode) {
            return axios.get(
                `${apiHost}/education/trainer/get/course/${courseCode}`, {
                    withCredentials: true
                }
            )
        };

        const getFile = function (id) {
            return axios.get(
                `${apiHost}/education/trainer/get/course/video/ibelement/${id}`, {
                    withCredentials: true
                }
            )
        };

        axios.all([getCourse(this.courseCode), getFile(this.fileId)])
            .then(axios.spread((acct, perms) => {
                this.setState({course: acct.data.course, video: perms.data});
            }));
    }

    handleProgress = state => {
        const {isRequestSend} = this.state;

        if (!isRequestSend && state.played > 0.8) {
            this.saveEvent();
            this.setState({isRequestSend: true});
        }
    }

    saveEvent = () => {
        const formData = new FormData();

        axios(`${apiHost}/education/log/trainer/course/video/ibelement/${this.fileId}`,
            {
                method: "post",
                data: formData,
                withCredentials: true
            }
        ).then(res => {
            // do nothing
        }).catch(error => {
            this.setState({isRequestSend: false});
        });
    }

    render() {
        const {course, video} = this.state;

        return (
            <>
                <h2 className="page-title page-title--breadcrumbs" dangerouslySetInnerHTML={{__html: video.name}}></h2>
                <ul className="breadcrumbs">
                    <li><NavLink to="/lk/education">Обучение</NavLink></li>
                    <li>></li>
                    <li><NavLink to="/lk/education/trainer">Обучение от бизнес тренера</NavLink></li>
                    <li>></li>
                    <li><NavLink to={`/lk/education/trainer/course/${course.code}`}>{course.name}</NavLink></li>
                </ul>
                <div>
                    { typeof(video.file) !== 'undefined' &&
                        <ReactPlayer
                            width={'100%'}
                            url={video.file.src}
                            controls
                            onProgress={this.handleProgress}/>
                    }
                </div>
            </>
        );
    }
}


export default Page;

import React from 'react';
import {connect} from 'react-redux';
import './alert.scss';
import store from '../store/store';


class Alert extends React.Component {
    render() {

        const isHidden = !this.props.status.message ? 'hidden' : '',
            code = this.props.status.code === 200 ? 'success' : 'error';

        return (
            <div className={`alert-message ${isHidden} ${code}`}>
                <div className="alert-message__info">
                    {this.props.status.message}
                </div>
                <button type="button" className="alert-message__close"
                onClick={() => (store.dispatch({type: 'clearResponseStatus'}))}>×</button>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        status: state.axios.status
    }
}

export default connect(mapStateToProps)(Alert);

import React, { Component } from 'react';
import {PieChart, Pie, Cell, Tooltip, Legend} from 'recharts';
import axios from "axios";

import {apiHost} from "../../../../common/config";
import store from "../../../../store/store";


const RADIAN = Math.PI / 180;

class PieChartComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dataChart: [],
            searching: this.props.searching
        }
    }


    componentDidMount() {
        this.getData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.year !== prevProps.year || this.props.legalEntity !== prevProps.legalEntity) {
            this.getData();
        }
    }


    getData() {
        axios.get(
            `${apiHost}${this.props.url}`,
            {
                withCredentials: true,
                params: {
                    year: this.props.year,
                    legalEntity: this.props.legalEntity,
                    type: this.props.type
                }
            }
        ).then(res => {
            this.setState({
                dataChart: res.data
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        }).finally(() => {
            this.setState({
                searching: false
            });
        });

    }


    renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };


    toolTipFormatter = (value, name) => {
        const ending = this.props.type == 'count' ? ' шт.' : '';
        const formattedValue = this.props.type == 'count' ? value : this.currencyFormatter(value);

        return [`${formattedValue}${ending}`, name];
    };


    currencyFormatter(count) {
        const formatter = new Intl.NumberFormat('ru-RU', {
            style: 'currency',
            currency: 'RUB',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

        return formatter.format(count);
    }


    render() {
        const { dataChart, searching } = this.state;

        return (
            !searching ?
                <div className='mt-10'>
                    <h3 className='custom-grey-color text-2xl'>
                        {this.props.title}
                    </h3>
                    <PieChart width={200} height={200}>
                        <Pie
                            data={dataChart}
                            cx={90}
                            cy={80}
                            labelLine={false}
                            label={this.renderCustomizedLabel}
                            outerRadius={70}
                            fill="#8884d8"
                            dataKey="value"
                        >
                            {dataChart.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={this.props.colors[index % this.props.colors.length]} />
                            ))}
                        </Pie>
                        <Tooltip formatter={this.toolTipFormatter} separator=' - ' />
                        <Legend />
                    </PieChart>
                </div>
            : null
        );
    }
}


export default PieChartComponent;
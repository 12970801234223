import React from 'react';
import Layout from '../../../layouts/authorized';
import {Calendar, Views, momentLocalizer, globalizeLocalizer} from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './calendar.scss';
import moment from 'moment';
import 'moment/locale/ru';

import Icon from '../../../icons'
import Button from "../../../components/UI/Button/Button";
import Input from "../../../components/UI/Input/Input";
import axios from "axios";
import {apiHost} from "../../../common/config";
import store from "../../../store/store";
import _ from "lodash";
import Modal from '../../../components/modal/modal';

const propTypes = {};

class Page extends React.Component {

    constructor(...args) {
        super(...args);


        this.state = {
            events: [],
            selectedEvent: false,
            isMobile: window.innerWidth <= 768,
            responseModalOpen: false,
            responseModalStatus: '',
            listAdditionalFieldsForWebinar: [],
            userAdditionalFieldsForWebinar: {},
            statusModalSendButton: true
        }
    }

    componentDidMount() {
        this.getEventsList();
    }

    getEventsList () {
        axios.get(
            `${apiHost}/calendar/get/list`,
            {withCredentials: true}
        ).then(res => {
            const events = res.data.map(event =>
                (
                    {
                        id: event.id,
                        title: this.getEventContainer(
                            {
                                time: `${event.start.h}:${event.start.i}-${event.end.h}:${event.end.i}`,
                                ..._.pick(event, ['title', 'type', 'isJoined', 'link', 'address', 'day', 'image', 'text', 'id']),
                            }
                        ),
                        start: new Date(
                            event.start.y, event.start.m - 1, event.start.d, event.start.h, event.start.i
                        ),
                        end: new Date(
                            event.end.y, event.end.m - 1, event.end.d, event.end.h, event.end.i
                        )
                    }
                )
            );
            this.setState({events});
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }

    getEventContainer = function (event) {
        return <div className={event.isJoined ? `event event-joined` : `event`}
                    onClick={() => this.handleSelect(event)}>
            <div className={`event__type event__type-${event.type}`}>
                {event.type == 'online' ? `Онлайн` : `Офлайн`}</div>
            <div className="event__title">{event.title}</div>
        </div>;
    };

    handleSelect(selectedEvent) {
        this.setState({selectedEvent}, () => {
            window.scrollTo(
                {
                    top: document.getElementById('selected-event-calendar').offsetTop,
                    behavior: "smooth"
                }
            );
        });
    }


    eventStyleGetter = function (event, start, end, isSelected) {
        var backgroundColor = '#' + event.hexColor;
        var style = {
            backgroundColor: backgroundColor,
            borderRadius: '0',
            opacity: 1,
            color: 'black',
            border: '0px',
            display: 'block',
            padding: 0
        };
        return {
            style: style
        };
    };


    registerWebinar() {
        const selected = this.state.selectedEvent;
        const userAdditionalFields = this.state.userAdditionalFieldsForWebinar;


        axios.get(
            `${apiHost}/webinar/register/${selected.id}`,
            {
                withCredentials: true,
                params: {
                    userAdditionalFields: JSON.stringify(userAdditionalFields)
                }
            }
        ).then(res => {
            let additionalFields = [];

            if (res.data.status) {

                if (res.data.status === 'needAdditionalFields') {
                    additionalFields = res.data.additionalFields || [];
                }

                this.setState({
                    responseModalOpen: true,
                    responseModalMessage: res.data.message || '',
                    responseModalLink: res.data.link  || '',
                    responseModalStatus: res.data.status,
                    listAdditionalFieldsForWebinar: additionalFields
                })
            }
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }

    closeModal() {
        this.setState({
            responseModalOpen: false,
            selectedEvent: false,
            responseModalStatus: '',
            listAdditionalFieldsForWebinar: [],
            userAdditionalFieldsForWebinar: {},
            statusModalSendButton: true
        });
        this.getEventsList();
    }

    checkModalSendButton(event) {
        const fieldName = event.target.name;
        const {userAdditionalFieldsForWebinar, listAdditionalFieldsForWebinar} = this.state;
        let {statusModalSendButton} = this.state;

        userAdditionalFieldsForWebinar[fieldName] = event.target.value;

        let countUserAddFields = _.without(_.values(userAdditionalFieldsForWebinar), '').length;

        if (countUserAddFields === listAdditionalFieldsForWebinar.length) {
            statusModalSendButton = false;
        } else {
            statusModalSendButton = true;
        }

        this.setState({userAdditionalFieldsForWebinar, statusModalSendButton});
    }

    renderMessageAfterRegistration() {
        return (
            <>
                <p className="pb-5">{this.state.responseModalMessage}</p>
                <a className="btn btn-thin btn-blue"
                   href={this.state.responseModalLink} target="_blank">Перейти к вебинару</a>
            </>
        );
    }


    renderAdditionalFields() {
        const {listAdditionalFieldsForWebinar} = this.state;

        return (
            <>
                <p className="pb-5">Для регистрации на вебинар необходимо заполнить дополнительные поля:</p>

                {
                    listAdditionalFieldsForWebinar.map((field) => {

                        return (
                            <Input label={field.label}
                                   name={field.key}
                                   type="text"
                                   className="w-full max-w-3xl"
                                   key={field.key}
                                   onInput={(e) => this.checkModalSendButton(e)}/>
                        );
                    })
                }

                <Button onClick={() => this.registerWebinar()}
                        className="btn btn-thin btn-blue mt-4 sm:mr-8"
                        disabled={this.state.statusModalSendButton}>
                    Регистрация в вебинаре
                </Button>
            </>
        );
    }

    render() {
        const localizer = momentLocalizer(moment),
            selected = this.state.selectedEvent,
            view = this.state.isMobile ? 'agenda' : 'month',
            defaultView = this.state.isMobile ? Views.AGENDA : Views.MONTH;

        return (
            <Layout>
                <h2 className="page-title">Календарь мероприятий</h2>

                <div className="rbc-calendar-container">
                    <Calendar
                        selectable
                        localizer={localizer}
                        events={this.state.events}
                        views={[view]}
                        defaultView={defaultView}
                        scrollToTime={new Date(1970, 1, 1, 6)}
                        defaultDate={new Date()}
                        // onSelectEvent={event => console.log(event)}
                        // onSelectSlot={this.handleSelect}
                        messages={
                            {
                                previous: <Icon name="prev-long" fill="#797979"/>,
                                next: <Icon name="next-long" fill="#797979"/>,
                                today: 'сегодня',
                                date: 'Дата',
                                time: 'Время',
                                event: 'Мероприятие',
                                noEventsInRange: 'Нет мероприятий на выбранный период'
                            }
                        }
                        eventPropGetter={(this.eventStyleGetter)}
                    />
                </div>

                <div id="selected-event-calendar"/>

                <div className="w-full flex sm:flex-row-reverse flex-col sm:justify-between sm:items-center items-start py-4">
                    <ul className="calendar-legend sm:justify-end">
                        <li>
                            <span className="calendar-legend__status calendar-legend__status-joined"></span>
                            <span className="calendar-legend__title">Записан</span>
                        </li>
                        <li>
                            <span className="calendar-legend__status calendar-legend__status-not-joined"></span>
                            <span className="calendar-legend__title">Не записан</span>
                        </li>
                        <li>
                            <span className="calendar-legend__type calendar-legend__type-online"></span>
                            <span className="calendar-legend__title">Онлайн</span>
                        </li>
                        <li>
                            <span className="calendar-legend__type calendar-legend__type-offline"></span>
                            <span className="calendar-legend__title">Офлайн</span>
                        </li>
                    </ul>
                    <div className=" whitespace-no-wrap">
                        {!this.state.selectedEvent || <p>Вы выбрали:</p>}
                    </div>
                </div>

                {
                    !this.state.selectedEvent ||
                    <div className=" w-full max-w-4xl">
                        <div className="flex flex-col lg:flex-row flex-wrap">
                            <div className="flex-1 mr-4 mb-6 w-full lg:w-2/4">
                                <div className="white-box box-shadow">
                                    <div className="title-semibold title-semibold__blue mb-4">{selected.title}</div>
                                    <div className="flex flex-col sm:flex-row">
                                        <div className="w-full sm:w-1/3 h-48 sm:mr-3 mr-0">
                                            <img className="w-full h-full object-cover"
                                                 src={selected.image}/>
                                        </div>
                                        <div className="flex flex-col mt-2 sm:mt-0">
                                            <div className="mb-2 text-essilor-dark-blue">{selected.day} | {selected.time}</div>
                                            <div className="text-lg font-semibold mb-4 text-essilor-dark-blue">Online Essilor Академия</div>
                                            <div className="text-lg font-semibold mb-4 text-essilor-dark-blue">{selected.address}</div>
                                            {
                                                selected.isJoined
                                                    ? <a href={selected.link} target="_blank"
                                                              className="btn btn-thin btn-blue mt-auto sm:mr-8">Перейти к вебинару</a>
                                                    : <Button onClick={() => this.registerWebinar()}
                                                                     className="btn btn-thin btn-blue mt-auto sm:mr-8">Регистрация в вебинаре</Button>
                                            }
                                        </div>
                                    </div>

                                    <div className="mt-6">
                                        {selected.text}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {
                    !this.state.responseModalOpen ||
                    <Modal isOpen={this.state.responseModalOpen}
                           hideCloseButton="true"
                           onClose={() => this.closeModal()}
                           title="Регистрация на вебинар"
                    >

                        {   this.state.responseModalStatus === 'needAdditionalFields'
                                ? this.renderAdditionalFields()
                                : this.renderMessageAfterRegistration()
                        }

                    </Modal>
                }

            </Layout>
        )
    }
}

Page.propTypes = propTypes;

export default Page;

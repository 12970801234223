import React from "react";
import './profile-progress-cup.scss';
import ProfileProgressCupSvg from "./profile-progress-cup-svg";

const ProfileProgressCup = (props) => {
    return (
        <div className="profile-progress-cup-wrapper">
            <div className="profile-progress-cup-tip">
                {props.tip}
            </div>
            <ProfileProgressCupSvg {...props}/>
        </div>
    )
};

export default ProfileProgressCup;

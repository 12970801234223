import React from 'react';
import {NavLink} from 'react-router-dom';
import {withRouter} from 'react-router-dom';

const MenuLink = props => {

    let {staticContext, ...propsNew} = props;

    const currentPath = props.location.pathname.split('/'),
        pathToLength = props.to.split('/').length;

    const $isActive = currentPath.slice(0, pathToLength).join('/') === props.to ? ' active' : '';
    propsNew.className += $isActive;

    return (
        <NavLink {...propsNew}>
            {propsNew.children}
        </NavLink>
    );

}


export default withRouter(MenuLink);

import React from 'react';
import Layout from '../../../layouts/authorized';
import {apiHost} from '../../../common/config';
import axios from "axios";
import store from "../../../store/store";
import Select from "../../../components/UI/Select/Select";
import CustomTable from '../../../components/table/table.2.0';
import DatePickerRange from "../../../components/UI/DatePicker/DatePickerRange";


class Page extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            headers: [
                {code: 'regionalManager', caption: 'Региональный менеджер', type: 'text'},
                {code: 'saleForce', caption: 'Sales Force', type: 'text'},
                {code: 'personalName', caption: 'ФИО', type: 'text', link: true},
                {code: 'personalRegisterDate', caption: 'Дата регистрации', type: 'text'},
                {code: 'personalEmail', caption: 'Email', type: 'text'},
                {code: 'legalEntity', caption: 'Партнёр', type: 'text'},
                {code: 'address', caption: 'Адрес', type: 'text'},
                {code: 'group', caption: 'Роль', type: 'text'},
                {code: 'level', caption: 'Уровень', type: 'text'},
                {code: 'salePoints', caption: 'Баллы продажи', type: 'number'},
                {code: 'educationPoints', caption: 'Баллы обучение', type: 'number', useCaption: true},
                {code: 'points', caption: 'Итого баллов', type: 'number'},
            ],
            tableData: [],
            sf: [
                {value: 'none', label: 'Без привязки'},
            ],
            rm: [],
            addresses: [
                {value: '', label: 'Все оптики'},
            ],
            entities: [
                {value: '', label: 'Все партнёры'},
            ],
            groups: [
                {value: '', label: 'Все роли'},
                {value: 'DOCTOR_USERS', label: 'Врач'},
                {value: 'CONSULTANT_USERS', label: 'Консультант'}
            ],
            levels: [
                {value: '', label: 'Все уровни'},
                {value: '1', label: 'Специалист'},
                {value: '2', label: 'Профессионал'},
                {value: '3', label: 'Эксперт'}
            ],
            filter: {
                legalEntity: '',
                group: '',
                address: '',
                levels: '',
                sf: '',
                rm: '',
                limit: 10,
                page: 1,
            },
            searching: false,
            allRecords: 0,
        }

    }

    componentDidMount() {
        const {filter} = this.state;
        this.getAddresses();
        this.getEntities();
        this.getSalesForceUsers();
        this.getRegionalManagerUsers();
    }

    getTableData(filter) {
        axios.get(
            `${apiHost}/activity/get/ecp/users/stat`,
            {
                withCredentials: true,
                params: {
                    filter: JSON.stringify(filter)
                }
            }
        ).then(res => {
            this.setState({
                tableData: res.data.data,
                allRecords: res.data.allRecords
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        }).finally(() => {
            this.setState({
                searching: false
            });
        });

    }

    getAddresses() {
        axios.get(
            `${apiHost}/entity/get/addresses/forSF`,
            {withCredentials: true,}
        ).then(res => {
            const { addresses } = this.state;
            this.setState({
                addresses: [...addresses, ...res.data]
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });

    }

    getEntities() {
        axios.get(
            `${apiHost}/entity/getlist`,
            {withCredentials: true,}
        ).then(res => {
            const { entities } = this.state;
            this.setState({
                entities: [...entities, ...res.data]
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });

    }

    getSalesForceUsers() {
        axios.get(
            `${apiHost}/user/get/sf/users`,
            {withCredentials: true,}
        ).then(res => {
            const { sf } = this.state;
            this.setState({
                sf: [...sf, ...res.data]
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });

    }

    getRegionalManagerUsers() {
        axios.get(
            `${apiHost}/user/get/rm/users`,
            {withCredentials: true,}
        ).then(res => {
            const { rm } = this.state;
            this.setState({
                rm: [...rm, ...res.data]
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });

    }

    handleChangeGroup(selectedOption) {
        const {filter} = this.state;
        filter.group = selectedOption.value;
        this.setState({filter});
    }

    handleChangeAddress(selectedOption) {
        const {filter} = this.state;
        filter.address = selectedOption.value;
        this.setState({filter});
    }

    handleChangeEntity(selectedOption) {
        const {filter} = this.state;
        filter.legalEntity = selectedOption.value;
        this.setState({filter});
    }

    handleChangeLevel(selectedOption) {
        const {filter} = this.state;
        filter.level = selectedOption.value;
        this.setState({filter});
    }

    handleChangeSalesForce(selectedOption) {
        const {filter} = this.state;
        filter.sf = selectedOption.value;
        this.setState({filter});
    }

    handleChangeRegionalManager(selectedOption) {
        const {filter} = this.state;
        filter.rm = selectedOption.value;
        this.setState({filter});
    }

    handleChangeDatePickerRange = (startDate, endDate) => {
        const {filter} = this.state;
        filter.dateStart = startDate;
        filter.dateEnd = endDate;
        this.setState({filter});

    }

    handleChangePage = (page) => {
        const {filter} = this.state
        filter.page = page;
        this.setState({
            searching: true
        });

        this.getTableData(filter);
    }

    handleSearch = () => {
        const {filter} = this.state;
        filter.page = 1;

        this.setState({
            searching: true
        });

        this.getTableData(filter);
    }

    render() {

        return (
            <Layout>
                <h2 className="page-title">Статистика по ECPs</h2>


                <div className="w-full md:flex md:justify-between md:w-3/4">
                    <div className="md:w-4/12 md:mr-5">
                        <Select
                            name="entity"
                            label="Выберите Sales Force"
                            placeholder=""
                            options={this.state.sf}
                            className="w-full"
                            onChange={(selectedOption) => this.handleChangeSalesForce(selectedOption)}
                        />
                    </div>
                    <div className="md:w-4/12 md:ml-5 md:mr-5">
                        <Select
                            name="entity"
                            label="Выберите партнёра"
                            placeholder=""
                            options={this.state.entities}
                            className="w-full"
                            onChange={(selectedOption) => this.handleChangeEntity(selectedOption)}
                        />
                    </div>
                    <div className="md:w-4/12 md:ml-5">
                        <Select
                            name="sf"
                            label="Выберите адрес"
                            placeholder=""
                            options={this.state.addresses}
                            className="w-full"
                            onChange={(selectedOption) => this.handleChangeAddress(selectedOption)}
                        />
                    </div>
                </div>

                <div className="w-full md:flex md:justify-between md:w-3/4">
                    <div className="md:w-4/12 md:mr-5">
                        <Select
                            name="rm"
                            label="Выберите РМ"
                            placeholder=""
                            options={this.state.rm}
                            className="w-full"
                            onChange={(selectedOption) => this.handleChangeRegionalManager(selectedOption)}
                        />
                    </div>

                    <div className="md:w-4/12 md:ml-5 md:mr-5">
                        <Select
                            name="levels"
                            label="Выберите уровень"
                            placeholder=""
                            options={this.state.levels}
                            className="w-full"
                            onChange={(selectedOption) => this.handleChangeLevel(selectedOption)}
                        />
                    </div>

                    <div className="md:w-4/12 md:ml-5">
                        <Select
                            name="groups"
                            label="Выберите роль"
                            placeholder=""
                            options={this.state.groups}
                            className="w-full"
                            onChange={(selectedOption) => this.handleChangeGroup(selectedOption)}
                        />
                    </div>
                </div>

                <div className="w-full md:flex md:justify-between md:w-3/4 mb-5">
                    <div className="md:w-8/12">
                        <DatePickerRange onChange={this.handleChangeDatePickerRange} />
                    </div>

                    <div className="md:w-4/12 md:ml-5"></div>
                </div>

                <div className="w-full md:flex md:justify-between md:w-3/4 mb-10 mt-3">
                    <div className="md:w-6/12 md:mr-5">
                        <div className="w-full md:flex md:justify-between">
                            <button disabled={this.state.searching ? "disabled" : ""}
                                    className="btn btn-narrow btn-blue w-full md:w-6/12 md:mr-5"
                                    onClick={this.handleSearch}>Найти</button>

                        </div>
                    </div>

                    <div className="md:w-6/12 md:ml-5"></div>
                </div>

                {  this.state.tableData.length > 0 &&
                <CustomTable
                    headers={this.state.headers}
                    tableData={this.state.tableData}
                    showSpin={this.state.searching}
                    allRecords={this.state.allRecords}
                    perPage={this.state.filter.limit}
                    currentPage={this.state.filter.page}
                    onChangePage={this.handleChangePage}
                    exportLink={`${apiHost}/export/users?filter=${JSON.stringify(this.state.filter)}`}
                />
                }
            </Layout>
        );
    }
}



export default Page;

import React from 'react';
import {NavLink} from 'react-router-dom';
import './education.scss';
import axios from "axios";
import {apiHost} from "../../../common/config";
import store from "../../../store/store";
import Textarea from "../../../components/UI/Textarea/Textarea";
import Button from "../../../components/UI/Button/Button";

class Page extends React.Component {

    constructor(props) {
        super(props);

        const path = this.props.location.pathname.split('/');

        this.courseCode = path[5];

        this.state = {
            course: {},
            files: [],
            sections: [
                {code: 'all', caption: 'Все'},
                {code: 'webinar', caption: 'Вебинары'},
                {code: 'video', caption: 'Видео'},
                {code: 'presentation', caption: 'Презентации'},
                {code: 'book', caption: 'Бизнес литература'},
                {code: 'course', caption: 'Курсы'},
                {code: 'hometask', caption: 'Домашние задание'}
            ],
            selectedSection: 'all'
        }
    }

    componentDidMount() {
        axios.get(
            `${apiHost}/education/trainer/get/course/${this.courseCode}`, {
                withCredentials: true
            }
        ).then(res => {
            this.setState({course: res.data.course, files: res.data.course.files});
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }

    selectSection(event, section) {
        event.preventDefault();
        this.setState({selectedSection: section});
    }

    render() {
        const {course, selectedSection} = this.state;

        return (
            <>
                <h2 className="page-title page-title--breadcrumbs">{course.name}</h2>
                <ul className="breadcrumbs">
                    <li><NavLink to="/lk/education">Обучение</NavLink></li>
                    <li>></li>
                    <li><NavLink to="/lk/education/trainer">Обучение от бизнес тренера</NavLink></li>
                </ul>

                <div className="md:flex blue-light-box mx-2 mb-4 course-description">
                    <div className="md:flex-shrink-0 md:w-1/4">
                        <img src={course.image} className="mr-6 mb-6"/>
                    </div>
                    <div className="mt-4 md:mt-0 md:ml-6">

                        <h3 className="p-0 course-description__title">
                            Описание обучения
                        </h3>

                        <p className="mt-5 course-description__text"
                           dangerouslySetInnerHTML={{__html: course.text}} />

                        {
                            course.points &&
                            <div className="course-description__points">
                                {course.points}
                                <div className="course-description__points__text">баллов</div>
                            </div>
                        }
                    </div>
                </div>

                <div className="sections-menu">
                    {
                        this.state.sections.map(section => {
                                const isActive = selectedSection === section.code ? 'active' : '';

                                return (
                                    <a href="#" className={`sections-menu__item ${isActive}`}
                                       key={`section-${section.code}`}
                                       onClick={(event) => this.selectSection(event, section.code)}>{section.caption}</a>
                                )
                            }
                        )
                    }
                </div>

                <div className="items-result relative w-full">
                    {
                        this.state.files.filter(file => {
                            if (selectedSection === 'all') {
                                return true;
                            } else {
                                return file.type === selectedSection
                            }
                        }).map(file => {
                            let buttonText = 'Подробнее';

                            if (file.type === 'webinar' && file.filePathType === 'link') {
                                buttonText = 'Записаться на вебинар';
                            }

                            if (file.type === 'course' && file.filePathType === 'link') {
                                buttonText = 'Перейти к курсу';
                            }

                            return (
                                <div className="items-result__item item-white box-shadow" key={`item-${file.id}`}>
                                    <img src={file.image}/>
                                    <div className="flex justify-between">
                                    </div>
                                    <div className="content">
                                        <h2 className="title">{file.name}</h2>
                                        <div className="description" dangerouslySetInnerHTML={{__html: file.text}}></div>
                                    </div>
                                    <div
                                        className="flex pt-4 text-center w-full justify-center bottom-content">
                                        {
                                            file.type === 'hometask' &&
                                            <a href={`/lk/education/trainer/course/${course.code}/homework/${file.filePath}`}
                                               className="px-3">
                                                Перейти к заданию
                                            </a>
                                        }

                                        {
                                            file.type === 'video' &&
                                            <a href={`/lk/education/trainer/course/${course.code}/video/${file.id}`}
                                               className="px-3">
                                                Подробнее
                                            </a>
                                        }

                                        {
                                            (file.type !== 'hometask' && file.type !== 'video') &&
                                            <a href={file.filePath} target="_blank" className="px-3">
                                                {buttonText}
                                            </a>
                                        }
                                    </div>
                                </div>
                            )
                        })

                    }

                </div>
            </>
        );
    }
}


export default Page;

import React from 'react';

const SVG = ({
                 fill="none",
                 width="46",
                 height="46",
                 viewBox="0 0 172 172",
                 className="",
                 style={}
             }) => (
    <svg fill={fill} width={width} style={style} height={height} viewBox={viewBox} className={`svg-icon ${className || ""}`} xmlns="http://www.w3.org/2000/svg">
        <path d="M0 172V0h172v172z"/><path d="M145.433 37.933L64.5 118.8658 33.7337 88.0996l-10.134 10.1341L64.5 139.1341l91.067-91.067z" fill="#000000" strokeWidth="1"/>
    </svg>
);

export default SVG;

import React from 'react';
import {NavLink} from 'react-router-dom';
import './education.scss';
import axios from "axios";
import {apiHost} from "../../../common/config";
import store from "../../../store/store";
import PageCourseDetail from './education-course-detail';
import _ from 'lodash';

class Page extends React.Component {

    constructor(props) {
        super(props);

        const path = this.props.location.pathname.split('/'),
            codeCourse = path[4];

        this.state = {
            courses: [],
            codeCourse: typeof codeCourse !== 'undefined' ? codeCourse : null
        }
    }

    componentDidMount() {
        axios.get(
            `${apiHost}/education/get/tests`,
            {withCredentials: true}
        ).then(res => {
            this.setState({courses: res.data});
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }

    render() {
        const { courses } = this.state;

        return (
            this.state.codeCourse
                ? <PageCourseDetail {...this.props}/>
                :
                    <>
                        <h2 className="page-title page-title--breadcrumbs">Интерактивное обучение</h2>
                        <ul className="breadcrumbs">
                            <li><NavLink to="/lk/education">Обучение</NavLink></li>
                        </ul>
                        <div className="mb-5">
                            <p className="mb-2">
                                Интерактивное обучение позволяет в удобном и понятном формате запомнить
                                информацию о ключевых продуктах компании Essilor.
                            </p>
                            <p>
                                Вы сможете не только пройти обучение по интерактивным презентациям,
                                но также будете иметь удобный доступ к видеоматериалам по каждому бренду,
                                по которым создан обучающий курс.
                            </p>
                        </div>
                        <div className="items-result relative w-full courses">
                            {
                                _.isEmpty(courses) ? null :
                                courses.map(course => (
                                <a className="items-result__item relative courses__item cursor-pointer item-light-blue"
                                   key={`course-${course.id}`}
                                   href={`/lk/education/courses/${course.code}`}>
                                    <div className="items-result__item-title justify-between uppercase font-bold">
                                        {course.name}
                                    </div>
                                    { course.status === 'start' &&
                                        <span
                                            className="absolute top-0 right-0 bg-blue-100 text-white courses__item-status courses__item-status--start">
                                            Начать
                                        </span>
                                    }

                                    { course.status === 'continue' &&
                                    <span
                                        className="absolute top-0 right-0 bg-blue-100 text-white courses__item-status courses__item-status--continue">
                                            Продолжить
                                        </span>
                                    }

                                    { course.status === 'finish' &&
                                    <span
                                        className="absolute top-0 right-0 bg-blue-100 text-white courses__item-status courses__item-status--finish">
                                            Завершено
                                        </span>
                                    }
                                </a>
                            ))
                            }
                        </div>
                    </>
        );
    }
}


export default Page;


import React from 'react';
import {NavLink} from 'react-router-dom';
import Layout from '../../../layouts/authorized';
import './library.scss';
import axios from "axios";
import {apiHost} from "../../../common/config";
import store from "../../../store/store";
import Spinner from '../../../components/UI/Spinner/Spinner'
import FileBox from "../../../components/file-box/file-box";
import Select from "../../../components/UI/Select/Select";
import {connect} from "react-redux";
import ResizeDetector from "react-resize-detector";

class Page extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            brands: [],
            sections: [],
            files: [],
            selectedBrand: '',
            selectedSection: '',
            selectedSectionName: '',
            useSelectFilter: false
        };

        this.layout = React.createRef();
    }

    componentDidMount() {
        axios.get(
            `${apiHost}/library/getBrands`,
            {
                withCredentials: true
            }
        ).then(res => {
            this.setState({brands: res.data}, () => {
                this.setState({selectedBrand: res.data[0].code}, () => this.getFiles());
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });

        axios.get(
            `${apiHost}/library/getSections`,
            {
                withCredentials: true
            }
        ).then(res => {
            this.setState({sections: res.data});
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });

        setTimeout(() => {this.addActivity()}, 3000);
    }

    getFiles() {
        axios.get(
            `${apiHost}/library/getFiles`, {
                params: {
                    brand: this.state.selectedBrand,
                    section: this.state.selectedSection
                },
                withCredentials: true
            }
        ).then(res => {
            this.setState({files: res.data});
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }

    handleBrand(id = '') {
        const {selectedBrand} = this.state;

        if (selectedBrand === id) {
            id = '';
        }

        this.setState({selectedBrand: id}, () => this.getFiles());
    }

    handleSection(section = {}) {
        this.setState({selectedSection: section.code, selectedSectionName: section.name}, () => this.getFiles());
    }

    checkLayoutSize() {
        this.setState({useSelectFilter: this.layout.current && this.layout.current.offsetWidth < 1050});
    }




    getSelectBrands() {
        return this.state.brands.map(brand => (
            {
                value: brand.code,
                label: <div className="flex flex-row"><img src={brand.image} width="50" className=" mr-2"/> {brand.name}</div>
            }
        ));
    }

    getSelectSections() {
        return this.state.sections.map(brand => (
            {
                value: brand.code,
                label: brand.name
            }
        ));
    }

    addActivity()
    {
        axios.get(
            `${apiHost}/activity/add/event/use/library`,
            {
                withCredentials: true,
            }
        ).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }

    renderSelectVersion() {
        const optinonsBrands = [{value: '', label: 'Все'}].concat(this.getSelectBrands());

        return (
            <div className="filters flex w-full flex-col sm:flex-row mb-4 items-start">
                <div className="flex flex-col sm:flex-row flex-wrap sm:items-center  items-start mr-6 mt-2">
                    <div className="pr-4">Бренд</div>
                    <Select
                        placeholder=""
                        options={optinonsBrands}
                        className="w-64"
                        onChange={option => this.handleBrand(option.value)}
                    />
                </div>
                <div className="flex flex-col sm:flex-row flex-wrap sm:items-center  items-start mr-6 mt-2">
                    <div className="pr-4 ">Раздел</div>
                    <Select
                        placeholder=""
                        options={this.getSelectSections()}
                        className="w-64"
                        onChange={option => this.handleSection({code: option.value, name: option.label})}
                    />
                </div>
            </div>
        )
    }

    renderButtonsVersion() {
        return (
            <>
                <ul className="brands-menu">
                    {
                        this.state.brands.map(brand => (
                            <li key={brand.code}>
                                <div onClick={() => this.handleBrand(brand.code)}
                                     className={this.state.selectedBrand === brand.code ? 'active' : ''}>
                                    <img className="w-48 max-w-full mx-auto object-contain h-24 cursor-pointer"
                                         src={brand.image} title={brand.name}/>
                                </div>
                            </li>
                        ))
                    }
                </ul>

                <ul className="submenu">
                    {
                        this.state.sections.map(section => (
                            <li key={section.code} onClick={() => this.handleSection(section)}
                                className={this.state.selectedSection === section.code ? 'active' : ''}>
                                {section.name}
                            </li>
                        ))
                    }
                </ul>
            </>
        )
    }


    render() {

        return (
            <Layout layoutContentRef={this.layout}>

                <ResizeDetector
                    handleWidth
                    onResize={() => this.checkLayoutSize()}
                />

                <h2 className="page-title">Библиотека знаний</h2>

                {
                    this.state.useSelectFilter
                        ? this.renderSelectVersion()
                        : this.renderButtonsVersion()
                }

                <h2 className="page-title">{this.state.selectedSectionName}</h2>

                <div className="items-result relative w-full">
                    {
                        this.state.files.map(file => (
                            <FileBox {...file}/>
                        ))
                    }

                </div>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        menuIsOpen: state.menu.menuIsOpen
    }
};

export default connect(mapStateToProps)(Page);

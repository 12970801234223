import React from 'react';
import {NavLink} from "react-router-dom";
import axios from "axios";
import {apiHost} from "../../../common/config";
import store from "../../../store/store";
import "./education-academy-test.scss";
import Tests from "../../../components/tests/tests";
import Button from "../../../components/UI/Button/Button";
import Modal from "../../../components/modal/modal";
import {withRouter} from "react-router-dom";
import _ from 'lodash';

function num_word(value, words){
    value = Math.abs(value) % 100;
    var num = value % 10;
    if(value > 10 && value < 20) return words[2];
    if(num > 1 && num < 5) return words[1];
    if(num == 1) return words[0];
    return words[2];
}

class Page extends React.Component {

    constructor(props) {
        super(props);

        const path = this.props.location.pathname.split('/');

        this.courseId = path[5];

        this.state = {
            questions: [],
            startTime: 0,
            testId: 0,
            title: '',

            successModalOpen: false,
            failModalOpen: false,

            testResult: [],
            wrongQuestionBlocks: [],
            showSendButton: false
        }
    }

    componentDidMount() {
        this.fetchTests();
    }

    fetchTests() {
        axios.get(
            `${apiHost}/academy/get/course/test/${this.courseId}`,
            {
                withCredentials: true,
            }
        ).then(res => {
            if (!res.data.courseTestUse) {
                this.props.history.push(`/lk/education/academy`);
            } else {
                this.setState(
                    {
                        questions: res.data.questions,
                        startTime: res.data.startTime,
                        testId: res.data.testId,
                        showSendButton: true,
                        wrongQuestionBlocks: [],
                        title: res.data.title
                    }
                );
            }
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }


    closeModal = () => {
        this.setState({successModalOpen: false, failModalOpen: false});
    };

    backToEducation = () => {
        this.props.history.push(`/lk/education/academy`);
    };

    repeatTest = () => {
        this.closeModal();
        this.setState(
            {
                questions: [],
                startTime: 0,
                testId: 0,
            },
            this.fetchTests()
        );
    };

    showWrongQuestionBlocks = () => {
        this.setState({
                wrongQuestionBlocks: this.state.testResult.pop().wrongQuestionBlocks
            }, this.closeModal()
        );
    }

    parseTestResult = (result) => {
        if (_.get(_.last(result), 'pass', false) === true) {
            this.setState({successModalOpen: true, showSendButton: false});
        } else {
            this.setState(
                {
                    failModalOpen: true,
                    testResult: result,
                    showSendButton: false
                }
            );
        }
    };

    submitTest = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        formData.append('startTime', this.state.startTime);
        formData.append('courseId', this.courseId);

        axios(`${apiHost}/tests/submit/test/${this.state.testId}`,
            {
                method: "post",
                data: formData,
                withCredentials: true
            }
        ).then(res => {
            this.parseTestResult(res.data);
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }

    render() {

        return (
            <>
                <h2 className="page-title">{this.state.title}</h2>
                <ul className="breadcrumbs">
                    <li><NavLink to="/lk/education">Обучение</NavLink></li>
                    <li>></li>
                    <li><NavLink to="/lk/education/academy">Essilor Академия</NavLink></li>
                </ul>

                <h3>Тест</h3>
                <form onSubmit={this.submitTest}>
                    <Tests questions={this.state.questions} wrongQuestionBlocks={this.state.wrongQuestionBlocks}/>

                    <Button type="submit"
                            className={`btn-thin btn-blue mb-8 ${!this.state.showSendButton ? `hidden` : ``}`}
                    >Проверить</Button>
                </form>

                <Modal isOpen={this.state.successModalOpen} onClose={this.backToEducation} hideCloseButton={true}
                       boxStyle={{maxWidth: '30rem'}}>
                    <div className="text-center">
                        <h1 className="text-4xl font-bold text-essilor-navy uppercase">Поздравляем!</h1>
                        <h2 className="text-xl mt-2  mb-6 font-bold text-essilor-navy">Вы успешно прошли тест!</h2>

                        <a className="text-essilor-light-navy underline" href="#"
                           onClick={() => this.backToEducation()}>Вернуться в обучение</a>
                    </div>
                </Modal>
                <Modal isOpen={this.state.successModalOpen} onClose={this.backToEducation} hideCloseButton={true}
                       boxStyle={{maxWidth: '37rem'}}>
                    <div className="text-center">
                        <h1 className="text-4xl font-bold text-essilor-navy uppercase">Поздравляем!</h1>
                        <h2 className="text-xl mt-2  mb-6 font-bold text-essilor-navy">Вы успешно прошли тест!</h2>

                        <a className="text-essilor-light-navy underline"
                           href="#"
                           onClick={() => this.backToEducation()}>Вернуться в обучение</a>
                    </div>
                </Modal>

                <Modal isOpen={this.state.failModalOpen} onClose={this.showWrongQuestionBlocks} hideCloseButton={true}
                       boxStyle={{maxWidth: '37rem'}}>
                    <div className="text-center">

                        <h1 className="text-3xl font-bold text-essilor-navy uppercase">Тест не пройден!</h1>
                        {
                            _.get(_.last(this.state.testResult), 'retry', false) === true &&
                            <p className="text-xl mt-2  mb-6 text-essilor-navy">
                                Попробуйти изучить материалы лучше  и пройти тест ещё раз!</p>
                        }

                        {
                            (
                                _.get(_.last(this.state.testResult), 'retry', false) === false &&
                                _.get(_.last(this.state.testResult), 'lockDays', 0) == 0
                            ) &&
                            <p className="text-xl mt-2  mb-6 text-essilor-navy">
                                Вы исчерпали все попытки на прохождение теста. Вам необходимо зарегистрироваться на курс.</p>
                        }

                        {
                            (
                                _.get(_.last(this.state.testResult), 'retry', false) === false &&
                                _.get(_.last(this.state.testResult), 'lockDays', 0) != 0
                            ) &&
                            <p className="text-xl mt-2  mb-6 text-essilor-navy">
                                Вы исчерпали все попытки на прохождение теста. Следующий раз вы сможете пройти тест через { _.get(_.last(this.state.testResult), 'lockDays', 0) } { num_word(_.get(_.last(this.state.testResult), 'lockDays', 0), ['день', 'дня', 'дней']) }.</p>
                        }

                        <div className="flex flex-col sm:flex-row justify-around mb-6">
                            {
                                _.get(_.last(this.state.testResult), 'retry', false) === true &&
                                <Button className="btn btn-orange-yellow btn-narrow w-56" style={{fontWeight: 'normal'}}
                                        onClick={() => this.repeatTest()}>Пройти тест ещё раз</Button>
                            }

                            <Button className="btn btn-dark-blue btn-narrow w-56"
                                    onClick={() => this.showWrongQuestionBlocks()}
                                    style={{fontWeight: 'normal'}}
                            >Показать ответы</Button>
                        </div>

                        <a className="text-essilor-light-navy underline" href="#"
                           onClick={() => this.backToEducation()}>Вернуться в обучение</a>
                    </div>
                </Modal>
            </>

        );
    }
}

export default withRouter(Page);

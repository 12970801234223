import React from 'react';
import Layout from '../../../layouts/authorized';
import axios from "axios";
import {apiHost} from "../../../common/config";
import store from "../../../store/store";
import _ from 'lodash';
import {sortableContainer, sortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';


const SortableItem = sortableElement(
    ({value, number, onClickFunc}) => {
        const bgActie = value.active ? 'bg-light-blue text-white' : '';

        return <li className="mb-3 w-full" onClick={() => {onClickFunc(number)}}>
            <div className={`bg-light-light-blue py-2 px-4 font-semibold uppercase cursor-pointer flex justify-between items-center ${bgActie}`}>
                <span>{`${number + 1}. ${value.name}`}</span>
                {bgActie ? <i className="icon-arrow-b text-white"></i> : <i className="icon-arrow-t"></i>}
            </div>
            {
                !bgActie || <div className="text-lg bg-light-light-blue py-4 px-4 text-essilor-navy">{value.text}</div>
            }


        </li>
    }
);

const SortableContainer = sortableContainer(({children}) => {
    return <ul className="w-full max-w-4xl">{children}</ul>;
});

class Page extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            items: [],
        };
    }

    componentDidMount() {
        axios.get(
            `${apiHost}/lenses/get/settings/list`,
            {withCredentials: true}
        ).then(res => {
            const items = res.data.map(value => {
                value.active = false;
                return value;
            });
            this.setState({items});
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }

    saveSettings() {
        const formData = new FormData();
        formData.set('items', JSON.stringify(_.map(this.state.items, 'id')));

        axios(
            `${apiHost}/lenses/save/settings`,
            {
                method: 'post',
                data: formData,
                withCredentials: true
            }
        ).then(res => {
            store.dispatch({
                type: 'addResponseStatus',
                status: {
                    code: 200,
                    message: res.data.message
                }
            })
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }

    onSortEnd = ({oldIndex, newIndex}) => {
        this.setState(
            ({items}) => ({
                items: arrayMove(items, oldIndex, newIndex),
            }),
            function () {
                this.saveSettings()
            }
        );
    };

    onClickFunc = (index) => {
        const {items} = this.state;
        items[index].active = !items[index].active;

        this.setState({items});
    }

    render() {
        return (
            <Layout>
                <h2 className="page-title">Настройки подбиратора</h2>
                <p className="text-gray mb-5">Пожалуйста, раставьте в порядке приоритетности</p>
                {
                    <SortableContainer onSortEnd={this.onSortEnd} pressDelay={200}>
                        {this.state.items.map((value, index) => (
                            <SortableItem
                                key={`item-${index}`}
                                number={index}
                                index={index}
                                value={value}
                                onClickFunc={this.onClickFunc}
                            />
                        ))}
                    </SortableContainer>
                }
            </Layout>
        );
    }
}



export default Page;

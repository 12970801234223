import React from 'react';
import axios from 'axios';
import store from '../../store/store';


export default class Form extends React.Component {
    requestUrl = '';

    validationRules = {};

    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            responseError: ''
        };
    }

    isValid = (name, formData) => {
        const value = formData.get(name);

        if (!this.validationRules.hasOwnProperty(name)) {
            return true;
        }

        const field = this.validationRules[name];
        let hasError = false,
            errorText = '';

        if (field.hasOwnProperty('require') && field.require) {
            hasError = !value;
            errorText = 'обязательно для заполнения';

            if (value instanceof File) {
                hasError = !value.size;
                errorText = 'необходимо выбрать файл';
            }
        }

        if (field.hasOwnProperty('minLength') && field.minLength && !hasError && value !== '') {
            hasError = value.length < field.minLength;
            errorText = `минимум ${field.minLength} символов`;
        }
        if (field.hasOwnProperty('maxLength') && field.maxLengthh && !hasError && value !== '') {
            hasError = value.length < field.maxLength;
            errorText = `максимум ${field.maxLength} символов`;
        }
        if (field.hasOwnProperty('email') && field.email && !hasError) {
            const re = /\S+@\S+\.\S+/;
            hasError = !re.test(value);
            errorText = 'неверный формат email';
        }

        if (field.hasOwnProperty('equalTo') && field.equalTo && !hasError && value !== '') {
            hasError = value !== formData.get(field.equalTo);
            errorText = `должен совпадать с «${this.validationRules[field.equalTo].title}»`;
        }

        if (name === 'name' && !hasError) {
            const re = /^[а-яё\s/-]*$/i;
            hasError = !re.test(value);
            errorText = 'поле может содержать только кириллицу';
        }

        if (name === 'name' && !hasError) {
            const words = value.split(' ');
            hasError = words.length !== 3;

            if (!hasError) {
                const handledWords = words.filter((word) => word.length >= 3);

                hasError = handledWords.length !== words.length;
            }

            errorText = 'неверный формат поля. Поле должно содержать три слова.';
        }

        if (!hasError) {
            return true;
        }

        return errorText;
    }


    validateForm = formData => {
        let errors = {};
        Object.keys(this.validationRules).forEach(name => {
            let errorText = this.isValid(name, formData);
            if (errorText !== true) {
                errors[name] = {...this.validationRules[name], errorText};
            }
        });

        this.setState({errors}, () => this.submitRequest(formData));
    };

    removeError = event => {

        let name = event;

        if (event.hasOwnProperty('target')) {
            name = event.target.getAttribute('name');
        }

        let errors = {...this.state.errors};
        delete errors[name];

        this.setState({errors, responseError: ''});
    }

    submitHandler(event) {
        event.preventDefault();

        const formData = new FormData(event.target);

        this.validateForm(formData);
    }

    submitRequest(formData) {
        if (!Object.keys(this.state.errors).length) {
            axios(
                this.requestUrl,
                {
                    method: 'post',
                    data: formData,
                    withCredentials: true
                }
            )
            .then(res => {
                store.dispatch({
                    type: 'addResponseStatus',
                    status: {
                        code: 200,
                        message: res.data.message
                    }
                });

                this.submitRequestCalllback(res);
            })
            .catch(error => {
                store.dispatch({
                    type: 'addResponseStatus',
                    status: error.response.data.error
                })
                this.setState({responseError: error.response.data.error.message});

                this.errorResponceCallback(error);
            });
        } else {
            store.dispatch({
                type: 'openFailModal',
                formErrors: this.state.errors
            });
        }

    }


    submitRequestCalllback = (res) => {

    }

    errorResponceCallback = (error) => {

    }
}

import React from 'react';
import '../sales-stat.scss';


class StatTableCellVsASP extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    getClassWrapper(currentYearCount, currentYearAmount, yearCount, yearAmount) {
        if (currentYearCount == 0 || currentYearCount == 0 || yearCount == 0 || yearAmount == 0) {
            return '';
        }

        const result = (currentYearAmount/currentYearCount - yearAmount/yearCount)/(yearAmount/yearCount) * 100;

        if (result > 0) {
            return  'sales-stat-cell-wrapper-green';
        }

        if (result < 0) {
            return  'sales-stat-cell-wrapper-red';
        }

        return '';
    }

    calculateCount(currentYearCount, currentYearAmount, yearCount, yearAmount) {

        if (currentYearCount == 0 || currentYearCount == 0 || yearCount == 0 || yearAmount == 0) {
            return '-';
        }

        return `${this.formatter((currentYearAmount/currentYearCount - yearAmount/yearCount)/(yearAmount/yearCount) * 100)}%`;
    }

    formatter(count) {
        const formatter = new Intl.NumberFormat('ru-RU', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

        return formatter.format(count);
    }

    render() {

        return (
            <td className={this.getClassWrapper(
                this.props.currentYearCount,
                this.props.currentYearAmount,
                this.props.yearCount,
                this.props.yearAmount)}>
                {
                    this.calculateCount(
                        this.props.currentYearCount,
                        this.props.currentYearAmount,
                        this.props.yearCount,
                        this.props.yearAmount
                    )
                }
            </td>
        );
    }
}

export default StatTableCellVsASP;
import React from 'react';

import './notifications.scss';


class NotificationItem extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    openLink = (url) => {
        if (url) {
            const newWindow = window.open(url, '', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        }
    }

    render() {
        const {title, text, date, link, section, location} = this.props;
        const pointerClass = link ? 'cursor-pointer' : '';

        return (

            <div onClick={() => this.openLink(link)}
                 className={`blue-light-box rounded-lg max-w-2/3 mb-4 relative overflow-hidden ${pointerClass}`}>
                {
                    section.caption &&
                    <div className={`notifications-item__section notifications-item__section-${section.type}`}>
                        {section.caption}
                    </div>
                }

                {
                    location &&
                    <div className={`notifications-item__location`}>
                        Расположение: <span className='uppercase'>{location}</span>
                    </div>
                }
                <h4 className='pt-1 mb-2 notifications-item__title'>{`${title}`}</h4>
                <p>{text}</p>
                <p className='text-right text-gray text-sm'>{date}</p>
            </div>
        );
    }
}

export default NotificationItem;

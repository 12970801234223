import React from 'react';
import Icon from '../../../icons';
import './Radio.scss';

const Radio = React.forwardRef((props, ref) => {
    let hasError = false;

    if (props.hasOwnProperty('errors') && props.errors.hasOwnProperty(props.name) && props.errors[props.name]) {
        hasError = true;
    }

    return (
        <label className="custom-label flex items-center mb-6">
            <div className="w-8 h-8 p-1 flex justify-center items-center mr-2">
                <input type="radio" className="hidden" {...props} ref={ref}/>
                <div className="w-6 h-6 pointer-events-none rounded-full border border-black">
                    <div/>
                </div>
            </div>
            <span className="select-none flex justify-between ">
                {props.label}
                {hasError ? (<i className="icon-alert-triangle error-icon"></i>) : ''}
            </span>
        </label>
    )
});

export default Radio;

import React from 'react';
import Modal from '../../components/modal/modal';
import Textarea from "../UI/Textarea/Textarea";
import Input from "../UI/Input/Input";
import Select from "../UI/Select/Select";
import {apiHost} from "../../common/config";
import EditSaleModal from "./edit-sale-modal";
import CustomDatePicker from "../UI/DatePicker/DatePicker";
import RadioInput from "../UI/Radio/Radio";
import Button from "../UI/Button/Button";
import axios from "axios";
import store from "../../store/store";

class EditSaleModalExt extends EditSaleModal {

    constructor(props) {
        super(props);

        this.roleText = this.props.role === 'doctor' ? 'врача' : 'консультанта';

        this.state = {
            ...this.state,
            filledComment: ''
        };
    }

    componentDidMount() {
        axios.get(
            `${apiHost}/sale/get/formData`,
            {withCredentials: true}
        ).then(res => {
            this.setState({
                coveringList: res.data.covering,
                designList: res.data.design,
                nameList: res.data.names,
                sourceList: res.data.source,
                groupList: res.data.group,
                groupFetched: false,
                designFetched: false,
                namesFetched: false,
                coveringFetched: false,
                sourceSelected: null,
                groupSelected: null,
                designSelected: null,
                nameSelected: null,
                coveringSelected: null,
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }

    submitHandler (event) {
        event.preventDefault();
        const formData = new FormData(event.target);

        axios(`${apiHost}/sale/edit/`+this.props.id,
            {
                method: "post",
                data: formData,
                withCredentials: true
            }
        ).then(res => {
            if (res.data.status === 'success') {
                this.setState({
                    showModalOpen: true,
                    showModalText: 'Данные успешно обновлены.',
                    uploadedStatus: true,
                    editSaleModalOpen: false,
                });
            } else {
                this.setState({
                    showModalOpen: true,
                    showModalText: res.data.error,
                    editSaleModalOpen: false,
                });
            }
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }

    fetchGroups(source) {
        axios.get(
            `${apiHost}/sale/combinationElements`,
            {
                withCredentials: true,
                params: {
                    source: source
                }
            }
        ).then(res => {
            this.setState({
                groupList: res.data.UF_GROUP,
                groupFetched: true,
                designFetched: false,
                namesFetched: false,
                coveringFetched: false,
                sourceSelected: source,
                groupSelected: null,
                designSelected: null,
                nameSelected: null,
                coveringSelected: null,
                coveringList: [],
                designList: [],
                nameList: [],
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }

    fetchDesign(source, group) {
        axios.get(
            `${apiHost}/sale/combinationElements`,
            {
                withCredentials: true,
                params: {
                    source: source,
                    group: group,
                }
            }
        ).then(res => {
            this.setState({
                designList: res.data.UF_DESIGN,
                groupFetched: true,
                designFetched: true,
                namesFetched: false,
                coveringFetched: false,
                sourceSelected: source,
                groupSelected: group,
                designSelected: null,
                nameSelected: null,
                coveringSelected: null,
                coveringList: [],
                nameList: [],
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }

    fetchNames(source, group, design) {
        axios.get(
            `${apiHost}/sale/combinationElements`,
            {
                withCredentials: true,
                params: {
                    source: source,
                    group: group,
                    design: design,
                }
            }
        ).then(res => {
            this.setState({
                nameList: res.data.UF_NAME,
                groupFetched: true,
                designFetched: true,
                namesFetched: true,
                coveringFetched: false,
                sourceSelected: source,
                groupSelected: group,
                designSelected: design,
                nameSelected: null,
                coveringSelected: null,
                coveringList: [],
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }

    fetchCovering(source, group, design, name) {
        axios.get(
            `${apiHost}/sale/combinationElements`,
            {
                withCredentials: true,
                params: {
                    source: source,
                    group: group,
                    design: design,
                    name: name,
                }
            }
        ).then(res => {
            this.setState({
                coveringList: res.data.UF_COVERING,
                groupFetched: true,
                designFetched: true,
                namesFetched: true,
                coveringFetched: true,
                sourceSelected: source,
                groupSelected: group,
                designSelected: design,
                nameSelected: name,
                coveringSelected: null,
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }

    checkSendButton(event) {
        super.checkSendButton(event);
        switch (event.target.name) {
            case 'email':
                this.setState({filledEmail: event.target.value}, this.fillSubmitButtonFunc);
                break;
            default:
                break;
        }
    }

    closeModal() {
        const { uploadedStatus } = this.state;

        if (uploadedStatus) {
            window.location.reload();
        }

        this.setState({showModalOpen: false, uploadedStatus: false});
    }

    submitFunc = () => {
        const formData = new FormData();
        const subject = this.state.selectedSubject.value;

        formData.append('subjectCode', subject);
        formData.append('message', this.state.filledMessage);
        formData.append('email', this.state.filledEmail);

        this.validateForm(formData);
    }

    fillSubmitButtonFunc() {
        if (this.state.filledMessage !== '' && this.state.filledEmail !== '') {
            this.setState({submitButtonFunc: this.submitFunc})
        } else {
            this.setState({submitButtonFunc: false})
        }
    }

    handleChangeSubject(selectedOption) {
        this.setState({selectedSubject: selectedOption});
    }

    render() {
        const {showForm, selectedSubject} = this.state;

        return (
            <>

                <button className="btn btn-blue btn-narrow mt-8 float-right"
                        onClick={(event) => this.showEditModal(event)}>
                    <span>✎</span>
                </button>

                <Modal isOpen={this.state.showModalOpen} onClose={() => this.closeModal()} hideCloseButton={true}
                       boxStyle={{maxWidth: '48rem'}}>
                    <div className="text-center">
                        <h2 className="title-semibold title-semibold__black">Изменение продаж!</h2>
                        <p className="text-center">{this.state.showModalText}</p>

                        <Button className="btn btn-blue btn-narrow w-56 mt-10" style={{fontWeight: 'normal'}}
                                onClick={() => this.closeModal()}>Закрыть</Button>
                    </div>
                </Modal>

                <Modal isOpen={this.state.editSaleModalOpen} onClose={this.closeEditModal} title="Изменение продажи"
                       onSubmit={this.state.submitButtonFunc} hideCloseButton={!this.state.showButtonClose} boxStyle={{maxWidth: '48rem'}}>

                    <form onSubmit={(event) => this.submitHandler(event)}
                          method="post">
                        <div className="w-full md:flex md:justify-between">
                            <div className="md:w-3/12 md:mr-5">
                                <CustomDatePicker
                                    date={this.props.date}
                                />
                            </div>
                            <div className="md:w-9/12 md:mr-5">
                                <Select
                                    name="source"
                                    label="Источник"
                                    placeholder="Выберите из списка"
                                    options={this.state.sourceList}
                                    className="w-full"
                                    defaultValue={{ label: this.props.source, value: this.props.sourceValue }}
                                    onChange={e => {
                                        this.fetchGroups(
                                            e.value,
                                        );
                                    }}
                                />
                            </div>
                        </div>

                        <div className="w-full md:flex md:justify-between">
                            <div className="md:w-6/12 md:mr-5">
                                <Select
                                    name="group"
                                    label="Группа"
                                    placeholder="Выберите из списка"
                                    options={this.state.groupList}
                                    className="w-full"
                                    defaultValue={{ label: this.props.group, value: this.props.groupValue }}
                                    isDisabled={!this.state.groupFetched}
                                    onChange={e => {
                                        this.fetchDesign(
                                            this.state.sourceSelected,
                                            e.value,
                                        );
                                    }}
                                />
                            </div>
                            <div className="md:w-6/12">
                                <Select
                                    name="design"
                                    label="Дизайн"
                                    placeholder="Выберите наименование линзы"
                                    options={this.state.designList}
                                    className="w-full"
                                    defaultValue={{ label: this.props.design, value: this.props.designValue }}
                                    isDisabled={!this.state.designFetched}
                                    onChange={e => {
                                        this.fetchNames(
                                            this.state.sourceSelected,
                                            this.state.groupSelected,
                                            e.value,
                                        );
                                    }}
                                />
                            </div>

                        </div>

                        <div className="w-full md:flex md:justify-between">
                            <div className="md:w-4/12 md:mr-5">
                                <Select
                                    name="name"
                                    label="Наименование"
                                    placeholder="Выберите из списка"
                                    options={this.state.nameList}
                                    className="w-full"
                                    defaultValue={{ label: this.props.name, value: this.props.nameValue }}
                                    isDisabled={!this.state.namesFetched}
                                    onChange={e => {
                                        this.fetchCovering(
                                            this.state.sourceSelected,
                                            this.state.groupSelected,
                                            this.state.designSelected,
                                            e.value,
                                        );
                                    }}
                                />
                            </div>
                            <div className="md:w-4/12 md:mr-5">
                                <Select
                                    name="covering"
                                    label="Покрытиe"
                                    placeholder="Выберите из списка"
                                    options={this.state.coveringList}
                                    defaultValue={{ label: this.props.covering, value: this.props.coveringValue }}
                                    className="w-full"
                                    isDisabled={!this.state.coveringFetched}
                                />
                            </div>

                            <div className="md:w-4/12">
                                <Input label="Кол-во линз"
                                       name="count"
                                       type="number"
                                       min="1"
                                       max="100"
                                       defaultValue={this.props.count}
                                       className="w-full max-w-3xl"/>
                            </div>
                        </div>

                        <div className="w-full md:flex md:justify-between">
                            <div className="md:w-6/12 md:mr-5">
                                <RadioInput
                                    defaultChecked={this.props.technologyValue === null}
                                    name="technology"
                                    value=""
                                    label='Без дополнительных технологий'/>
                                <RadioInput
                                    defaultChceked={this.props.technologyValue === 'transitionsClassic'}
                                    name="technology"
                                    value="transitionsClassic"
                                    label='Transitions Classic'/>
                                <RadioInput
                                    defaultChceked={this.props.technologyValue === 'transitionsVII'}
                                    name="technology"
                                    value="transitionsVII"
                                    label='Transitions VII'/>
                                <RadioInput
                                    defaultChceked={this.props.technologyValue === 'transitionsGen8'}
                                    name="technology"
                                    value="transitionsGen8"
                                    label='Transitions Gen8'/>
                                <RadioInput
                                    defaultChceked={this.props.technologyValue === 'transitionsXTRActive'}
                                    name="technology"
                                    value="transitionsXTRActive"
                                    label='Transitions XTRActive'/>
                            </div>
                            <div className="md:w-6/12 md:mr-5">
                                <RadioInput
                                    defaultChceked={this.props.technologyValue === 'blueUVCapture'}
                                    name="technology"
                                    value="blueUVCapture"
                                    label='Blue UV Capture'/>
                                <RadioInput
                                    defaultChceked={this.props.technologyValue === 'acclimatesIII'}
                                    name="technology"
                                    value="acclimatesIII"
                                    label='Acclimates III'/>
                                <RadioInput
                                    defaultChceked={this.props.technologyValue === 'blueUVStopper'}
                                    name="technology"
                                    value="blueUVStopper"
                                    label='Blue UV Stopper'/>
                                <RadioInput
                                    defaultChceked={this.props.technologyValue === 'lightAdapt'}
                                    name="technology"
                                    value="lightAdapt"
                                    label='LightAdapt'/>
                                <RadioInput
                                    defaultChceked={this.props.technologyValue === 'xperio'}
                                    name="technology"
                                    value="xperio"
                                    label='Xperio'/>
                            </div>
                        </div>

                        <Textarea name="comment"
                                  defaultValue={this.props.comment}
                                  label={`Примечание ${this.roleText}`}
                                  placeholder="Введите текст"
                                  className="w-full max-w-3xl"
                                  rows={4}/>

                        <Button type="submit" className="btn btn-blue btn-thin uppercase my-8"
                                disabled={this.state.submitDisabled}>
                            Изменить
                        </Button>
                    </form>

                </Modal>
            </>
        );
    }
}

export default EditSaleModalExt;

const initState = {
    widgetNewsData: []
}

export default (state = initState, action) => {
    switch (action.type) {
        case 'getNewsWidget':
            return {
                ...state, widgetNewsData: action.widgetNewsData
            };
        default:
            return {...state}
    }
}
import React from 'react';
import {Redirect} from 'react-router-dom';
import store from '../../store/store';
import axios from "axios";
import {apiHost} from "../../common/config";

const Page = () => {
    const logout = async () => {
        return await axios.get(
            `${apiHost}/user/logout`,
            {withCredentials: true}
        ).then(res => {
            store.dispatch({
                type: 'logout'
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    };

    React.useEffect(() => {
        logout().then(() =>  window.location.href='/');
    }, []);

    return '';
};

export default Page;

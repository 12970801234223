import React from 'react';
import {NavLink} from 'react-router-dom';
import {connect} from "react-redux";
import axios from "axios";
import ResizeDetector from "react-resize-detector";
import ReactPlayer from 'react-player';

import Layout from '../../../layouts/authorized';
import './library.scss';
import {apiHost} from "../../../common/config";
import store from "../../../store/store";

class Page extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            name: '',
            file: {
                src: '',
                type: ''
            },
            isRequestSend: false,
            played: 0
        };

        this.layout = React.createRef();

        this.elementId = this.props.location.pathname.substring(this.props.location.pathname.lastIndexOf('/') + 1);
    }

    componentDidMount() {

        axios.get(
            `${apiHost}/file/ibelement/${this.elementId}`, {
                withCredentials: true
            }
        ).then(res => {
            this.setState({name: res.data.name, file: res.data.file});
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });

        setTimeout(() => {this.addActivity()}, 3000);
    }

    checkLayoutSize() {
        this.setState({useSelectFilter: this.layout.current && this.layout.current.offsetWidth < 1050});
    }

    handleProgress = state => {
        const {isRequestSend} = this.state;

        if (!isRequestSend && state.played > 0.8) {
            this.saveEvent();
            this.setState({isRequestSend: true});
        }
    }

    saveEvent = () => {
        const formData = new FormData();

        axios(`${apiHost}/library/log/video/ibelement/${this.elementId}`,
            {
                method: "post",
                data: formData,
                withCredentials: true
            }
        ).then(res => {
            // do nothing
        }).catch(error => {
            this.setState({isRequestSend: false});
        });
    }

    addActivity = () => {
        const formData = new FormData();

        axios(`${apiHost}/library/log/view/document/${this.elementId}`,
            {
                method: "post",
                data: formData,
                withCredentials: true
            }
        ).then(res => {
            // do nothing
        }).catch(error => {
            this.setState({isRequestSend: false});
        });
    }

    renderViewer() {
        const fileType = this.state.file.type;
        const filePath = this.state.file.src;

        if (fileType === '') {
            return '<>';
        } else if (fileType === 'video') {
            return (
                <ReactPlayer
                    width={'100%'}
                    url={filePath}
                    controls
                    onProgress={this.handleProgress}/>
            )
        } else if (fileType === 'image') {
            return <img src={filePath}/>
        } else if (fileType === 'pdf') {
            const pdfPath = `${filePath}#toolbar=0`;
            return (
                <div className="w-full flex">
                    <embed src={pdfPath}
                           type="application/pdf"
                           width="100%"
                           height="600px" />
                </div>
            )
        } else {
            return <p className="text-white pr-4">Формат не поддерживается</p>
        }
    }

    render() {

        return (
            <Layout layoutContentRef={this.layout}>

                <ResizeDetector
                    handleWidth
                    onResize={() => this.checkLayoutSize()}
                />

                <div className="layout-content items-start relative">

                    <h2 className="page-title">Библиотека знаний</h2><br/>
                    <h3 className="page-title page-title--withoutborder page-title--second">{this.state.name}</h3>

                    {
                        this.state.file.type && this.renderViewer()
                    }
                </div>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        menuIsOpen: state.menu.menuIsOpen
    }
};

export default connect(mapStateToProps)(Page);

import RadioInput from "../UI/Radio/Radio";
import Checkbox from "../UI/Checkbox/Checkbox";
import React from "react";
import './tests.scss'

const Tests = (props) => {
    return (
        <div className="relative w-full flex flex-wrap content-start flex-col tests-component">
            {
                props.questions.map((question, index) => {
                    const isWrongClass = props.wrongQuestionBlocks.includes(index) ? 'wrong-test' : '';

                    return (
                        <div className={`blue-light-box w-auto mb-6 ${isWrongClass}`} key={`t-${index}`}>
                            {
                                isWrongClass && <div className="wrong-test__label">Не верно</div>
                            }
                            <div className="mb-6">
                                <span>{index + 1}. {question.question}</span>
                            </div>
                            {
                                question.answers.map((item, num) => (
                                    <div key={`ta-${num}`}>
                                        {
                                            !question.isMultiple
                                                ? <RadioInput name={`answers[${index}][]`} label={item}
                                                              value={num + 1} disabled={isWrongClass !== ''}/>
                                                :
                                                <Checkbox name={`answers[${index}][]`} label={item} value={num + 1}
                                                          className="is-orange" disabled={isWrongClass !== ''}/>
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    )
                })
            }
        </div>
    )
};

export default Tests;

import React from 'react';
import './modal.scss';
import Button from "../UI/Button/Button";

class Modal extends React.Component {

    constructor(props) {
        super(props);

        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.isOpen) {
            document.addEventListener('click', this.handleClickOutside, false);
        } else {
            document.removeEventListener('click', this.handleClickOutside, false);
        }
    }

    handleClickOutside(event) {
        if (this.refNode && !this.refNode.contains(event.target) && !event.target.classList.contains("selectInput__option")) {
            this.props.onClose();
        }
    }

    setRefNode = node => {
        this.refNode = node;
    };

    render() {
        return (
            <>
                {this.props.isOpen &&
                <div
                    className="modal-window fixed w-full h-100 inset-0 z-50 overflow-hidden justify-center items-center animated faster fadeIn flex text-black"
                    style={{background: "rgba(0,0,0,.7)"}}>
                    <div ref={this.setRefNode}
                         className="border shadow-lg modal-window__container bg-white w-11/12 md:max-w-4xl mx-auto rounded shadow-lg z-50 overflow-y-auto max-h-screen"
                        style={this.props.boxStyle}>
                        <div className="modal-content py-4 text-left px-6">
                            <div className="flex justify-between items-center pb-3">
                                <p className={`title-semibold title-semibold__black ${this.props.titleAdditionalStyle}`}>
                                    {this.props.title}
                                </p>
                                <div className="modal-close cursor-pointer z-50" onClick={() => this.props.onClose()}>
                                    <svg className="fill-current text-black" xmlns="http://www.w3.org/2000/svg"
                                         width="18"
                                         height="18"
                                         viewBox="0 0 18 18">
                                        <path
                                            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                                        </path>
                                    </svg>
                                </div>
                            </div>
                            <div className="my-5">
                                {this.props.children}
                            </div>
                            <div className="flex justify-end pt-2 flex-col md:flex-row">
                                {
                                    !this.props.hideCloseButton && <Button onClick={() => this.props.onClose()}
                                                                  className="btn-grey uppercase">Отмена
                                    </Button>
                                }
                                {
                                    this.props.onSubmit && <Button className="btn-blue uppercase my-4 md:my-0 md:ml-3"
                                                                   onClick={() => this.props.onSubmit()}>Отправить
                                    </Button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                }
            </>
        )
    }
};

export default Modal;

import React from 'react';
import Layout from '../../../layouts/authorized';
import SalesUploadForm from './sales-upload-form';

class Page extends React.Component {

    render() {

        return (
            <Layout>
                <h2 className="page-title">Рекомендации</h2>

                <div className="blue-light-box rounded-lg max-w-2/3 mb-20">
                    <h3 className="custom-grey-color text-2xl">
                        <i className="icon-alert-circle text-essilor-green mr-5 text-5xl"></i>
                        Акция: Удвоенные баллы за линзы Varilux Comfort Max
                    </h3>
                    <p>
                        В период с 7 февраля по 31 марта 2022 г. за проданные
                        мультифокусные линзы Varilux Comfort Max будут начисляться удвоенные баллы.
                    </p>
                </div>

                <SalesUploadForm role="doctor" />
            </Layout>
        );
    }
}



export default Page;
import React, { Component } from 'react';

import PieChartComponent from './piechart.component';

class DoublePieChartComponent extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="w-full md:flex md:justify-between md:w-3/4">
                <div className="md:w-6/12 md:mr-5">
                    <PieChartComponent
                        title={`${this.props.title} за ${this.props.year} год, шт.`}
                        searching={this.props.searching}
                        url={this.props.url}
                        year={this.props.year}
                        legalEntity={this.props.legalEntity}
                        type='count'
                        colors={['#FFBB28', '#00C49F', '#0088FE', '#ff28c2', '#242424', '#56416c', '#43523e']}
                    />
                </div>
                <div className="md:w-6/12 md:ml-5">
                    <PieChartComponent
                        title={`${this.props.title} за ${this.props.year} год, руб.`}
                        searching={this.props.searching}
                        url={this.props.url}
                        year={this.props.year}
                        legalEntity={this.props.legalEntity}
                        type='amount'
                        colors={['#0088FE', '#00C49F', '#FFBB28', '#ff28c2', '#242424', '#56416c', '#43523e']}
                    />
                </div>
            </div>
        );
    }
}


export default DoublePieChartComponent;
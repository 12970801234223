import React from 'react';
import PropTypes from 'prop-types';
import './iframe.scss';

class Iframe extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            width: props.width,
            height: props.height,
            scale: 1
        };
    }

    componentDidMount() {
        let scale = 1,
            width = this.props.width,
            height = this.props.height;

        const maxWidth = document.body.offsetWidth - this.props.offsetWidth,
            maxHeight = document.body.offsetHeight - this.props.offsetHeight;


        if (this.props.width > maxWidth) {
            scale = maxWidth / this.props.width;
            width = maxWidth;
            height = height * scale;
        }

        if (height > maxHeight) {
            scale = maxHeight / this.props.height;
            height = maxHeight;
        }

        this.setState({width, height, scale})
    }



    render() {
        return (
            <div className="iframe-container"
                 style={{
                     height: this.state.height,
                     width: this.state.width
                 }}>
                <iframe src={this.props.src} width="1980" height="800"
                        style={{'WebkitTransform': `scale(${this.state.scale})`, 'MozTransform': `scale(${this.state.scale})`}}
                        className={!this.props.allowClick ? 'noClick' : ''}
                />
            </div>
        )
    }
}

Iframe.propTypes = {
    src: PropTypes.string.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
    allowClick: PropTypes.bool,
    offsetWidth: PropTypes.number,
    offsetHeight: PropTypes.number,
}

Iframe.defaultProps = {
    src: '',
    width: 1024,
    height: 768,
    allowClick: true,
    offsetWidth: 0,
    offsetHeight: 0,
}

export default Iframe;


import React from 'react';
import {NavLink, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import classes from './login.module.scss';
import Icon from '../../icons/';
import Layout from '../../layouts/unauthorized';
import Button from "../../components/UI/Button/Button";

const Page = props => {

    return (
        <Layout>
            <div className={`${classes.loginPage}`}>
                <div className="container mx-auto py-6 px-3">
                    <NavLink to="/">
                        <Icon name="logo" fill="#fff" className="mx-auto max-w-8/9"/>
                    </NavLink>
                    <img className="mx-auto max-w-full md:w-2/5"
                         src="/assets/resources/energo-ball.png"
                         srcSet="/assets/resources/energo-ball.png, /assets/resources/energo-ball@2x.png 2x"/>

                    <div className="text-center text-white mx-auto max-w-4xl">
                        <p>
                            Уважаемый посетитель сайта Essilor Pro Business, для того, чтобы присоединиться к программе,
                            пожалуйста,
                            обратитесь к вашему региональному представителю или напишите нам запрос и мы с Вами
                            обязательно свяжемся!
                        </p>
                        <p className="pt-6">
                            <small>support@essilor-pro.ru</small>
                        </p>
                    </div>

                    <div
                        className="flex mx-auto md:justify-around md:max-w-5xl py-8 items-baseline md:flex-no-wrap flex-wrap">
                        <Button to="/signin" className={`text-center btn-blue ${classes.btnOrange}`}>
                            Войти в личный кабинет
                        </Button>
                        <Button to="/signup" className={`text-center btn-blue ${classes.btnOrange}`}>
                            Зарегистрироваться
                        </Button>
                    </div>

                    <p className="text-center underline">
                        <small><NavLink to="/password-reset">Забыл пароль</NavLink></small>
                    </p>

                    <div className="clearfix"></div>
                </div>
            </div>
        </Layout>
    )
};


export default Page;
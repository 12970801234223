import React from 'react';

const SVG = ({
                 fill="#fff",
                 width="26",
                 height="16",
                 viewBox="0 0 26 16",
                 className="",
                 style={}
             }) => (
    <svg fill={fill} width={width} style={style} height={height} viewBox={viewBox} className={`svg-icon ${className || ""}`} xmlns="http://www.w3.org/2000/svg">
        <rect width="26" height="2"/>
        <rect y="7" width="26" height="2"/>
        <rect y="14" width="26" height="2"/>
    </svg>
);

export default SVG;

import React from 'react';

const SVG = ({
                 fill="none",
                 width="53",
                 height="12",
                 viewBox="0 0 52 12",
                 className="",
                 style={}
             }) => (
    <svg fill={fill} width={width} style={style} height={height} viewBox={viewBox} className={`svg-icon ${className || ""}`} xmlns="http://www.w3.org/2000/svg">
        <path d="M0.245773 5.42665L5.61824 0.237454C5.94602 -0.0791515 6.47763 -0.0791515 6.80542 0.237454C7.13327 0.554125 7.13327 1.06747 6.80542 1.38414L2.86599 5.18918H50.9985C51.4621 5.18918 51.838 5.55223 51.838 5.99999C51.838 6.44769 51.4621 6.8108 50.9985 6.8108H2.86599L6.80529 10.6158C7.13314 10.9325 7.13314 11.4459 6.80529 11.7625C6.64143 11.9207 6.42653 12 6.2117 12C5.99686 12 5.78203 11.9207 5.6181 11.7625L0.245773 6.57333C-0.0820808 6.25666 -0.0820808 5.74332 0.245773 5.42665Z" fill="#797979"/>
    </svg>
);

export default SVG;

import React from 'react'
import  './Input.scss';
import InputMask from 'react-input-mask';


const Input = props => {
    let propsNew = {...props};
    propsNew.id = props.name;

    let hasError = false;

    if (props.hasOwnProperty('errors') && props.errors.hasOwnProperty(props.name) && props.errors[props.name]) {
        propsNew.className += ' invalid';
        hasError = true;
    }

    return (
        <div className={`Input ${propsNew.className}`}>
            <label htmlFor={propsNew.id}>
                {propsNew.label}
                {hasError ? (<i className="icon-alert-triangle error-icon"></i>) : ''}
            </label>
            <InputMask {...propsNew} />
        </div>
    )
};

export default Input

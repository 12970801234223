import React, {Fragment} from 'react';

import PagePromoList from './promo-list';
import PageDetails from './promo-details';

import NotFound from '../../404/404'
import Layout from "../../../layouts/authorized";

class Page extends React.Component {

    render() {
        const path = this.props.location.pathname.split('/');

        let page;
        const currentPage = path[3];

        switch (currentPage) {
            case undefined:
                page = <PagePromoList {...this.props}/>;
                break;
            case 'detail':
                page = <PageDetails {...this.props}/>;
                break;
            default:
                return (<NotFound/>)
        }

        return (<Layout>{page}</Layout>);
    }
}


export default Page;

import React from 'react';
import './education.scss';
import axios from "axios";
import {apiHost} from "../../../common/config";
import store from "../../../store/store";
import Button from "../../../components/UI/Button/Button";
import Tests from "../../../components/tests/tests";

class Page extends React.Component {

    constructor(props) {
        super(props);

        const path = this.props.location.pathname.split('/'),
            currentPageTest = path[4];

        this.state = {
            currentPageTest: currentPageTest,

            tests: [],

            questions: [],
            currentTestTitle: '',
            testId: 0,
            startTime: 0,

            testsResults: []
        }
    }

    getTest() {
        axios.get(
            `${apiHost}/education/get/test/${this.state.currentPageTest}`,
            {withCredentials: true}
        ).then(res => {
            this.setState(
                {
                    currentTestTitle: res.data.name,
                    questions: res.data.questions,
                    testId: res.data.testId,
                    startTime: res.data.startTime
                }
            );
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }

    getTests() {
        axios.get(
            `${apiHost}/education/get/tests`,
            {withCredentials: true}
        ).then(res => {
            this.setState({tests: res.data});
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }

    submitTest = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        formData.append('startTime', this.state.startTime);

        axios(`${apiHost}/tests/submit/test/${this.state.testId}`,
            {
                method: "post",
                data: formData,
                withCredentials: true
            }
        ).then(res => {
            this.setState({testsResults: res.data});
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const path = nextProps.location.pathname.split('/'),
            currentPageTest = path[4];

        this.setState(
            {currentPageTest},
            () => {
                this.componentDidMount();
            }
        )
    }

    componentDidMount() {
        if (this.state.currentPageTest) {
            this.getTest();
        } else {
            this.getTests();
        }
    }

    renderTests() {
        return (
            <>
                <div className="items-result relative w-full">
                    {
                        this.state.tests.map(test => (
                            <div className="items-result__item" key={`test-${test.id}`}
                                     to={`/lk/education/presentation/${test.code}`}>
                                <div className="h-48 w-full">
                                    <img alt={test.name} src={test.image}/>
                                </div>
                                <div className="items-result__item-title justify-between">
                                    <a href={`/lk/education/presentation/${test.code}`}>Обучение - {test.name}</a>
                                    <a className="border-white border-l pl-6"
                                        href={`/lk/education/tests/${test.code}`}>Пройти тест</a>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </>
        );
    }


    renderTest() {
        return (
            <form onSubmit={this.submitTest}>
                <h3 className="uppercase text-lg mb-6">{this.state.currentTestTitle} / Тест</h3>
                <Tests questions={this.state.questions}/>

                <Button type="submit" className="btn-thin btn-blue mb-8">Завершить</Button>
            </form>
        );
    }

    renderTestsResult() {
        return (
            <>
                <h3 className="uppercase text-lg mb-6">
                    {this.state.currentTestTitle} / Тест / Результаты</h3>

                <div className="flex flex-col">
                    {
                        this.state.testsResults.map((test, index) => (
                            <div key={`at-${index}`}>
                                <h4 className="border-b w-full">{index + 1} попытка</h4>

                                <div className="flex flex-row justify-between -mx-3 mt-4">
                                    <div className="w-2/4 mx-3">
                                        <b className="">Правильных ответов</b>
                                        <div
                                            className="blue-light-box text-4xl text-purple-900 py-6 px-16 w-full mt-2 text-center">
                                            {test.totalScore}/{test.totalQuestions}
                                        </div>
                                    </div>
                                    <div className="w-2/4">
                                        <b className="">Затраченное время</b>
                                        <div
                                            className="blue-light-box text-4xl text-purple-900 py-6 px-16 w-full mt-2 text-center">
                                            {test.time}
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-3 mb-6">{test.rateText}</div>
                            </div>
                        ))
                    }

                </div>
                <a href={`/lk/education/tests/${this.state.currentPageTest}`}
                   className="btn btn-thin btn-orange-yellow my-6">Пройти тест еще раз</a>
            </>
        );
    }

    render() {
        return (
            <>
                <h2 className="page-title">Наше обучение</h2>

                {
                    this.state.currentPageTest ?
                        this.state.testsResults.length ?
                            this.renderTestsResult() :
                            this.renderTest() :
                        this.renderTests()
                }
            </>
        );
    }
}


export default Page;


import React from 'react';
import Checkbox from "../UI/Checkbox/Checkbox";
import './table-intity.scss';

class TableEntityAbstract extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            checkboxes: []
        }

        this.perPage = 10;
    }


    changePage(page)
    {
        this.setState({page});
    }

    handleCheckedItem(index) {
        const {checkboxes} = this.state;

        checkboxes[index] = !checkboxes[index];
        this.setState({checkboxes});
    }

    checkCheckedAll() {
        return this.state.checkboxes.filter(item => item).length === this.state.checkboxes.length && this.state.checkboxes.length > 0;
    }

    setAll(status) {
        const checkboxes = [];

        this.props.data.map((item, index) => checkboxes[index] = status);
        this.setState({checkboxes})
    }

    componentDidMount() {
        if (this.props.checkedAll) {
            this.setAll(this.props.checkedAll);
        }
    }
}

export default TableEntityAbstract;

import React from 'react'
import {NavLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import './Button.scss'

const Button = ({
                    onClick,
                    className,
                    disabled,
                    type,
                    children,
                    ...props
                }) => {

    const cls = [
        'btn',
        className
    ];

    const onClickAction = event => {
        if (disabled) {
            event.preventDefault();
        } else {
            return onClick(event);
        }
    };

    const Tag = props.href || props.to ? NavLink : 'button';

    return (
        <Tag
            onClick={onClickAction}
            disabled={disabled}
            type={type}
            className={cls.join(' ')}
            {...props}
        >
            {children}
        </Tag>
    )
}

Button.propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    type: PropTypes.string,
    children: PropTypes.node,
};

Button.defaultProps = {
    onClick: () => {
    },
    className: '',
    disabled: false,
    type: 'button',
    children: 'OK',
};


export default Button
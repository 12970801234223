import React, {Fragment} from 'react';
import {NavLink} from 'react-router-dom';
import {xor as diff} from 'lodash';

import Layout from '../../../layouts/authorized';
import './education.scss';

import PageTests from './education-tests';
import PageTrainer from './education-trainer';
import PageAcademy from './education-academy';
import PagePresentation from './education-presentation';
import PageCourses from './education-courses';

import NotFound from '../../404/404'
import axios from "axios";
import {apiHost} from "../../../common/config";
import store from "../../../store/store";

class Page extends React.Component {

    state = {
        tests: [],
        selectedBrand: '',
        selectedSection: '',
        selectedSectionName: '',
        allowedSections: []
    }

    checkSectionsRestrictions() {
        axios.get(
            `${apiHost}/user/check/education/access`,
            {
                withCredentials: true,
            }
        ).then(res => {
            this.setState({allowedSections: diff(res.data, ['trainer', 'academy', 'courses'])})
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }

    componentDidMount() {
        this.checkSectionsRestrictions();
    }


    render() {
        const {allowedSections} = this.state;
        const path = this.props.location.pathname.split('/');

        let page,
            showButtons = true;
        const currentPage = path[3],
            codeCourse = typeof path[4] !== 'undefined' ? path[4] : null;

        if (currentPage !== undefined && allowedSections.length > 0 && !allowedSections.includes(currentPage)) {
            return (<NotFound/>)
        }

        switch (currentPage) {
            case undefined:
                page = <React.Fragment/>;
                break;
            case 'tests':
                page = <PageTests {...this.props}/>;
                break;
            case 'trainer':
                page = <PageTrainer {...this.props}/>;
                showButtons = false;
                break;
            case 'academy':
                page = <PageAcademy {...this.props}/>;
                showButtons = false;
                break;
            case 'courses':
                page = <PageCourses {...this.props}/>;
                showButtons = false;
                break;
            default:
                return (<NotFound/>)
        }


        return (
            <Layout>

                {
                    !showButtons ||
                    <>
                        <h2 className="page-title">Обучение</h2>

                            <ul className="submenu-education">
                                {
                                    allowedSections.includes('academy') &&
                                    <li>
                                        <NavLink to="/lk/education/academy">
                                            <span>Essilor Академия</span>
                                            <img className="" src="/assets/resources/academy-nav.png"/>
                                        </NavLink>
                                    </li>
                                }
                                {
                                    allowedSections.includes('courses') &&
                                    <li>
                                        <NavLink to="/lk/education/courses">
                                            <span>Интерактивное<br/>обучение</span>
                                            <img className="" src="/assets/resources/courses-nav.png"/>
                                        </NavLink>
                                    </li>
                                }
                                {
                                    allowedSections.includes('trainer') &&
                                    <li>
                                        <NavLink to="/lk/education/trainer">
                                            <span>обучение от<br/>бизнес тренера</span>
                                            <img className="" src="/assets/resources/trainer-nav.png"/>
                                        </NavLink>
                                    </li>
                                }
                            </ul>
                    </>
                }

                {allowedSections.length > 0 && page}

            </Layout>
        );
    }
}


export default Page;

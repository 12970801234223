import React from 'react';
import Modal from '../../components/modal/modal';
import Textarea from "../UI/Textarea/Textarea";
import Select from "../UI/Select/Select";
import Form from "../forms/forms";
import {apiHost} from "../../common/config";
import _ from 'lodash';

class FeedbackModal extends Form {

    requestUrl = `${apiHost}/feedback/send`;

    constructor(props) {
        super(props);

        this.state = {
            feedbackModalOpen: false,
            filledMessage: '',
            submitButtonFunc: false,
            subjects: [
                {value: 'support', label: 'Техническая поддержка: вопросы по работе сайта'},
                {value: 'academySupport', label: 'Essilor  Академия: вопросы по продуктам Essilor'}
            ],
            selectedSubject: {value: 'support', label: 'Техническая поддержка: вопросы по работе сайта'},
            showForm: true,
            showButtonClose: true
        };
    }

    showModal(event) {
        event.preventDefault();
        this.setState({feedbackModalOpen: true})
    }

    closeModal = () => {
        this.setState({
            feedbackModalOpen: false,
            showForm: true,
            showButtonClose: true,
            selectedSubject: {value: 'support', label: 'Техническая поддержка: вопросы по работе сайта'}
        })
    };

    checkSendButton(event) {
        switch (event.target.name) {
            case 'subject':
                this.setState({filledSubject: event.target.value}, this.fillSubmitButtonFunc);
                break;
            case 'message':
                this.setState({filledMessage: event.target.value}, this.fillSubmitButtonFunc);
                break;
            default:
                break;
        }
    }

    submitFunc = () => {
        const formData = new FormData();
        const subject = this.state.selectedSubject.value;

        formData.append('subjectCode', subject);
        formData.append('message', this.state.filledMessage);

        this.validateForm(formData);
    }

    submitRequestCalllback = () => {
        this.setState({
            showForm: false,
            submitButtonFunc: false,
            showButtonClose: false
        });
    }


    fillSubmitButtonFunc() {
        if (this.state.filledMessage !== '') {
            this.setState({submitButtonFunc: this.submitFunc})
        } else {
            this.setState({submitButtonFunc: false})
        }
    }

    handleChangeSubject(selectedOption) {
        this.setState({selectedSubject: selectedOption});
    }

    render() {
        const {showForm, selectedSubject} = this.state;

        return (
            <>
                <button className="Menu__feedback flex"
                        onClick={(event) => this.showModal(event)}>
                    <span>Задать вопрос</span>
                    <i className="text-2xl ml-2 icon-question-feedback opacity-50"/></button>

                <Modal isOpen={this.state.feedbackModalOpen} onClose={this.closeModal} title="Обратная связь"
                       onSubmit={this.state.submitButtonFunc} hideCloseButton={!this.state.showButtonClose}>
                    { showForm ?
                        <>
                            <Select
                                name="subject"
                                label="Тема обращения"
                                options={this.state.subjects}
                                value={this.state.selectedSubject}
                                className="w-full"
                                onChange={(selectedOption) => this.handleChangeSubject(selectedOption)}
                            />
                            <Textarea
                                placeholder="Сообщение"
                                rows="5"
                                name="message"
                                onInput={(e) => this.checkSendButton(e)}
                            />
                        </>
                        :  selectedSubject.value == 'support'
                            ?
                                <p>Ответ на ваше обращение придет на вашу электронную почту в течение 1 рабочего дня.</p>
                            :
                                <p>Ответ на ваше обращение придет на вашу электронную почту в течение 2 рабочих дней.</p>

                    }
                </Modal>
            </>
        );
    }
}

export default FeedbackModal;

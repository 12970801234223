import React from 'react';
import '../sales-stat.scss';


class StatTableCellASP extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    getClassWrapper(count, amount) {
        if (count == 0 || amount == 0) {
            return '';
        }

        const result = amount/count;

        if (result > 0) {
            return  'sales-stat-cell-wrapper-green';
        }

        if (result < 0) {
            return  'sales-stat-cell-wrapper-red';
        }

        return '';
    }

    calculateCount(count, amount) {

        if (count == 0 || amount == 0) {
            return '-';
        }

        return `${this.currencyFormatter((amount/count))}`;
    }

    currencyFormatter(count) {
        const formatter = new Intl.NumberFormat('ru-RU', {
            style: 'currency',
            currency: 'RUB',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

        return formatter.format(count);
    }

    render() {

        return (
            <td className={this.getClassWrapper(this.props.count, this.props.amount)}>
                {this.calculateCount(this.props.count, this.props.amount)}
            </td>
        );
    }
}

export default StatTableCellASP;
import React, {Router, Fragment} from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import PageMain from './pages/main/main';
import PageLogin from './pages/login/login';
import PageLogout from './pages/logout/logout';
import PageSignUp from './pages/signup/signup';
import PageSignIn from './pages/signin/signin';
import PagePasswordReset from './pages/signin/password-reset';
import PageGetPassword from './pages/signin/get-password';
import PageHome from './pages/lk/home/home';
import PageLibrary from './pages/lk/library/library';
import PageLibraryDetail from './pages/lk/library/library-detail';
import PageSettings from './pages/lk/settings/settings';
import PageNews from './pages/lk/news/news';
import PageNewsDetails from './pages/lk/news/news-details';
import PagePermissions from './pages/lk/permission/permission';
import PagePermissionsStatistic from './pages/lk/permission/statistic';
import PagePermissionsStatisticMarketing from './pages/lk/permission/permission-statistic-marketing';
import PagePodbirator from './pages/lk/podbirator/podbirator';
import PagePodbiratorSettings from './pages/lk/podbirator-settings/podbirator-settings';
import PageСurrentPromos from './pages/lk/current-promos/current-promos';
import PageStatSf from './pages/lk/stat/stat-sf';
import PageStatSfDetail from './pages/lk/stat/stat-sf-detail';
import PageStatMarketing from './pages/lk/stat/stat-marketing';
import PageStatMarketingDetail from './pages/lk/stat/stat-marketing-detail';
import PageCalendar from './pages/lk/calendar/calendar';
import PageEducation from './pages/lk/education/education';
import PageAllRates from './pages/lk/send-promo-message/send-promo-message';
import PageSendPromoMessage from './pages/lk/all-rates/all-rates';
import PageSalesMarketing from './pages/lk/sales/sales-marketing';
import PageSalesSf from './pages/lk/sales/sales-sf';
import PageSalesOwner from './pages/lk/sales/sales-owner';
import PageUploadSalesConsultant from './pages/lk/sales/upload-sales-consultant';
import PageUploadSalesDoctor from './pages/lk/sales/upload-sales-doctor';
import PageSalesStat from './pages/lk/sales/sales-stat';
import PagePromo from './pages/lk/promo/promo';
import PagePromoStat from './pages/lk/promo/promo-stat';
import PagePromoStatSf from './pages/lk/promo/promo-stat-sf';
import PageMyteam from './pages/lk/my-team/users';
import PageMyteamDetail from './pages/lk/my-team/user-detail';
import PageCrizalTeam from './pages/lk/crizal-team/crizal-team';
import NotFound from './pages/404/404';
import ScrollToTop from './components/scroll-to-top/scroll-to-top';


const App = () =>  {
  return (
      <Fragment>
          <ScrollToTop />
          <Switch>
              <Route path="/" exact={true} component={PageMain}/>
              <Route path="/login" exact={true} component={PageLogin}/>
              <Route path="/signup" exact={true} component={PageSignUp}/>
              <Route path="/signin" exact={true} component={PageSignIn}/>
              <Route path="/password-reset" exact={true} component={PagePasswordReset}/>
              <Route path="/getpassword" exact={true} component={PageGetPassword}/>
              <Route path="/logout" exact={true} component={PageLogout}/>
              <Route path="/lk/home" exact={true} component={PageHome}/>
              <Route path="/lk/library" exact={true} component={PageLibrary}/>
              <Route path="/lk/library/:slug" exact={true} component={PageLibraryDetail}/>
              <Route path="/lk/settings" exact={true} component={PageSettings}/>
              <Route path="/lk/news" exact={true} component={PageNews}/>
              <Route path="/lk/news/:slug" exact={true} component={PageNewsDetails}/>
              <Route path="/lk/permissions" exact={true} component={PagePermissions}/>
              <Route path="/lk/education" exact={false} component={PageEducation}/>
              <Route path="/lk/permissions/statistic" exact={true} component={PagePermissionsStatistic}/>
              <Route path="/lk/permission-statistic-marketing" exact={true} component={PagePermissionsStatisticMarketing}/>
              <Route path="/lk/podbirator" exact={true} component={PagePodbirator}/>
              <Route path="/lk/podbirator-settings" exact={true} component={PagePodbiratorSettings}/>
              <Route path="/lk/current-promos" exact={true} component={PageСurrentPromos}/>
              <Route path="/lk/calendar" exact={true} component={PageCalendar}/>
              <Route path="/lk/stat-sf" exact={true} component={PageStatSf}/>
              <Route path="/lk/stat-sf/:slug" exact={true} component={PageStatSfDetail}/>
              <Route path="/lk/stat-marketing" exact={true} component={PageStatMarketing}/>
              <Route path="/lk/stat-marketing/:slug" exact={true} component={PageStatMarketingDetail}/>
              <Route path="/lk/send-promo-message" exact={true} component={PageSendPromoMessage}/>
              <Route path="/lk/all-rates" exact={true} component={PageAllRates}/>
              <Route path="/lk/sales-marketing" exact={true} component={PageSalesMarketing}/>
              <Route path="/lk/sales-sf" exact={true} component={PageSalesSf}/>
              <Route path="/lk/sales-owner" exact={true} component={PageSalesOwner}/>
              <Route path="/lk/upload-sales-consultant" exact={true} component={PageUploadSalesConsultant}/>
              <Route path="/lk/upload-sales-doctor" exact={true} component={PageUploadSalesDoctor}/>
              <Route path="/lk/sales-stat" exact={true} component={PageSalesStat}/>
              <Route path="/lk/promo" exact={false} component={PagePromo}/>
              <Route path="/lk/promo-stat" exact={true} component={PagePromoStat}/>
              <Route path="/lk/promo-stat-sf" exact={true} component={PagePromoStatSf}/>
              <Route path="/lk/my-team" exact={true} component={PageMyteam}/>
              <Route path="/lk/my-team/:slug" exact={true} component={PageMyteamDetail}/>
              <Route path="/lk/crizal-team" exact={true} component={PageCrizalTeam}/>
              <Route component={NotFound} />
          </Switch>
      </Fragment>
  );
}

export default App;

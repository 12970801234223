import React from 'react';
import Layout from '../../../layouts/authorized';

class Page extends React.Component {

    render() {

        return (
            <Layout>
                <h2 className="page-title">Crizal-team</h2>
                <p className="text-white">
                    Приносим извинения, данная страница находится в разработке.<br/>
                    Будем рады видеть Вас на портале 24 апреля!
                </p>

            </Layout>
        );
    }
}



export default Page;
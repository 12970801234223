import React from "react";
import './file-list.scss';

const FileList = (props) => {
    return (
        <div className="files-list">
            {
                props.files.map((item, index) => (
                    <div className="files-list__item clearfix" key={`files-${item.id}`}>
                        <i className={`icon-file-${item.file.type}`}></i>
                        <span className="files-list__item-title">
                            {item.name}
                            {props.showType && <span> ({item.file.type})</span>}
                            {props.showSize && <span> ({item.file.size})</span>}
                        </span>
                        <a href={item.file.src} target="_blank"
                           className="files-list__item-link icon-download"></a>
                    </div>
                ))
            }
        </div>
    );
};

export default FileList;

import React from 'react';
import Layout from '../../../layouts/authorized';
import {apiHost} from '../../../common/config';
import axios from "axios";
import store from "../../../store/store";
import Select from "../../../components/UI/Select/Select";
import CustomTableOwner from '../../../components/table-owner/table.2.0';
import DatePickerRange from '../../../components/UI/DatePicker/DatePickerRange';

class Page extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            headers: [
                {code: 'date', caption: 'Дата', type: 'date'},
                {code: 'personalName', caption: 'ФИО', type: 'text'},
                {code: 'group', caption: 'Роль', type: 'text'},
                {code: 'source', caption: 'Источник', type: 'text'},
                {code: 'lens_group', caption: 'Группа', type: 'text'},
                {code: 'name', caption: 'Наименование', type: 'text'},
                {code: 'design', caption: 'Дизайн', type: 'text'},
                {code: 'covering', caption: 'Покрытие', type: 'text'},
                {code: 'count', caption: 'Кол-во линз', type: 'number'},
                {code: 'technology', caption: 'Технология', type: 'text'},
                {code: 'points', caption: 'Баллы', type: 'number'},
                {code: 'status', caption: 'Статус', type: 'text'},
            ],
            tableData: [],
            addresses: [
                {value: '', label: 'Все оптики'},
            ],
            subordinates: [
                {value: '', label: 'Все сотрудники'},
            ],
            groups: [
                {value: '', label: 'Все роли'},
                {value: 'DOCTOR_USERS', label: 'Врач'},
                {value: 'CONSULTANT_USERS', label: 'Консультант'}
            ],
            filter: {
                group: '',
                address: '',
                subordinate: '',
                dateStart: '',
                dateEnd: '',
                limit: 10,
                page: 1,
            },
            allRecords: 0,
            searching: true,
            showRetroModalOpen: false,
            showRetroModalText: '',
            retro: false,
        }

    }

    componentDidMount() {
        const {filter} = this.state;
        this.getTableData(filter);
        this.getAddresses();
        this.getSubordinates();
        this.getEntity();
    }

    getEntity() {
        axios.get(
            `${apiHost}/entity/getUserEntity`,
            {withCredentials: true}
        ).then(res => {
            if(res.data.RETRO == '1')
            {
                this.setState({
                    retro: true
                });
            }
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });

    }

    getTableData(filter) {
        axios.get(
            `${apiHost}/sale/get/sales`,
            {
                withCredentials: true,
                params: {
                    filter: JSON.stringify(filter)
                }
            }
        ).then(res => {
            this.setState({
                tableData: res.data.data,
                allRecords: res.data.allRecords
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        }).finally(() => {
            this.setState({
                searching: false
            });
        });
    }

    getAddresses() {
        axios.get(
            `${apiHost}/entity/get/addresses/forOwner`,
            {withCredentials: true,}
        ).then(res => {
            const { addresses } = this.state;
            this.setState({
                addresses: [...addresses, ...res.data]
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }

    getSubordinates() {
        axios.get(
            `${apiHost}/user/get/subordinates`,
            {withCredentials: true,}
        ).then(res => {
            const { subordinates } = this.state;
            this.setState({
                subordinates: [...subordinates, ...res.data]
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }

    handleChangeGroup(selectedOption) {
        const {filter} = this.state;
        filter.group = selectedOption.value;
        this.setState({filter});
    }

    handleChangeAddress(selectedOption) {
        const {filter} = this.state;
        filter.address = selectedOption.value;
        this.setState({filter});
    }

    handleChangeSubordinate(selectedOption) {
        const {filter} = this.state;
        filter.subordinate = selectedOption.value;
        this.setState({filter});
    }

    handleChangeDatePickerRange = (startDate, endDate) => {
        const {filter} = this.state;
        filter.dateStart = startDate;
        filter.dateEnd = endDate;
        this.setState({filter});
    }

    handleChangePage = (page) => {
        const {filter} = this.state
        filter.page = page;
        this.setState({
            searching: true
        });

        this.getTableData(filter);
    }

    handleSearch = () => {
        const {filter} = this.state;
        filter.page = 1;

        this.setState({
            searching: true
        });

        this.getTableData(filter);
    }

    closeRetroModal() {
        this.setState({showRetroModalOpen: false});
    }

    showRetroModal() {
        this.setState({showRetroModalOpen: !this.state.showRetroModalOpen});
    }

    render() {

        return (
            <Layout>
                <h2 className="page-title">Продажи</h2>

                <div className="blue-light-box rounded-lg max-w-2/3 mb-20">
                    <h3 className="custom-grey-color text-2xl">
                        <i className="icon-alert-circle text-essilor-green mr-5 text-5xl"></i>
                        Акция: Удвоенные баллы за линзы Varilux Comfort Max
                    </h3>
                    <p>
                        В период с 7 февраля по 31 марта 2022 г. за проданные
                        мультифокусные линзы Varilux Comfort Max будут начисляться удвоенные баллы.
                    </p>
                </div>

                <a hidden={!this.state.retro} onClick={() => this.showRetroModal()} style={{
                    color: '#19212f',
                    textDecoration: 'none',
                    fontWeight: 'bold',
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <img hidden={!this.state.retro} style={{ marginRight: '15px' }} src="/assets/resources/question.svg" alt=""/>
                    <span hidden={!this.state.retro} >Информация о разделе</span>
                </a><br></br>
                <div
                    style={{
                        backgroundColor: 'rgba(255,255,255,0.5)',
                        minHeight: '150px',
                        marginBottom: '25px',
                        marginTop: '25px',
                        width: '75%',
                        borderRadius: '25px',
                        padding: '15px'
                    }}
                    hidden={!this.state.showRetroModalOpen}>
                    В данной таблице отображаются все внесённые продажи Ваших сотрудников. Для более удобного поиска Вы можете воспользоваться фильтрами.<br></br>
                    В таблице Вы можете отслеживать и изменять статусы продаж, для этого в столбце <strong>“Действия”</strong> нажмите на галочку - принять продажу или на крестик - отклонить продажу.<br></br>
                    <br></br>
                    Каждая внесённая продажа может иметь один из трёх статусов:<br></br>
                    • В обработке - с продажей не было проведено никаких действий, баллы сотруднику не зачислены;<br></br>
                    • Принята - баллы за продажу зачислены в личный кабинет сотрудника;<br></br>
                    • Отклонена -  продажа внесена некорректно, баллы зачислены не будут.<br></br>
                    Вы можете выгрузить данную таблицу в excel нажав на кнопку <strong>“Выгрузить в excel”</strong> расположенную под таблицей в правом нижнем углу.<br></br>
                    <br></br>
                    В период с 1 по 9 число нового квартала Вам необходимо его закрыть. Для этого Вам необходимо принять или отклонить внесённые продажи за предыдущий квартал до 9 числа (включительно) нового квартала и нажать на кнопку <strong>"Закрыть квартал"</strong>.<br></br>
                    <span style={{"textDecoration": "underline"}}>После закрытия квартала сотрудники оптики не смогут внести продажи за предыдущий квартал.</span><br></br>
                    <br></br>
                    Если квартал не будет закрыт до конца 9 числа, то закрытие квартала произойдёт автоматически с теми статусами продаж, которые они имеют на момент автоматической блокировки.<br></br>
                    <br></br>
                    Автоматическое закрытие кварталов будет происходить: I - III квартал – 10 числа нового квартала; IV – 19 числа нового квартала<br></br>
                    <br></br>
                    В случае, если квартал был закрыт автоматически, продажи, имеющие статус «В обработке» учтены не будут.<br></br>


                </div>

                <div className="w-full md:flex md:justify-between md:w-3/4">
                    <div className="md:w-6/12 md:mr-5">
                        <Select
                            name="addresses"
                            label="Выберите адрес"
                            placeholder="Все оптики"
                            options={this.state.addresses}
                            className="w-full"
                            onChange={(selectedOption) => this.handleChangeAddress(selectedOption)}
                        />
                    </div>
                    <div className="md:w-6/12 md:ml-5">
                        <DatePickerRange onChange={this.handleChangeDatePickerRange} />
                    </div>
                </div>

                <div className="w-full md:flex md:justify-between md:w-3/4 mb-5">
                    <div className="md:w-6/12 md:mr-5">
                        <Select
                            name="users"
                            label="Выберите сотрудника"
                            placeholder="Все сотрудники"
                            options={this.state.subordinates}
                            className="w-full"
                            onChange={(selectedOption) => this.handleChangeSubordinate(selectedOption)}
                        />
                    </div>

                    <div className="md:w-6/12 md:ml-5">
                        <Select
                            name="groups"
                            label="Выберите роль"
                            placeholder="Все роли"
                            options={this.state.groups}
                            className="w-full"
                            onChange={(selectedOption) => this.handleChangeGroup(selectedOption)}
                        />
                    </div>
                </div>

                <div className="w-full md:flex md:justify-between md:w-3/4 mb-10 mt-3">
                    <div className="md:w-6/12 md:mr-5">
                        <div className="w-full md:flex md:justify-between">
                            <button disabled={this.state.searching ? "disabled" : ""}
                                    className="btn btn-narrow btn-blue w-full md:w-6/12 md:mr-5"
                                    onClick={this.handleSearch}>Найти</button>

                        </div>
                    </div>

                    <div className="md:w-6/12 md:ml-5"></div>
                </div>

                {  this.state.tableData.length > 0 &&
                <CustomTableOwner
                    headers={this.state.headers}
                    tableData={this.state.tableData}
                    showSpin={this.state.searching}
                    allRecords={this.state.allRecords}
                    perPage={this.state.filter.limit}
                    currentPage={this.state.filter.page}
                    onChangePage={this.handleChangePage}
                    exportLink={`${apiHost}/export/sales?filter=${JSON.stringify(this.state.filter)}`}
                />
                }

            </Layout>
        );
    }
}

export default Page;

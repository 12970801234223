import React from 'react';
import {NavLink, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import store from '../store/store';
import axios from "axios";
import {apiHost} from "../common/config";
import qs from 'qs';
import FormErrors from "./form-errors";
import Alert from "./alert";

class Layout extends React.Component {

    render() {
        if (this.props.isLogged) {
            if (window.innerWidth <= 768) {
                store.dispatch({
                    type: 'forceSetMenuIsClosed'
                });
            }

            return <Redirect to='/lk/home'/>;
        }

        return (
            <>
                <Alert/>
                <FormErrors/>

                {this.props.children}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isLogged: state.user.isLogged
    }
}

export default connect(mapStateToProps)(Layout);

import React from 'react';
import {NavLink} from 'react-router-dom';
import './education.scss';
import axios from "axios";
import {apiHost} from "../../../common/config";
import store from "../../../store/store";
import Button from "../../../components/UI/Button/Button";
import InputFile from "../../../components/UI/InputFile/InputFile";
import Form from "../../../components/forms/forms";
import './education-trainer-course-detail-homework.scss';
import Modal from '../../../components/modal/modal';
import _ from 'lodash';

class Page extends React.Component {

    constructor(props) {
        super(props);

        const path = this.props.location.pathname.split('/');

        this.courseCode = path[5];
        this.homeworkId = path[7];

        this.state = {
            course: {},
            homework: {},
            submitDisabled: true,
            errors: {},
            uploadedHomeworks: [],
            showModalText: '',
            showModalOpen: false,
        };

        this.fileRef = React.createRef();
    }

    componentDidMount() {

        const getCourse = function (courseCode) {
            return axios.get(
                `${apiHost}/education/trainer/get/course/${courseCode}`, {
                    withCredentials: true
                }
            )
        };

        const getTask = function (homeworkId) {
            return axios.get(
                `${apiHost}/education/trainer/get/homework/${homeworkId}`, {
                    withCredentials: true
                }
            )
        };

        axios.all([getCourse(this.courseCode), getTask(this.homeworkId)])
            .then(axios.spread((acct, perms) => {
                this.setState({course: acct.data.course, homework: perms.data.homework});
                this.getUploadedHomeworks();
            }));
    }

    chooseFile(event) {
        this.setState({submitDisabled: false});
    }

    submitHandler (event) {
        event.preventDefault();
        const formData = new FormData(event.target);
        formData.append('courseId', this.state.course.id);
        formData.append('homeworkId', this.homeworkId);

        axios(`${apiHost}/education/trainer/homework/add`,
            {
                method: "post",
                data: formData,
                withCredentials: true
            }
        ).then(res => {
            this.getUploadedHomeworks();
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }

    resetFileInputs() {
        const event = new Event('change', {bubbles: true});
        this.fileRef.current.dispatchEvent(event);
    }

    getUploadedHomeworks() {
        const {course, homework} = this.state;

        axios.get(`${apiHost}/education/trainer/homework/uploadedHomeworks`,
            {
                params: {
                    homeworkId: homework.id,
                    courseId: course.id,
                },
                withCredentials: true
            }
        ).then(res => {
            this.setState({uploadedHomeworks: res.data});
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }

    showComment(event) {
        event.preventDefault();
        const text = event.target.getAttribute('data-text');
        this.setState({showModalOpen: true, showModalText: text})
    }

    closeModal() {
        this.setState({showModalOpen: false, showModalText: ''});
    }

    render() {
        const {course, homework, uploadedHomeworks} = this.state;
        const passedHomework = _.find(uploadedHomeworks, (homework) => homework.status.code === 'success');
        const waitedHomework = _.find(uploadedHomeworks, (homework) => homework.status.code === 'wait');
        let showUploadForm = true;

        if (passedHomework || waitedHomework) {
            showUploadForm = false;
        }

        return (
            <>
                <h2 className="page-title page-title--breadcrumbs">{homework.name}</h2>
                <ul className="breadcrumbs">
                    <li><NavLink to="/lk/education">Обучение</NavLink></li>
                    <li>></li>
                    <li><NavLink to="/lk/education/trainer">Обучение от бизнес тренера</NavLink></li>
                    <li>></li>
                    <li><NavLink to={`/lk/education/trainer/course/${course.code}`}>{course.name}</NavLink></li>
                </ul>

                <div className="md:flex blue-light-box mx-2 mb-4 course-description w-full">
                    <div className="md:flex-shrink-0 md:w-1/4">
                        <img src={homework.image} className="rounded-lg mr-6 mb-6"/>
                    </div>
                    <div className="mt-4 md:mt-0 md:ml-6">

                        <h3 className="p-0 course-description__title">{homework.name}</h3>

                        <p className="mt-5 course-description__text"
                           dangerouslySetInnerHTML={{__html: homework.text}} />

                    </div>
                </div>

                {
                    uploadedHomeworks.length > 0
                        ?
                            <div className="uploadedhomeworks mx-2 mt-5 mb-5 w-full">
                                <p className="mb-2 uploadedhomeworks__title">Ваше решение домашнего задания</p>
                                {
                                    uploadedHomeworks.map((homework) => (
                                        <div className="mb-2 w-full uploadedhomeworks__item text-white flex"
                                             key={`uploadedhomework-${homework.id}`}>
                                            <div className="w-2/5 uploadedhomeworks__item-title">
                                                {homework.fileName}
                                            </div>
                                            <div className={`w-2/5 uploadedhomeworks__item-status uploadedhomeworks__item-status-${homework.status.code}`}>
                                                {homework.status.code === 'fail' && <i className="icon-alert-triangle error-icon mr-2"></i>}
                                                {homework.status.code === 'success' && <i className="icon-check-mark mr-2"></i>}
                                                {homework.status.text}
                                            </div>
                                            <div className="w-1/5 text-right">
                                                {
                                                    homework.text &&
                                                    <a onClick={(e) => this.showComment(e)}
                                                       data-text={homework.text}
                                                       className="cursor-pointer">Комментарий Тренера</a>
                                                }
                                                </div>
                                        </div>
                                    ))
                                }
                            </div>
                        : null
                }

                { showUploadForm &&
                    <div className="w-full text-white mx-2 mb-4">
                        <form onSubmit={(event) => this.submitHandler(event)}
                              method="post" encType="multipart/form-data">
                            <InputFile name="file"
                                       multiple
                                       className="w-full"
                                       showInfoAboutFileSize={true}
                                       onChange={(event) => this.chooseFile(event)}
                                       fileref={this.filesRef}/>


                            <Button type="submit" className="btn btn-thin btn-blue uppercase"
                                    disabled={this.state.submitDisabled}>
                                Отправить
                            </Button>
                        </form>
                    </div>
                }

                {
                    passedHomework &&
                    <div className="text-essilor-orange text-4xl uppercase mb-5 mx-2">
                        <i className="icon-trophy text-6xl mr-5"></i>
                        Задание выполнено
                    </div>
                }

                <Modal isOpen={this.state.showModalOpen} onClose={() => this.closeModal()} hideCloseButton={true}
                       boxStyle={{maxWidth: '38rem'}}>
                    <div className="text-center">
                        <h2 className="text-xl mt-2  mb-6 font-bold text-essilor-navy uppercase">Комментарий Тренера!</h2>
                        <p className="text-left">{this.state.showModalText}</p>

                        <Button className="btn btn-blue btn-narrow w-56 mt-10" style={{fontWeight: 'normal'}}
                                onClick={() => this.closeModal()}>Понятно</Button>
                    </div>
                </Modal>
            </>
        );
    }
}


export default Page;

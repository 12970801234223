const initState = {
    menuIsOpen: localStorage.getItem('menuIsOpen') === 'true',
}

export default (state = initState, action) => {
    switch (action.type) {
        case 'setMenuIsOpen':
            localStorage.setItem('menuIsOpen', !state.menuIsOpen);

            return {
                ...state, menuIsOpen: !state.menuIsOpen
            };
        case 'forceSetMenuIsClosed':
            localStorage.setItem('menuIsOpen', false);

            return {
                ...state, menuIsOpen: false
            };
        default:
            return {...state}
    }
}

import React from 'react';
import { NavLink } from 'react-router-dom';
import './table.scss';
import _ from 'lodash';
import Pagination from "../pagination/pagination";
import moment from 'moment';
import queryString from 'query-string';
import {apiHost} from "../../common/config";
import axios from "axios";
import store from "../../store/store";
import Modal from '../../components/modal/modal';
import EditSaleModalExt from '../../components/edit-sale-modal/edit-sale-modal-ext';
import Button from "../UI/Button/Button";


class ConsultantCustomTable extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showModalOpen: false,
            showPromptModalOpen: false,
            promptModalId: 0,
            showModalText: '',
            tableData: this.props.tableData,
            sortColumn: 'date',
            sortDirection: 'asc',
            currentPage: 1,
            currentSalesSum: [],
            retro: false,
        }

        this.perPage = 10;
    }

    sort(event, code){
        event.preventDefault();
        const {tableData, sortDirection} = this.state;
        const {headers} = this.props;
        const cloneTableData = _.cloneDeep(tableData);
        const newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
        const sortColumn = _.find(headers, {'code': code});
        let orderedTableData = {};

        if (sortColumn.type === 'date') {
            orderedTableData = _.orderBy(
                cloneTableData,
                function(row) {
                    const dateMomentObject = moment(row[code], "DD.MM.YYYY");
                    return dateMomentObject.toDate();
                },
                newSortDirection
            );

        } else if (sortColumn.type === 'number') {
            orderedTableData = _.orderBy(
                cloneTableData,
                function(row) {
                    return parseInt(row[code]);
                },
                newSortDirection
            );
        } else {
            orderedTableData = _.orderBy(cloneTableData, code, newSortDirection);
        }

        this.setState({
            sortColumn: code,
            sortDirection: newSortDirection,
            tableData: orderedTableData
        });
    }

    componentDidMount() {
        this.getCurrentSalesSum()
        this.getEntity()
    };

    getEntity() {
        axios.get(
            `${apiHost}/entity/getUserEntity`,
            {withCredentials: true}
        ).then(res => {
            if(res.data.RETRO == '1')
            {
                this.setState({
                    retro: true
                });
            }
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });

    }

    getCurrentSalesSum() {
        axios.get(
            `${apiHost}/sale/get_sales_by_quarters`,
            {withCredentials: true}
        ).then(res => {
            this.setState({
                currentSalesSum: res.data
            })
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });

    }

    changePage(page) {
        this.setState({currentPage: page});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.tableData, this.props.tableData)) {
            this.setState({tableData:  this.props.tableData})
        }
    }

    showPromptModal(event, id)
    {
        event.preventDefault();
        this.setState({showPromptModalOpen: true, promptModalId: id});
    }

    deleteSale(event, id) {
        event.preventDefault();
        axios.get(
            `${apiHost}/sale/delete/userSales/` + id,
            {withCredentials: true}
        ).then(res => {
            if (res.data.status === 'success') {
                this.setState({
                    showModalOpen: true,
                    showModalText: 'Данные успешно удалены.',
                    uploadedStatus: true
                });
            } else {
                this.setState({
                    showModalOpen: true,
                    showModalText: res.data.error
                });
            }
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });

    }

    async exportStat(url) {
        let fileName = '';

        const blob = await axios.get(
            url,
            {
                withCredentials: true,
            }
        ).then(res => {
            fileName = res.headers.filename;
            return new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), res.data], {type: res.headers['content-type']})
        })
            .catch(error => {
                store.dispatch({
                    type: 'addResponseStatus',
                    status: error.response.data.error
                })
            });

        const link = document.createElement('a');


        link.download = fileName || 'download';
        link.href = URL.createObjectURL(blob);
        link.click();

        URL.revokeObjectURL(link.href);
    }

    closeModal() {
        const { uploadedStatus } = this.state;

        if (uploadedStatus) {
            window.location.reload();
        }

        this.setState({showModalOpen: false, uploadedStatus: false});
    }

    closePromptModal() {
        this.setState({showPromptModalOpen: false, uploadedStatus: false});
    }

    render () {
        const {tableData, sortDirection, sortColumn, currentPage} = this.state;
        const {headers} = this.props;
        const startSlice = (currentPage - 1) * this.perPage;
        const endSlice = startSlice + this.perPage;

        return (
            <>
                <div hidden={!this.state.retro} className="clear-both w-full mb-6">
                    {this.state.currentSalesSum.map(function(quarter, i) {
                        return (<div>{quarter.name}: Сумма склад: {quarter.sum_storage} Сумма RX: {quarter.sum_rx}</div>)
                    })}
                </div>

                <Modal isOpen={this.state.showPromptModalOpen} onClose={() => this.closePromptModal()} hideCloseButton={true}
                       boxStyle={{maxWidth: '38rem'}}>
                    <div className="text-center">
                        <h2 className="text-xl mt-2  mb-6 font-bold text-essilor-navy uppercase">Удаление продаж!</h2>
                        <p className="text-center">Вы уверены что хотите удалить продажу?</p>

                        <button type={"button"} className="btn btn-orange btn-narrow mt-8"
                                onClick={(event) => this.deleteSale(event, this.state.promptModalId)}>Удалить</button>
                    </div>
                </Modal>
                <Modal isOpen={this.state.showModalOpen} onClose={() => this.closeModal()} hideCloseButton={true}
                       boxStyle={{maxWidth: '38rem'}}>
                    <div className="text-center">
                        <h2 className="text-xl mt-2  mb-6 font-bold text-essilor-navy uppercase">Удаление продаж!</h2>
                        <p className="text-center">{this.state.showModalText}</p>

                        <Button className="btn btn-blue btn-narrow w-56 mt-10" style={{fontWeight: 'normal'}}
                                onClick={() => this.closeModal()}>Закрыть</Button>
                    </div>
                </Modal>

                <div className="custom-table">
                    <table>
                        <thead>
                        <tr>
                            { headers.map(item => (
                                <th key={item.code}>
                                    <a onClick={(event) => this.sort(event, item.code)}>
                                        {item.caption}
                                        {
                                            item.code === sortColumn &&
                                            <i className={`sort-direction icon-${sortDirection === 'asc' ? 'arrow-t' : 'arrow-b'}`}></i>
                                        }
                                    </a>
                                </th>
                            ))}

                            <th>Действия</th>
                        </tr>
                        </thead>
                        <tbody>
                        { tableData.slice(startSlice, endSlice).map((row, index) =>(
                            <tr key={index}>
                                { headers.map((col, index) => {

                                    if (_.has(col, 'useCaption')) {
                                        const caption = `${col.code}Caption`;

                                        return (
                                            <td key={index}
                                                className={`${_.has(col, 'addStyleClass') ? col.addStyleClass : null}`}
                                                dangerouslySetInnerHTML={{__html: row[caption]}}></td>
                                        )
                                    } else {

                                        if (_.has(col, 'link')) {
                                            const link = `${col.code}Link`;

                                            return (
                                                <td key={index}>
                                                    <NavLink to={row[link]}>
                                                        {row[col.code]}
                                                    </NavLink>
                                                </td>
                                            )
                                        } else {
                                            return (
                                                <td key={index} className={`${_.has(col, 'addStyleClass') ? col.addStyleClass : null}`}>
                                                    {row[col.code]}
                                                </td>
                                            )
                                        }

                                    }

                                })}

                                <td key={index}>

                                    {(() => {
                                        if (row.statusValue === '-1') {
                                            return (
                                                [<button type={"button"} className="btn btn-orange btn-narrow mt-8 float-right"
                                                        onClick={(event) => this.showPromptModal(event, row.id)}>✕</button>,
                                                <EditSaleModalExt
                                                    id={row.id}
                                                    design={row.design}
                                                    designValue={row.designValue}
                                                    covering={row.covering}
                                                    coveringValue={row.coveringValue}
                                                    source={row.source}
                                                    sourceValue={row.sourceValue}
                                                    group={row.group}
                                                    groupValue={row.groupValue}
                                                    name={row.name}
                                                    nameValue={row.nameValue}
                                                    technology={row.technology}
                                                    count={row.count}
                                                    comment={row.comment}
                                                    date={row.date}
                                                    technologyValue={row.technologyValue}
                                                >
                                                </EditSaleModalExt>]
                                            )
                                        }
                                    })()}


                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>

                <div className="clear-both w-full mb-6">
                    <Pagination totalPages={tableData.length / this.perPage}
                                invertColor={true}
                                onNavigate={(page) => this.changePage(page)} className="mt-6 float-left"/>
                    {
                        this.props.exportLink &&
                            <button className="btn btn-blue btn-narrow mt-8 float-right"
                               onClick={() => this.exportStat(this.props.exportLink)}>Выгрузить в Excel</button>
                    }
                </div>
            </>
        );
    }

}

export default ConsultantCustomTable;

import React from 'react';
import IconLogo from './icon-logo';
import IconLogoShort from './icon-logo-short';
import IconCheck from './icon-check';
import IconMenu from './icon-menu';
import IconNextLong from './icon-next-long';
import IconPrevLong from './icon-prev-long';

const Icon = (props) => {
    switch (props.name) {
        case "logo":
            return <IconLogo {...props}/>;
        case "logo-short":
            return <IconLogoShort {...props}/>;
        case "check":
            return <IconCheck {...props}/>;
        case "menu":
            return <IconMenu {...props}/>;
        case "next-long":
            return <IconNextLong {...props}/>;
        case "prev-long":
            return <IconPrevLong {...props}/>;
        default:
            return '';
    }
}

export default Icon;

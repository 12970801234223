import store from '../store';

const initState = {
    status: {
        message: '',
        code: ''
    },
}

export default (state = initState, action) => {
    switch (action.type) {
        case 'addResponseStatus':

            if (action.status.code === 401) {
                setTimeout(() => {
                    store.dispatch({
                        type: 'logout'
                    })
                }, 100);
            }

            setTimeout(() => {
                store.dispatch({
                    type: 'clearResponseStatus'
                })
            }, 7000);

            return {
                ...state, status: action.status
            };

        case 'clearResponseStatus':
            return {
                ...initState
            };
        default:
            return {...state}
    }
}
import React from "react";
import ProfileProgressBarSvg from "./profile-progress-bar-svg";
import './profile-progress-bar.scss';
import { useState } from 'react';
import Modal from '../../components/modal/modal';
import Button from "../../components/UI/Button/Button";

const ProfileProgressBar = (props) => {

    const currentPosition = parseInt(props.currentPosition) || 0;
    const [stateModalOpen, setStateModalOpen] = useState(false);
    const consultantList = {
        'professional' : [
            {
                'text' : 'Набрать 2 030 баллов',
                'link' : ''
            },
            {
                'text' : '<a href="/lk/upload-sales-consultant">Внести продажи</a> не менее, чем на 1 500 баллов',
                'link' : '/lk/upload-sales-consultant'
            },
            {
                'text' : '<a href="/lk/education/academy">Пройти курс "Быстрый старт"</a> или тест по курсу "Быстрый старт" в разделе "Обучение. Essilor Академия"(300 баллов)',
                'link' : '/lk/education/academy'
            },
            {
                'text' : '<a href="/lk/education/trainer/course/uskorenie-prodazh-turbo-sales">Просмотреть видеозапись вебинара</a> от бизнес-тренера «Ускорение продаж. Turbo Sales» (150 баллов)',
                'link' : '/lk/education/trainer/course/uskorenie-prodazh-turbo-sales'
            },
            {
                'text' : '<a hrerf="/lk/education/courses">Пройти четыре курса</a> в разделе Интерактивное обучение (по 10 баллов за каждое обучение)',
                'link' : '/lk/education/courses'
            },
            {
                'text' : '<a hrerf="/lk/education/academys">Просмотреть</a> один федеральный вебинаров от Академии Essilor онлайн (10 баллов)',
                'link' : '/lk/education/academy'
            },
            {
                'text' : 'Воспользоваться разделом «Подбиратор» не менее одного раза (25 баллов)',
                'link' : ''
            },
            {
                'text' : 'Воспользоваться разделом «Библиотека знаний» не менее 5 раз (5 баллов)',
                'link' : ''
            }
        ],
        'expert' : [
            {
                'text' : 'Набрать 10 480 баллов',
                'link' : ''
            },
            {
                'text' : '<a hrref="/lk/upload-sales-consultant">Внести продажи</a> не менее, чем на 9000 баллов',
                'link' : '/lk/upload-sales-consultant'
            },
            {
                'text' : '<a href="/lk/education/academy">Пройти курс</a> "Essilor Профессионал" (450 баллов)',
                'link' : '/lk/education/academy'
            },
            {
                'text' : '<a href="/lk/library">Пройти обучение</a> по теме «Работа с Eye-ruler 2» (100 баллов)',
                'link' : '/lk/library'
            },
            {
                'text' : '<a href="/lk/education/academy">Посмотреть</a> 7 любых федеральных вебинаров онлайн (по 10 баллов за каждый просмотренный вебинар)',
                'link' : '/lk/education/academy'
            },
            {
                'text' : 'Воспользоваться разделом «Подбиратор» не менее 5 раз (25 баллов)',
                'link' : ''
            },
            {
                'text' : 'Воспользоваться разделом «Библиотека знаний» не менее 5 раз (5 баллов)',
                'link' : ''
            },
            {
                'text' : '<a href="/lk/library">Использовать</a> в работе файл «Коммуникации с покупателем»',
                'link' : '/lk/library'
            }
        ]
    }
    const doctorList = {
        'professional' : [
            {
                'text' : 'Набрать 2 030 баллов',
                'link' : ''
            },
            {
                'text' : '<a href="/lk/upload-sales-doctor">Внести продажи</a> не менее, чем на 1 500 баллов',
                'link' : '/lk/upload-sales-doctor'
            },
            {
                'text' : '<a href="/lk/education/academy">Пройти курс "Фокус на Varilux"</a> или тест по курсу " Фокус на Varilux " в разделе "Обучение. Essilor  Академия"(300 баллов)',
                'link' : '/lk/education/academy'
            },
            {
                'text' : '<a href="/lk/education/trainer/course/uskorenie-prodazh-turbo-sales">Просмотреть видеозапись вебинара</a> от бизнес-тренера «Ускорение продаж. Turbo Sales» (150 баллов)',
                'link' : '/lk/education/trainer/course/uskorenie-prodazh-turbo-sales'
            },
            {
                'text' : '<a href="/lk/education/courses">Пройти четыре курса</a> в разделе Интерактивное обучение (по 10 баллов за каждое обучение)',
                'link' : '/lk/education/courses'
            },
            {
                'text' : '<a href="/lk/education/academy">Просмотреть</a> один федеральный вебинар от Академии Essilor онлайн (10 баллов)',
                'link' : '/lk/education/academy'
            },
            {
                'text' : 'Воспользоваться разделом «Подбиратор» не менее одного раза (25 баллов)',
                'link' : ''
            },
            {
                'text' : 'Воспользоваться разделом «Библиотека знаний» не менее 5 раз (5 баллов)',
                'link' : ''
            }
        ],
        'expert' : [
            {
                'text' : 'Набрать 10 480 баллов',
                'link' : ''
            },
            {
                'text' : '<a href="/lk/upload-sales-doctor">Внести продажи</a> не менее, чем на 9000 баллов',
                'link' : '/lk/upload-sales-doctor'
            },
            {
                'text' : '<a href="/lk/education/academy">Пройти курс</a> "Essilor Профессионал" (450 баллов)',
                'link' : '/lk/education/academy'
            },
            {
                'text' : '<a href="/lk/library">Пройти обучение</a> по теме «Работа с Eye-ruler 2» (100 баллов)',
                'link' : '/lk/library'
            },
            {
                'text' : '<a href="/lk/education/academy">Посмотреть</a> 7 любых федеральных вебинаров онлайн (по 10 баллов за каждый просмотренный вебинар)',
                'link' : '/lk/education/academy'
            },
            {
                'text' : 'Воспользоваться разделом «Подбиратор» не менее 5 раз (25 баллов)',
                'link' : ''
            },
            {
                'text' : 'Воспользоваться разделом «Библиотека знаний» не менее 5 раз (5 баллов)',
                'link' : ''
            },
            {
                'text' : '<a href="/lk/library">Использовать</a> в работе файл «Коммуникации с покупателем»',
                'link' : '/lk/library'
            }
        ]
    }
    const professionalList = props.roleCode !== 'DOCTOR_USERS'
        ? consultantList.professional
        : doctorList.professional;
    const expertList = props.roleCode !== 'DOCTOR_USERS'
        ? consultantList.expert
        : doctorList.expert;

    if (currentPosition === 0) {
        return '';
    }

    return (
        <>
            <div className="font-semibold text-xl mb-3">
                <span className="title-semibold title-semibold__black">Прогресс</span>
                <span onClick={() => setStateModalOpen(true)} className="icon-info ml-2 cursor-pointer text-essilor-light-blue"></span>
                <div className="text-xs text-essilor-gray font-normal underline">Информация об уровнях</div>
            </div>

            <div className="profile-progress-bar-wrapper">

                <ProfileProgressBarSvg width={props.width} currentPosition={currentPosition}/>

                <div className={`profile-progress-bar ${props.width < 730 ? `sm` : ``}`}>
                    <div className={currentPosition === 1 ? `active` : ``}>
                        <b>1</b>
                        <span>Уровень<i className="dash"> - </i><strong>Специалист</strong><i className="icon-check-mark"/>
                                                    <i className="mark-point">Вы здесь</i>
                                                </span>

                    </div>
                    <div className={currentPosition === 1 ? `inactive` : (currentPosition === 2 ? `active` : ``)}>
                        <b>2</b>
                        <span>Уровень<i className="dash"> - </i><strong>Профессионал</strong><i className="icon-check-mark"/>
                                                    <i className="mark-point">Вы здесь</i>
                                                </span>
                    </div>
                    <div className={currentPosition === 3 ? `active` : `inactive`}>
                        <b>3</b>
                        <span>Уровень<i className="dash"> - </i><strong>Эксперт</strong><i className="icon-check-mark"/>
                                                    <i className="mark-point">Вы здесь</i>
                                                </span>
                    </div>
                </div>

            </div>

            <Modal isOpen={stateModalOpen} onClose={() => setStateModalOpen(false)} hideCloseButton={true}
                   boxStyle={{maxWidth: '50rem'}}>
                <div className="text-center">
                    <h2 className="title-semibold title-semibold__black">Разделы и баллы</h2>

                    <p className="text-left mt-4">
                        <b>Для перехода на уровень «Профессионал», вам необходимо:</b>
                        <ul>
                            {
                                professionalList.map((item) => {
                                    return item.link.length > 0
                                        ? <li className="mb-1" dangerouslySetInnerHTML={{ __html: " → " + item.text }} />
                                        : <li className="mb-1"> &mdash; {item.text}</li>
                                })
                            }
                        </ul>
                    </p>

                    <p className="text-left mt-4">
                        <b>Для перехода на уровень «Эксперт», вам необходимо:</b>
                        <ul>
                            {
                                expertList.map((item) => {
                                    return item.link.length > 0
                                        ? <li className="mb-1" dangerouslySetInnerHTML={{ __html: " → " + item.text }} />
                                        : <li className="mb-1"> &mdash; {item.text}</li>
                                })
                            }
                        </ul>
                    </p>

                    <Button className="btn btn-blue btn-narrow w-56 mt-10" style={{fontWeight: 'normal'}}
                            onClick={() => setStateModalOpen(false)}>Понятно</Button>
                </div>
            </Modal>
        </>



    )
};

export default ProfileProgressBar;

import React, { Component } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend} from 'recharts';
import axios from "axios";
import {apiHost} from "../../../../common/config";
import store from "../../../../store/store";

const currentYear = new Date().getFullYear();

class TotalLineChartComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            countData: [],
            amountData: []
        }
    }


    componentDidMount() {
        this.getData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.year !== prevProps.year || this.props.legalEntity !== prevProps.legalEntity) {
            this.getData();
        }
    }


    getData() {
        axios.get(
            `${apiHost}/external1c/stat/sale/chart/total`,
            {
                withCredentials: true,
                params: {
                    currentYear: currentYear,
                    year: this.props.year,
                    legalEntity: this.props.legalEntity
                }
            }
        ).then(res => {
            this.setState({
                countData: res.data.count,
                amountData: res.data.amount,
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });

    }


    toolTipFormatter = (value, name) => {
        const ending = this.props.type == 'count' ? ' шт.' : '';
        const formattedValue = this.props.type == 'count' ? value : this.currencyFormatter(value);

        return [`${formattedValue}${ending}`, name];
    };


    currencyFormatter(count) {
        const formatter = new Intl.NumberFormat('ru-RU', {
            style: 'currency',
            currency: 'RUB',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

        return formatter.format(count);
    }


    render() {
        const {countData, amountData} = this.state;

        return (
            <div className='mt-10'>
                <h3 className='custom-grey-color text-2xl'>
                    Динамика продаж {this.props.year} - {currentYear} в шт.
                </h3>
                <LineChart
                    width={900}
                    height={300}
                    data={countData}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis type="number" domain={[0, 'dataMax + 1000']} />
                    <Tooltip separator=' г. - ' />
                    <Legend />
                    <Line type="monotone" dataKey={this.props.year} stroke="#8884d8" />
                    <Line type="monotone" dataKey={currentYear} stroke="#82ca9d" />
                </LineChart>


                <h3 className='custom-grey-color text-2xl'>
                    Динамика продаж в рублях {this.props.year} - {currentYear} в руб.
                </h3>
                <LineChart
                    width={900}
                    height={300}
                    data={amountData}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis type="number" domain={[0, 'dataMax + 1000']} />
                    <Tooltip formatter={this.toolTipFormatter} separator=' г. - ' />
                    <Legend />
                    <Line type="monotone" dataKey={this.props.year} stroke="#8884d8" />
                    <Line type="monotone" dataKey={currentYear} stroke="#82ca9d" />
                </LineChart>
            </div>
        );
    }
}


export default TotalLineChartComponent;
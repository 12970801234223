import React from 'react';
import {NavLink} from 'react-router-dom';
import {apiHost} from '../../../common/config';
import store from '../../../store/store';
import Layout from '../../../layouts/authorized';
import Select from '../../../components/UI/Select/Select';
import CustomTable from '../../../components/table/table.2.0';
import './permission.scss';
import axios from 'axios';

class Page extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            responseError: '',
            headers: [
                {code: 'legalEntity', caption: 'Партнёр', type: 'text'},
                {code: 'invite', caption: 'Приглашение', type: 'text'},
                {code: 'owners', caption: 'Владельцы', type: 'number'},
                {code: 'helpers', caption: 'Помощники', type: 'number'},
                {code: 'consultants', caption: 'Консультанты', type: 'number'},
                {code: 'doctors', caption: 'Врачи', type: 'number'},
            ],
            tableData: [],
            entities: [
                {value: '', label: 'Все партнёры'},
            ],
            filter: {
                legalEntity: '',
                limit: 10,
                page: 1,
            },
            searching: true,
            allRecords: 0,
        }
    }

    componentDidMount = () => {
        const {filter} = this.state;
        this.getTableData(filter);
        this.getEntities();
    };

    getEntities() {
        axios.get(
            `${apiHost}/entity/getlist`,
            {withCredentials: true,}
        ).then(res => {
            const { entities } = this.state;
            this.setState({
                entities: [...entities, ...res.data]
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });

    }

    handleChangeEntity(selectedOption) {
        const {filter} = this.state;
        filter.legalEntity = selectedOption.value;
        this.setState({filter});
    }

    getTableData(filter) {
        axios.get(
            `${apiHost}/activity/get/registered/user/stat`,
            {
                withCredentials: true,
                params: {
                    filter: JSON.stringify(filter)
                }
            }
        ).then(res => {
            this.setState({
                tableData: res.data.data,
                allRecords: res.data.allRecords
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        }).finally(() => {
            this.setState({
                searching: false
            });
        });
    }

    handleChangePage = (page) => {
        const {filter} = this.state
        filter.page = page;
        this.setState({
            searching: true
        });

        this.getTableData(filter);
    }

    handleSearch = () => {
        const {filter} = this.state;
        filter.page = 1;

        this.setState({
            searching: true
        });

        this.getTableData(filter);
    }

    render() {
        return (
            <Layout>

                <ul className="submenu">
                    <li>
                        <NavLink to="/lk/permissions" exact>Выдать доступ</NavLink>
                    </li>
                    <li className="active">
                        <NavLink to="/lk/permissions/statistic" exact>Статистика</NavLink>
                    </li>
                </ul>

                <h2 className="page-title">Статистика</h2>

                <div className="w-full md:flex md:justify-between mb-10">
                    <div className="md:w-6/12 md:mr-5">
                        <Select
                            name="entity"
                            label="Выберите партнёра"
                            placeholder="Все партнёры"
                            options={this.state.entities}
                            className="w-full"
                            onChange={(selectedOption) => this.handleChangeEntity(selectedOption)}
                        />
                    </div>
                    <div className="md:w-6/12 md:ml-5 flex justify-start items-center">
                        <button disabled={this.state.searching ? "disabled" : ""}
                                className="btn btn-narrow btn-blue w-full md:w-6/12 md:mr-5"
                                onClick={this.handleSearch}>Показать</button>
                    </div>
                </div>

                {  this.state.tableData.length > 0 &&
                <CustomTable
                    headers={this.state.headers}
                    tableData={this.state.tableData}
                    showSpin={this.state.searching}
                    allRecords={this.state.allRecords}
                    perPage={this.state.filter.limit}
                    currentPage={this.state.filter.page}
                    onChangePage={this.handleChangePage}
                    exportLink={`${apiHost}/export/registered/users?filter=${JSON.stringify(this.state.filter)}`}
                />
                }
            </Layout>
        );
    }
}



export default Page;
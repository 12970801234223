import React from 'react';
import Icon from '../../../icons';
import './Checkbox.scss';

class Checkbox extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isChecked: props.checked || false
        }
    }

    toggleChecked = (event) => {
        this.setState({isChecked: !this.state.isChecked});

        if (typeof this.props.onChange === 'function') {
            this.props.onChange(event);
        }
    }

    render() {
        let hasError = false;

        if (this.props.hasOwnProperty('errors')
            && this.props.errors.hasOwnProperty(this.props.name) && this.props.errors[this.props.name]) {
            hasError = true;
        }

        const isChecked = this.state.isChecked ? 'is-checked' : '';

        return (
            <label className={`custom-label flex items-start mb-6 ${this.props.className} ${isChecked}`}>
                <div className="checkbox w-6 h-6 p-1 flex justify-center items-center mr-2">
                    <input type="checkbox" {...this.props} className="hidden" onChange={this.toggleChecked}/>
                    <Icon name="check" className="hidden w-4 h-4 text-black pointer-events-none"/>
                </div>
                <span className="select-none flex justify-between ">
                {this.props.label}
                    {hasError ? (<i className="icon-alert-triangle error-icon"></i>) : ''}
                </span>
            </label>
        )
    }
};

export default Checkbox;

import React from 'react';
import {apiHost} from '../../../common/config';
import './news.scss';
import Button from "../../../components/UI/Button/Button";
import {withRouter} from "react-router-dom";
import queryString from 'query-string';
import Input from "../../../components/UI/Input/Input";
import InputFile from "../../../components/UI/InputFile/InputFile";
import Textarea from "../../../components/UI/Textarea/Textarea";
import Form from "../../../components/forms/forms";

class NewsForm extends Form {

    requestUrl = `${apiHost}/news/add`;

    validationRules = {
        title: {require: true, title: 'Заголовок новости'},
        previewImage: {require: true, title: 'Изображение'},
        text: {require: true, title: 'Текст новости'},
    }

    constructor(props) {
        super(props);

        let params = queryString.parse(this.props.location.search);

        this.state = {
            page: parseInt(params.page) || 1,
            news: [],
            lastCount: 0,
            isManager: false,
            newsCount: 0,
            submitDisabled: false,

            errors: {},
        };

        this.previewImageRef = React.createRef();
        this.filesRef = React.createRef();
    }

    async submitHandler(event) {
        event.preventDefault();

        let form = event.target;

        await super.submitHandler(event);

        if (!Object.keys(this.state.errors).length) {
            this.setState({submitDisabled: true});
            form.reset();
            this.resetFileInputs();
        }
    }

    resetFileInputs() {
        const event = new Event('change', {bubbles: true});
        this.previewImageRef.current.dispatchEvent(event);
        this.filesRef.current.dispatchEvent(event);
    }


    submitRequestCalllback = (res) => {
        this.setState({submitDisabled: false});

        if (typeof this.props.onSubmit === 'function') {
            return this.props.onSubmit();
        }
    }

    render() {
        return (
            <>
                <h2 className="page-title mt-4">Добавить новость</h2>
                <div className="w-full">

                    <form onSubmit={(event) => this.submitHandler(event)}
                          method="post" encType="multipart/form-data">
                        <Input label="Заголовок новости" name="title"
                               className="w-full max-w-3xl"
                               errors={this.state.errors}
                               onChange={this.removeError}/>
                        <InputFile label="Изображение"
                                   name="previewImage"
                                   className="w-full max-w-xl"
                                   errors={this.state.errors}
                                   onChange={this.removeError}
                                   accept="image/jpeg,image/png,image/gif"
                                   fileref={this.previewImageRef}/>
                        <Textarea name="text"
                                  label="Текст новости"
                                  onChange={this.removeError}
                                  className="w-full max-w-3xl"
                                  errors={this.state.errors}
                                  rows={10}/>
                        <InputFile label="Медиа файлы"
                                   name="files[]"
                                   multiple
                                   className="w-full max-w-xl"
                                   onChange={this.removeError}
                                   fileref={this.filesRef}/>

                        <Button type="submit" className="btn btn-blue uppercase my-8"
                                disabled={this.state.submitDisabled}>
                            Создать
                        </Button>
                    </form>
                </div>
            </>
        )
    }

}


export default withRouter(NewsForm);

import React from 'react';
import {NavLink} from 'react-router-dom';
import './education.scss';
import axios from "axios";
import {apiHost} from "../../../common/config";
import store from "../../../store/store";
import Textarea from "../../../components/UI/Textarea/Textarea";
import Button from "../../../components/UI/Button/Button";
import "./education-trainer.scss";
import Modal from '../../../components/modal/modal';

class Page extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            courses: [],
            showModalOpen: false,
            questionText: '',
            webinars: []
        }
    }

    componentDidMount() {
        axios.get(
            `${apiHost}/education/trainer/get/courses`, {
                withCredentials: true
            }
        ).then(res => {
            this.setState({courses: res.data});
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });

        this.getWebinars();
    }

    getWebinars() {
        axios.get(
            `${apiHost}/education/trainer/get/webinars`, {
                withCredentials: true
            }
        ).then(res => {
            this.setState({webinars: res.data});
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }

    sendMessage() {
        const { questionText } = this.state;
        const formData = new FormData();
        formData.append('message', questionText);

        if (questionText.length > 0) {
            axios(`${apiHost}/education/trainer/add/question`,
                {
                    method: "post",
                    data: formData,
                    withCredentials: true
                }
            ).then(res => {
                this.setState({showModalOpen: true})
            }).catch(error => {
                store.dispatch({
                    type: 'addResponseStatus',
                    status: error.response.data.error
                })
            });
        }

    }

    closeModal() {
        this.setState({showModalOpen: false, questionText: ''});
    }

    handleChangeQuestionText(event) {
        this.setState({questionText: event.target.value});
    }

    render() {
        const {webinars} = this.state;

        return (
            <>
                <h2 className="page-title page-title--breadcrumbs">Обучение от бизнес тренера</h2>
                <ul className="breadcrumbs">
                    <li><NavLink to="/lk/education">Обучение</NavLink></li>
                </ul>

                <div className="flex w-full flex-col lg:flex-row -mx-2">
                    <div className="blue-light-box mx-2 mb-4">
                        <img src="/assets/resources/trainer_v1.jpg" className="mr-6 mb-6 float-left max-w-xs"/>
                        <p>Дамы и Господа!</p>

                        <p className="mt-2">Приглашаю Ваc стать участником наших
                            обучений на платформе Essilor PRO Business!</p>

                        <p className="mt-2">Все программы наших обучений содержат
                            в себе опыт ведения бизнеса в России и адаптируются под реалии текущей ситуации.</p>

                        <p className="mt-2">Вне зависимости от уровня вашей подготовки, предлагаемый
                            материал будет полезен как для вашего профессионального, так и личностного роста!</p>

                        <p className="mt-2">Добро пожаловать на территорию возможностей,
                            которые доступны для Партнеров Essilor PRO Business!</p>

                        <p className="mt-2">С уважением и готовностью быть полезным,<br/>
                            Евгений Горянский</p>

                    </div>
                    <div className="mx-2 education-right-column flex flex-col">
                        <div className="blue-box mb-4">
                            <div className="blue-box__title">Ближайших вебинары</div>
                            {
                                webinars.length > 0
                                    ?
                                        webinars.map((webinar) => (
                                            <div className="mt-5" key={webinar.id}>
                                                <div className="text-white text-xs">{webinar.date}</div>
                                                <div className="text-white">{webinar.title}</div>
                                            </div>
                                        ))
                                    :
                                        <div className="mt-5 text-white">Нет данных</div>
                            }
                        </div>
                        <div className="white-box box-shadow h-auto">
                            <div className="mb-2">Задать вопрос тренеру</div>
                            <div className="Textarea">
                                <textarea placeholder="Ваш вопрос"
                                          rows="5"
                                          value={this.state.questionText}
                                          onChange={(event) => this.handleChangeQuestionText(event)}/>
                            </div>
                            <div className="text-center">
                                <Button className="btn btn-blue btn-thin"
                                        onClick={() => this.sendMessage()}
                                        disabled={this.state.questionText.length > 0 ? false : true}>Отправить</Button>
                            </div>
                        </div>
                    </div>
                </div>

                <h3 className="page-title page-title--second">Курсы</h3>

                <div className="items-result relative w-full">
                    {
                        this.state.courses.map(course =>
                            (
                                <div className="items-result__item item-white box-shadow" key={`item-${course.id}`}>
                                    <img src={course.image}/>
                                    <div className="flex justify-between">
                                        <div className='trainer__type-label trainer__type-label-course'>
                                            Курс
                                        </div>
                                    </div>
                                    <div className="content">
                                        <h2 className="title">{course.name}</h2>
                                        <div className="description">
                                            {course.text}
                                        </div>
                                    </div>
                                    <div
                                        className="flex pt-4 text-center w-full justify-center bottom-content">
                                        <a href={`/lk/education/trainer/course/${course.code}`} className="px-3">
                                            Перейти к обучению</a>
                                    </div>
                                </div>
                            )
                        )
                    }

                </div>

                <Modal isOpen={this.state.showModalOpen} onClose={() => this.closeModal()} hideCloseButton={true}
                       boxStyle={{maxWidth: '38rem'}}>
                    <div className="text-center">
                        <h2 className="text-xl mt-2  mb-6 font-bold text-essilor-navy uppercase">Задать вопрос тренеру</h2>
                        <p>Ответ на ваше обращение придет на вашу электронную почту в течение 2 рабочих дней.</p>
                    </div>
                </Modal>
            </>
        );
    }
}


export default Page;


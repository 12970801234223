import React from 'react';
import Modal from '../../components/modal/modal';
import Textarea from "../UI/Textarea/Textarea";
import Input from "../UI/Input/Input";
import Select from "../UI/Select/Select";
import {apiHost} from "../../common/config";
import FeedbackModal from "./feedback-modal";

class FeedbackModalExt extends FeedbackModal {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            filledEmail: ''
        };
    }

    checkSendButton(event) {
        super.checkSendButton(event);
        switch (event.target.name) {
            case 'email':
                this.setState({filledEmail: event.target.value}, this.fillSubmitButtonFunc);
                break;
            default:
                break;
        }
    }

    submitFunc = () => {
        const formData = new FormData();
        const subject = this.state.selectedSubject.value;

        formData.append('subjectCode', subject);
        formData.append('message', this.state.filledMessage);
        formData.append('email', this.state.filledEmail);

        this.validateForm(formData);
    }

    fillSubmitButtonFunc() {
        if (this.state.filledMessage !== '' && this.state.filledEmail !== '') {
            this.setState({submitButtonFunc: this.submitFunc})
        } else {
            this.setState({submitButtonFunc: false})
        }
    }

    handleChangeSubject(selectedOption) {
        this.setState({selectedSubject: selectedOption});
    }

    render() {
        const {showForm, selectedSubject} = this.state;

        return (
            <>
                <div className="flex justify-center mb-6">
                    <button className="text-center text-sm underline opacity-50 hover:opacity-100"
                            onClick={(event) => this.showModal(event)}>
                        <span>Обратная связь</span>
                    </button>
                </div>

                <Modal isOpen={this.state.feedbackModalOpen} onClose={this.closeModal} title="Обратная связь"
                       onSubmit={this.state.submitButtonFunc} hideCloseButton={!this.state.showButtonClose}>
                    { showForm ?
                        <>
                        <Input placeholder="Ваш Email" name="email" onInput={(e) => this.checkSendButton(e)}/>
                        <Select
                            name="subject"
                            label="Тема обращения"
                            options={this.state.subjects}
                            value={this.state.selectedSubject}
                            className="w-full"
                            onChange={(selectedOption) => this.handleChangeSubject(selectedOption)}
                        />
                        <Textarea
                            placeholder="Сообщение"
                            rows="5"
                            name="message"
                            onInput={(e) => this.checkSendButton(e)}
                        />
                        </>
                        :  selectedSubject.value == 'support'
                            ?
                            <p>Ответ на ваше обращение придет на вашу электронную почту в течение 1 рабочего дня.</p>
                            :
                            <p>Ответ на ваше обращение придет на вашу электронную почту в течение 2 рабочих дней.</p>

                    }
                </Modal>
            </>
        );
    }
}

export default FeedbackModalExt;

import React from "react";
import './profile-progress-circle.scss';
import ProfileProgressCircleSvg from "./profile-progress-circle-svg";

const ProfileProgressCircle = (props) => {

    return (
        <div className="profile-progress-circle-wrapper">
            <div className="profile-progress-circle-tip">
                Цель - набрать {props.valueMax} баллов
            </div>
            <ProfileProgressCircleSvg {...props}/>
            <div className="profile-progress-circle-text">
                <b>
                    {props.value}
                    <span>баллов</span>
                </b>
            </div>
    </div>


    )
};

export default ProfileProgressCircle;

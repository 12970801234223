import React from 'react';
import {NavLink} from 'react-router-dom';
import './education.scss';
import axios from "axios";
import {apiHost} from "../../../common/config";
import store from "../../../store/store";
import Button from "../../../components/UI/Button/Button";
import Iframe from "../../../components/iframe/iframe";
import ReactPlayer from 'react-player'
import _ from 'lodash';
import Tests from "../../../components/tests/tests";
import Modal from '../../../components/modal/modal';

class Page extends React.Component {

    constructor(props) {
        super(props);

        const path = this.props.location.pathname.split('/'),
            codeCourse = path[4];

        this.state = {
            codeCourse: typeof codeCourse !== 'undefined' ? codeCourse : null,
            course: {},
            currentStepCode: 'homePage',
            responseModalOpen: false,
            failModalOpen: false,
            wrongQuestionBlocks: [],
            testResult: []
        }

    }

    componentDidMount() {
        axios.get(
            `${apiHost}/education/get/test/${this.state.codeCourse}`,
            {withCredentials: true}
        ).then(res => {
            this.setState({course: res.data});
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }

    openStep(courseId, stepCode) {
        axios.get(
            `${apiHost}/education/course/${courseId}/steps/add/${stepCode}`,
            {withCredentials: true}
        ).then(res => {
            this.setState({currentStepCode: stepCode});
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }

    goToStep(event) {
        event.preventDefault();
        const {course} = this.state;
        const stepCode = event.target.dataset.step;
        const step = _.find(course.steps, {'code': stepCode});
        if (step.allow) {
            this.setState({currentStepCode: stepCode});
        }
    }

    goPrevStep() {
        const {currentStepCode, course} = this.state;
        const prevStep = _.find(course.steps, {'nextStep': currentStepCode});
        const newState = _.isUndefined(prevStep) ? 'homePage' : prevStep.code;

        this.setState({currentStepCode: newState});
    }

    goNextStep() {
        const {currentStepCode, course} = this.state;
        const currentStep = _.find(course.steps, {'code': currentStepCode});

        if (currentStep.nextStep === 'test' && course.status === 'finish') {
            this.openStep(course.id, 'homePage');
        } else {
            this.openStep(course.id, currentStep.nextStep);
        }
    }

    startCourse() {
        this.setState({currentStepCode: 'one'});
    }

    showWrongQuestionBlocks() {
        this.setState({
                wrongQuestionBlocks: this.state.testResult.wrongQuestionBlocks
            }
        );
        this.closeModal();
    }

    parseTestResult(result) {
        if (result.pass === true) {
            this.setState({successModalOpen: true, showSendButton: false});
        } else {
            this.setState(
                {
                    failModalOpen: true,
                    testResult: result,
                    showSendButton: false
                }
            );
        }
    };

    submitTest(event) {
        event.preventDefault();
        const formData = new FormData(event.target);

        axios(`${apiHost}/education/course/${this.state.course.id}/submit/test`,
            {
                method: "post",
                data: formData,
                withCredentials: true
            }
        ).then(res => {
            this.parseTestResult(res.data);
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }

    closeModal() {
        this.setState({responseModalOpen: false, failModalOpen: false});
    }


    repeatTest() {
        this.setState({wrongQuestionBlocks: [], testResult: []});
        this.closeModal();
    }

    backToEducation() {
        this.setState({currentStepCode: 'homePage'});
        this.closeModal();
    }

    renderHomePage() {
        const course = this.state.course;

        return (
            <>
                <h2 className="page-title page-title--breadcrumbs">{course.title}</h2>
                <ul className="breadcrumbs">
                    <li><NavLink to="/lk/education">Обучение</NavLink></li>
                    <li>></li>
                    <li><NavLink to="/lk/education/courses">Интерактивное обучение</NavLink></li>
                </ul>

                <div className="fw-full mx-2">
                    <div className="md:flex blue-light-box mx-2 mb-4 course-description">
                        <div className="md:flex-shrink-0 md:w-1/4">
                            <img src={course.image} className="mr-6 mb-6"/>
                        </div>
                        <div className="mt-4 md:mt-0 md:ml-6">
                            <div className="md:float-left">
                                <h3 className="p-0 course-description__title">
                                    Описание обучения
                                </h3>
                            </div>
                            <div className="text-left mt-2 md:text-right md:float-right md:-m-1">
                                {
                                    course.status === 'start' &&
                                    <Button onClick={(e) => this.startCourse(e)}
                                            className="btn-thin btn-blue uppercase">Начать обучение</Button>
                                }
                            </div>
                            <div className="clearfix"/>
                             <p className="mt-5 course-description__text"
                                dangerouslySetInnerHTML={{__html: course.description}} />
                        </div>
                    </div>

                    {course.steps
                        ?
                            <ul className="course-steps">
                                {
                                    course.steps.map((step, index) => {
                                        const stateClass = step.allow && 'course-steps__item--active sweep-to-right',
                                            numberStep = ++index;
                                        return <li onClick={(e) => this.goToStep(e)}
                                                   key={`course-steps-${step.code}`}
                                                   className={`course-steps__item ${stateClass}`} data-step={step.code}>
                                            <span>{numberStep}. {step.title}</span>
                                            {
                                                step.allow
                                                    ? <i className="icon-check-mark"/>
                                                    : <i className="icon-alert-triangle"/>
                                            }

                                        </li>;
                                    })
                                }
                            </ul>

                        : null
                    }

                    {course.status === 'finish' &&
                        <div className="text-essilor-orange text-4xl uppercase mb-5 mx-2 mt-10">
                            <i className="icon-trophy text-6xl mr-5"></i>
                            Курс пройден!
                        </div>
                    }
                </div>
            </>
        );
    }

    renderStep(stepCode) {
        const { course } = this.state;
        const step = _.find(course.steps, {'code': stepCode});
        let content = null;
        const startTime = Date.now();

        if (step.type === 'presentation') {
            content = <Iframe
                offsetWidth={document.querySelector('.Menu').clientWidth + 50}
                offsetHeight={250}
                src={step.content.src}
            />;
        } else if (step.type === 'video') {
            content = (
                <>
                    <ReactPlayer url={step.content.src} playing controls/>
                    <div className="mt-2">{step.content.description}</div>
                </>
            )
        } else if (step.type === 'gif') {
            content = <img src={step.content.src} />;
        } else if (step.type === 'questions') {

            content = (
                <form onSubmit={(e) => this.submitTest(e)}>
                    <input type="hidden" name="startTime" value={startTime} />
                    <Tests questions={step.content.questions} wrongQuestionBlocks={this.state.wrongQuestionBlocks}/>

                    <Button type="submit" className="btn-thin btn-blue mb-8">Проверить</Button>
                </form>
            );
        }

        return (
            <>
                <h2 className="page-title page-title--breadcrumbs">{course.title}</h2>
                <ul className="breadcrumbs">
                    <li><a href="/lk/education">Обучение</a></li>
                    <li>></li>
                    <li><a href="/lk/education/courses">Интерактивное обучение</a></li>
                    <li>></li>
                    <li><a href={`/lk/education/courses/${course.code}`}>{course.title}</a></li>
                    <li>></li>
                    <li>{step.title}</li>
                </ul>

                <div className="w-full mb-20">
                    <div>{content}</div>
                </div>
                <div className="absolute bottom-0 left-0 w-full bg-white p-5 course-step-navigation">
                    <Button onClick={(e) => this.goPrevStep(e)}
                            className="btn-thin btn-grey float-left">Вернуться</Button>
                    { stepCode !== 'test' ?
                        <Button onClick={(e) => this.goNextStep(e)}
                                className="btn-thin btn-blue float-right">Далее</Button>
                        : null
                    }
                </div>

                <Modal isOpen={this.state.successModalOpen} onClose={() => this.backToEducation()} hideCloseButton={true}
                       boxStyle={{maxWidth: '37rem'}}>
                    <div className="text-center">
                        <h1 className="text-4xl font-bold text-essilor-navy uppercase">Поздравляем!</h1>
                        <h2 className="text-xl mt-2  mb-6 font-bold text-essilor-navy">Вы успешно прошли тест!</h2>

                        <a className="text-essilor-light-navy underline"
                           href="#"
                           onClick={() => this.backToEducation()}>Вернуться в обучение</a>
                    </div>
                </Modal>

                <Modal isOpen={this.state.failModalOpen} onClose={() => this.showWrongQuestionBlocks()} hideCloseButton={true}
                       boxStyle={{maxWidth: '37rem'}}>
                    <div className="text-center">

                        <h1 className="text-3xl font-bold text-essilor-navy uppercase">Тестирование не пройдено!</h1>

                        <p className="text-xl mt-2  mb-6 text-essilor-navy">
                            Попробуйти изучить материалы лучше и пройти тестирование ещё раз!</p>

                        <div className="flex flex-col sm:flex-row justify-around mb-6">

                            <Button className="btn btn-blue btn-narrow w-56" style={{fontWeight: 'normal'}}
                                    onClick={() => this.repeatTest()}>Пройти тест ещё раз</Button>

                            <Button className="btn btn-grey btn-narrow w-56"
                                    onClick={() => this.showWrongQuestionBlocks()}
                                    style={{fontWeight: 'normal'}}
                            >Показать ответы</Button>
                        </div>

                        <a className="text-essilor-light-navy underline" href="#"
                           onClick={() => this.backToEducation()}>Вернуться в обучение</a>
                    </div>
                </Modal>
            </>
        );
    }


    render() {
        const {currentStepCode} = this.state;

        if (currentStepCode === 'homePage') {
            return this.renderHomePage();
        } else {
            return this.renderStep(currentStepCode);
        }
    }
}

export default Page;

import React from 'react';
import {NavLink} from 'react-router-dom';
import Icon from '../../icons/';
import Button from "../../components/UI/Button/Button";


const Page = props => (
    <div className="min-h-screen">
        <div className="container max-w-6xl mx-auto text-center flex flex-col">

            <div style={{fontWeight: 700, fontSize: '12rem'}}>404</div>
            <div className="mb-10 text-3xl">Страница не найдена</div>

            <div className="my-14">
                <Button to="/" className="btn-blue">
                    Перейти на главную
                </Button>
            </div>


            <NavLink to="/" className="pt-8">
                <Icon name="logo" fill="#3A84D0" className="mx-auto max-w-8/9 my-10" />
            </NavLink>
        </div>

    </div>
);

export default Page;
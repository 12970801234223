import React from 'react';
import Modal from "../components/modal/modal";
import {connect} from "react-redux";
import store from "../store/store";
import Button from "../components/UI/Button/Button";

class FormErrors extends React.Component {


    closeModal() {
        store.dispatch({
            type: 'closeFailModal'
        });
    }

    render() {
        return (
            <Modal isOpen={this.props.failModalOpen} onClose={this.closeModal}
                   boxStyle={{width: '40rem', maxWidth: '90%'}}
                   hideCloseButton>
                <p className="font-bold mb-8 -mt-5 text-red-700 text-xl">Пожалуйста, заполните правильно следующие поля:</p>
                <ul>
                    {
                        Object.values(this.props.formErrors).map(
                            (error, index) => <li key={`fem-${index}`}><b>{error.title}</b> - {error.errorText}</li>
                        )
                    }
                </ul>

                <div className="mt-12 flex justify-center">
                    <Button type="submit" className="btn btn-blue" onClick={this.closeModal}>
                        Понятно
                    </Button>
                </div>
            </Modal>
        )
    }
};

const mapStateToProps = (state) => {
    return {
        failModalOpen: state.form.failModalOpen,
        formErrors: state.form.formErrors,
    }
}

export default connect(mapStateToProps)(FormErrors);


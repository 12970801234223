import React from 'react';
import {NavLink} from 'react-router-dom';

import PageTrainerCourses from './education-trainer-courses';
import PageTrainerCourse from './education-trainer-course-detail';
import PageTrainerCourseHomework from './education-trainer-course-detail-homework';
import PageTrainerCourseVideo from './education-trainer-course-detail-video';
import NotFound from '../../404/404'

class Page extends React.Component {

    render() {
        const path = this.props.location.pathname.split('/');

        let page;
        const currentPage = path[4];

        switch (currentPage) {
            case undefined:
                page = <PageTrainerCourses {...this.props}/>;
                break;
            case 'course':
                const type = path[6];

                if (type === 'homework') {
                    page = <PageTrainerCourseHomework {...this.props}/>;
                } else if (type === 'video') {
                    page = <PageTrainerCourseVideo {...this.props}/>;
                } else {
                    page = <PageTrainerCourse {...this.props}/>;
                }
                break;
            default:
                return (<NotFound/>)
        }

        return (<>{page}</>);
    }
}


export default Page;


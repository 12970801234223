import React from 'react';
import '../sales-stat.scss';


class StatTableCell extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    getClassWrapper(one, two) {
        if (one == 0 || two == 0) {
            return '';
        }

        const result = (one/two - 1) * 100;

        if (result > 0) {
            return  'sales-stat-cell-wrapper-green';
        }

        if (result < 0) {
            return  'sales-stat-cell-wrapper-red';
        }

        return '';
    }

    calculateCount(one, two) {

        if (one == 0 || two == 0) {
            return '-';
        }

        return `${this.formatter((one/two - 1) * 100)}%`;
    }

    formatter(count) {
        const formatter = new Intl.NumberFormat('ru-RU', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });

        return formatter.format(count);
    }

    render() {

        return (
            <td className={`sales-stat-cell-wrapper`}>
                <div className='sales-stat-cell-wrapper-tip'>
                    {`${this.props.title_one} - ${this.props.count_one}`}
                    <br/>
                    {`${this.props.title_two} - ${this.props.count_two}`}
                </div>
                <span className={this.getClassWrapper(this.props.count_one, this.props.count_two)}>
                    {this.calculateCount(this.props.count_one, this.props.count_two)}
                </span>
            </td>
        );
    }
}

export default StatTableCell;
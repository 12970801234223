import React from "react";
import _ from 'lodash';

class Pagination extends React.Component {

    pages = [];

    constructor(props) {
        super(props);

        this.state = {
            currentPage: parseInt(this.props.currentPage) || 1,
            totalPages: Math.ceil(this.props.totalPages) || 1,
            pages: []
        }

    }

    buildPages() {
        let totalPages = this.state.totalPages,
            currentPage = this.state.currentPage,
            start = 1,
            breakPointFirst = 3,
            breakPointSecond = 3,
            useBreakPoints = totalPages > 8,
            pages = [],

            styleArrow = this.props.invertColor ? 'pagination-arrow' : 'pagination-arrow',
            styleArrowActive = this.props.invertColor ? 'bg-light-blue text-white' : 'bg-light-blue text-white',
            stylePage = this.props.invertColor ? 'pagination-page' : 'pagination-page',
            stylePageActive = this.props.invertColor ? 'bg-light-blue text-white' : ' bg-light-blue text-white';
        ;

        if (totalPages === 1) {
            return;
        }
        if (totalPages > 1) {
            pages.push({
                title: <small><i className="icon-arrow-l"/></small>,
                active: currentPage > 1,
                selected: false,
                className: `${styleArrow} ${currentPage > 1 ? styleArrowActive : ''}`,
                page: currentPage - 1
            })
        }

        if (currentPage > 3 && currentPage <= totalPages - 3) {
            breakPointFirst = 1;
            breakPointSecond = totalPages - 1;
        } else if (currentPage < 3 || currentPage >= totalPages - 3) {
            breakPointFirst = 3;
            breakPointSecond = totalPages - 3;
        } else if (currentPage  ===  3) {
            breakPointFirst = currentPage + 1;
            breakPointSecond = totalPages - 3;
        }



        for (let i = start; i <= totalPages; i++) {

            if (useBreakPoints) {
                if (currentPage < breakPointFirst && i > breakPointFirst && i <= breakPointSecond) {
                    continue;
                } else if (currentPage > breakPointFirst && i > breakPointFirst && i <= breakPointSecond) {
                    if (i < currentPage - 1) {
                        continue
                    }
                }
            }

            pages.push({
                title: i,
                active: true,
                selected: currentPage === i,
                className: currentPage === i ? stylePageActive : stylePage,
                page: i
            });

            if (useBreakPoints) {
                if (currentPage > breakPointFirst && i > breakPointFirst && i <= breakPointSecond) {
                    if (i > currentPage && breakPointSecond + 1 > i) {
                        i = breakPointSecond;
                    } else if (i <= currentPage - 1) {
                        continue
                    }
                }

                if (breakPointFirst === i || breakPointSecond === i) {
                    pages.push({
                        title: '...',
                        active: false,
                        selected: false,
                        className: '',
                        page: i
                    });
                }
            }
        }

        if (totalPages > 1) {
            pages.push({
                title: <small><i className="icon-arrow"/></small>,
                active: currentPage !== totalPages,
                selected: false,
                className: `${styleArrow} ${currentPage !== totalPages ? styleArrowActive : ''}`,
                page: currentPage + 1
            })
        }

        this.setState({pages})
    }

    componentDidMount() {
        this.buildPages();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.currentPage !== prevState.currentPage) {
            this.buildPages();
        }

        if (!_.isEqual(prevProps.totalPages, this.props.totalPages)) {
            this.setState({totalPages:  Math.ceil(this.props.totalPages) || 1})
        }

        if (this.state.totalPages !== prevState.totalPages) {
            this.buildPages();
        }
    }

    render() {

        if (this.state.totalPages === 1) {
            return '';
        }

        return (
            <div className={this.props.className}>
                <ul className="flex pl-0 list-none rounded my-2">
                    {
                        this.state.pages.map((item, index) =>
                            <li className={`relative block py-2 px-3 leading-tight mr-2 rounded flex ${item.className} ${item.active ? `cursor-pointer` : ``}`}
                                onClick={
                                    (e) => {
                                        e.preventDefault();
                                        if (item.active) {
                                            this.setState({currentPage: item.page});

                                            if (typeof this.props.onNavigate === 'function') {
                                                return this.props.onNavigate(item.page);
                                            }
                                        }
                                    }
                                }
                                key={`nav-${index}`}>
                                <a className="no-underline font-bold flex items-center">
                                    {item.title}
                                </a>
                            </li>
                        )
                    }
                </ul>
            </div>
        )
    }
}

export default Pagination;

import React from 'react';
import {NavLink, Redirect} from 'react-router-dom';
import './main.scss';
import News from '../../components/windget-news-scroll/widget-news-scroll';
import Layout from '../../layouts/unauthorized';
import Button from "../../components/UI/Button/Button";


const Page = props => {
    return (
        <Layout>
            <div
                className="flex min-h-screen flex-wrap md:flex-no-wrap md:flex-row flex-col-reverse main-page">
                <div className="lg:w-1/4 md:w-1/2 md:gradient-blue px-6 flex flex-col">
                    <div className="px-4 text-center h-full flex flex-col justify-center py-6">
                        <div className="text-left mt-auto">
                            <h1 className="pb-3 text-essilor-blue text-xl uppercase">
                                Добро пожаловать на портал Essilor PRO Business!</h1>

                            <p className="pb-3">Приглашаем Вас стать участником программы нового формата!</p>

                            <p className="pb-3">
                                Essilor PRO Business - это уникальная информационно-обучающая платформа, на которой Вы
                                сможете:
                            </p>

                            <ul className="list-disc list-inside mb-1 list-essilor">

                                <li className="mt-1">быть в курсе актуальных новостей оптического рынка,</li>

                                <li className="mt-1">получить материалы для развития профессиональных знаний и
                                    навыков,
                                </li>

                                <li className="mt-1">получить обучающую информацию в новом эффективном формате,</li>

                                <li className="mt-1">обучиться эффективным бизнес-практикам,</li>

                                <li className="mt-1">первыми узнавать о новинках,</li>

                                <li className="mt-1">в режиме онлайн отслеживать результаты своей работы и определять
                                    зоны своего развития и роста.
                                </li>
                            </ul>

                            <p className="pb-3">Это и многое другое поможет открыть новые возможности Вашего
                                бизнеса!</p>
                        </div>
                        <Button to="/signin" className="btn-blue uppercase mt-auto">
                            Войти в личный кабинет
                        </Button>
                    </div>
                </div>
                <div className="lg:w-3/4 md:w-1/2 background-main relative">
                    <div className="logo-energy hidden md:absolute md:visible">
                        <img className="md:w-3/5 max-w-full hidden md:block"
                             src="/assets/resources/logo-pro-business-white.png"/>
                        <img className="md:w-3/5 max-w-full md:hidden"
                             src="/assets/resources/logo-pro-business-blue.png"/>
                    </div>
                </div>
            </div>
        </Layout>
    )
};

export default Page;
import React from 'react';
import { NavLink } from 'react-router-dom';
import './table.scss';
import _ from 'lodash';
import Pagination from "../pagination/pagination";
import moment from 'moment';
import queryString from 'query-string';
import {apiHost} from "../../common/config";
import axios from "axios";
import store from "../../store/store";


class CustomTable extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            tableData: this.props.tableData,
            sortColumn: 'date',
            sortDirection: 'asc',
            currentPage: 1
        }
    }

    sort(event, code){
        event.preventDefault();
        const {tableData, sortDirection} = this.state;
        const {headers} = this.props;
        const cloneTableData = _.cloneDeep(tableData);
        const newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
        const sortColumn = _.find(headers, {'code': code});
        let orderedTableData = {};

        if (sortColumn.type === 'date') {
            orderedTableData = _.orderBy(
                cloneTableData,
                function(row) {
                    const dateMomentObject = moment(row[code], "DD.MM.YYYY");
                    return dateMomentObject.toDate();
                },
                newSortDirection
            );

        } else if (sortColumn.type === 'number') {
            orderedTableData = _.orderBy(
                cloneTableData,
                function(row) {
                    return parseInt(row[code]);
                },
                newSortDirection
            );
        } else {
            orderedTableData = _.orderBy(cloneTableData, code, newSortDirection);
        }

        this.setState({
            sortColumn: code,
            sortDirection: newSortDirection,
            tableData: orderedTableData
        });
    }

    changePage(page) {
        this.setState({currentPage: page});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.tableData, this.props.tableData)) {
            this.setState({tableData:  this.props.tableData})
        }
    }

    async exportStat(url) {
        let fileName = '';

        const blob = await axios.get(
            url,
            {
                withCredentials: true,
            }
        ).then(res => {
            fileName = res.headers.filename;
            return new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), res.data], {type: res.headers['content-type']})
        })
            .catch(error => {
                store.dispatch({
                    type: 'addResponseStatus',
                    status: error.response.data.error
                })
            });

        const link = document.createElement('a');


        link.download = fileName || 'download';
        link.href = URL.createObjectURL(blob);
        link.click();

        URL.revokeObjectURL(link.href);
    }

    renderTable() {
        const {tableData, sortDirection, sortColumn, currentPage} = this.state;
        const {headers} = this.props;
        const startSlice = (currentPage - 1) * this.props.perPage;
        const endSlice = startSlice + this.props.perPage;

        return (
            <>
                <div className="custom-table">
                    <table>
                        <thead>
                        <tr>
                            { headers.map(item => (
                                <th key={item.code}>
                                    <a onClick={(event) => this.sort(event, item.code)}>
                                        {item.caption}
                                        {
                                            item.code === sortColumn &&
                                            <i className={`sort-direction icon-${sortDirection === 'asc' ? 'arrow-t' : 'arrow-b'}`}></i>
                                        }
                                    </a>
                                </th>
                            ))}
                        </tr>
                        </thead>
                        <tbody>
                        { tableData.slice(startSlice, endSlice).map((row, index) =>(
                            <tr key={index}>
                                { headers.map((col, index) => {

                                    if (_.has(col, 'useCaption')) {
                                        const caption = `${col.code}Caption`;

                                        return (
                                            <td key={index}
                                                className={`${_.has(col, 'addStyleClass') ? col.addStyleClass : null}`}
                                                dangerouslySetInnerHTML={{__html: row[caption]}}></td>
                                        )
                                    } else {

                                        if (_.has(col, 'link')) {
                                            const link = `${col.code}Link`;

                                            return (
                                                <td key={index}>
                                                    <NavLink to={row[link]}>
                                                        {row[col.code]}
                                                    </NavLink>
                                                </td>
                                            )
                                        } else {
                                            return (
                                                <td key={index} className={`${_.has(col, 'addStyleClass') ? col.addStyleClass : null}`}>
                                                    {row[col.code]}
                                                </td>
                                            )
                                        }

                                    }

                                })}
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>

                <div className="clear-both w-full mb-6">
                    <Pagination totalPages={tableData.length / this.props.perPage}
                                invertColor={true}
                                onNavigate={(page) => this.changePage(page)} className="mt-6 float-left"/>
                    {
                        this.props.exportLink &&
                        <button className="btn btn-blue btn-narrow mt-8 float-right"
                                onClick={() => this.exportStat(this.props.exportLink)}>Выгрузить в Excel</button>
                    }
                </div>
            </>
        );
    }

    render() {

        return (
            this.props.showSpin
                ?
                    <div className="w-full md:w-3/4 flex justify-center">
                        <object type="image/svg+xml" data="/assets/resources/Infinity-2s-200px.svg">
                            <img src="/assets/resources/Infinity-2s-200px.svg" alt="Поиск ..." />
                        </object>
                    </div>
                : this.renderTable()
        );
    }
}

CustomTable.defaultProps = {
    showSpin: false,
    perPage: 10
};

export default CustomTable;

const initState = {
    failModalOpen: false,
    formErrors: {},
}

export default (state = initState, action) => {
    switch (action.type) {
        case 'openFailModal':
            return {
                ...state, failModalOpen: true, formErrors: action.formErrors
            };
        case 'closeFailModal':
            return {
                ...state, failModalOpen: false
            };
        default:
            return {...state}
    }
}

import React from 'react';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import MenuLink from './menu-link';
import {connect} from 'react-redux';
import './menu.scss';
import axios from "axios";
import {apiHost} from "../../common/config";
import qs from 'qs';
import store from '../../store/store';
import Modal from '../../components/modal/modal';
import Textarea from "../UI/Textarea/Textarea";
import Input from "../UI/Input/Input";
import FeedbackModal from "../feedback-modal/feedback-modal";

class Menu extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            items: [],
        };

    }

    componentWillMount() {
        axios.get(`${apiHost}/user/get/menu`,
            {
                withCredentials: true
            }

        )
        .then(res => {
            this.setState({items: res.data})
        })
        .catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }

    hideMenuAfterClick(link) {
        if (window.innerWidth <= 768) {
            store.dispatch({
                type: 'setMenuIsOpen'
            });
        }


        axios.get(
            `${apiHost}/activity/add/event/folder/open`,
            {
                withCredentials: true,
                params: {
                    link
                }
            }
        ).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }

    render() {
        const menuIsOpen = this.props.menuIsOpen ? '' : 'Menu-closed';

        return (
            <menu className={`w-1/4 Menu ${menuIsOpen}`}>
                <ul>
                    {
                        this.state.items.map(item => (
                            <li key={item.link}>
                                <MenuLink to={`/lk/${item.link}`}  onClick={() => this.hideMenuAfterClick(item.link)} exact>
                                    <i className={item.icon}/>
                                    <span>{item.displayName}</span>
                                </MenuLink>
                            </li>
                        ))
                    }

                </ul>
                <FeedbackModal/>

            </menu>
        );
    }
}

const mapStateToProps = state => {
    return {
        menuIsOpen: state.menu.menuIsOpen
    }
};

export default connect(mapStateToProps)(Menu);

import React from 'react';
import {NavLink} from 'react-router-dom';
import {apiHost} from '../../../common/config';
import store from '../../../store/store';
import Layout from '../../../layouts/authorized';
import Select from '../../../components/UI/Select/Select';
import Input from '../../../components/UI/Input/Input';
import Button from '../../../components/UI/Button/Button';
import Modal from '../../../components/modal/modal';
import CustomTable from '../../../components/table/table';

import Form from '../../../components/forms/forms';
import './permission.scss';
import axios from 'axios';

class Page extends Form {


    validationRules = {
        entity: {require: true, title: 'Выберите юридическое лицо'},
        type: {require: true, title: 'Выберите группу'},
        email: {require: true, title: 'Электронная почта для приглашения', email: true},
    }


    clickedType = '';

    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            responseError: '',

            legalEntity: [],
            selectedEntity: 0,
            selectedRole: '',
            selectedEmail: '',
            owners: [],
            consultants: [],
            showModalOpen: false,
            showModalText: '',
            sections: [
                {code: 'owner', caption: 'Владельцы'},
                {code: 'consultant', caption: 'Консультанты'}
            ],
            selectedSection: 'owner',
            tableData: [],
            tableHeaders: [
                {code: 'NAME', caption: 'ФИО', type: 'text'},
                {code: 'PERSONAL_PHONE', caption: 'Телефон', type: 'text'},
            ]
        }
    }

    componentDidMount = () => {
        axios.get(
            `${apiHost}/entity/getlist`,
            {
                withCredentials: true
            }
        ).then(res => {
            this.setState({legalEntity: res.data});
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    };

    handleChangeEntity = (selectedOption, event) => {
        this.removeError(event.name);

        axios.get(
            `${apiHost}/entity/getusers/${selectedOption.value}`,
            {
                withCredentials: true
            }
        ).then(res => {
            this.setState(
                {
                    owners: res.data.OWNER_USERS || [],
                    consultants: res.data.DOCTOR_USERS || [],
                    tableData: res.data.OWNER_USERS || []
                }
                );
        });

        this.setState({selectedEntity: selectedOption.value});
    };

    handleChangeRole = (selectedOption, event) => {
        this.removeError(event.name);

        this.setState({
            selectedOption: selectedOption.value,
            selectedRole: selectedOption.value
        });
    }

    handleChangeEmail(event) {
        this.removeError(event.target.name);

        this.setState({
            selectedEmail: event.target.value
        });
    }

    submitRequest = (formData) => {
        this.requestUrl = `${apiHost}/entity/send/invite`;
        super.submitRequest.call(this, formData);
    }



    submitRequestCalllback = (res) => {

    }


    showLink () {
        const {selectedEntity, selectedRole, selectedEmail} = this.state;
        const formData = new FormData();
        formData.append('email', selectedEmail);
        formData.append('entity', selectedEntity);
        formData.append('type', selectedRole);

        axios(
            `${apiHost}/entity/get/invite/link`,
            {
                method: 'post',
                data: formData,
                withCredentials: true
            }
        ).then(res => {
            this.setState({
                showModalOpen: true,
                showModalText: res.data
            });

        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }

    closeModal() {

        this.setState({showModalOpen: false});
    }

    selectSection(event, section) {
        event.preventDefault();
        const {consultants, owners} = this.state;

        if (section === 'consultant') {
            this.setState({tableData: consultants});
        } else {
            this.setState({tableData: owners});
        }

        this.setState({selectedSection: section});
    }

    render() {
        const {selectedEntity, selectedRole, selectedEmail, selectedSection} = this.state;
        const activeShowLink = (selectedEntity && selectedRole && selectedEmail) ? false : true;

        return (
            <Layout>

                <ul className="submenu">
                    <li className="active">
                        <NavLink to="/lk/permissions" exact>Выдать доступ</NavLink>
                    </li>
                    <li>
                        <NavLink to="/lk/permissions/statistic" exact>Статистика</NavLink>
                    </li>
                </ul>

                <h2 className="page-title">Выдать доступ</h2>


                <form onSubmit={this.submitHandler.bind(this)} method="post" className="w-full">
                    <div className="w-full md:max-w-3/4">
                        <div className="w-full md:flex md:justify-between">
                            <div className="md:w-1/2 md:mr-5">
                                <Select
                                    name="entity"
                                    label="Выберите юридическое лицо"
                                    placeholder="Выберите..."
                                    errors={this.state.errors}
                                    options={this.state.legalEntity}
                                    className="w-full"
                                    onChange={this.handleChangeEntity}
                                />
                            </div>

                            <div className="md:w-1/2  md:ml-5">
                                <Select
                                    name="type"
                                    label="Выберите группу"
                                    placeholder="Выберите..."
                                    errors={this.state.errors}
                                    options={[{
                                        "value": "owner",
                                        "label": "Владелец, Помощник Владельца"
                                    },
                                        {
                                            "value": "consultant",
                                            "label": "Консультант, Врач"
                                        }]}
                                    className="w-full"
                                    onChange={this.handleChangeRole}
                                />
                            </div>
                        </div>

                        <div className="w-full md:flex md:justify-between">
                            <div className="md:w-1/2 md:mr-5">
                                <Input label="Электронная почта для приглашения"
                                       placeholder="Введите email" name="email"
                                       errors={this.state.errors}
                                       onChange={(event) => this.handleChangeEmail(event)}/>
                            </div>

                            <div className="md:w-1/2  md:ml-5"></div>
                        </div>

                        <div className="w-full md:flex md:justify-between">
                            <div className="md:w-1/2 md:mr-5">
                                <Button type="submit" className="btn btn-blue w-full">
                                    Отправить ссылку
                                </Button>
                            </div>

                            <div className="md:w-1/2  md:ml-5">
                                <Button className="btn btn-blue w-full"
                                    disabled={activeShowLink}
                                    onClick={() => this.showLink()}
                                >
                                    Показать ссылку
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>

                { selectedEntity > 0 &&
                    <>
                        <h3 className="text-white mt-16 mb-0">Уже зарегистрированы по данному юр.лицу:</h3>

                        <div className="sections-menu">
                            {
                                this.state.sections.map(section => {
                                        const isActive = selectedSection === section.code ? 'active' : '';

                                        return (
                                            <a href="#" className={`sections-menu__item ${isActive}`}
                                               key={`section-${section.code}`}
                                               onClick={(event) => this.selectSection(event, section.code)}>{section.caption}</a>
                                        )
                                    }
                                )
                            }
                        </div>

                        <div className="w-full">

                            {  this.state.tableData.length > 0 &&
                            <CustomTable
                                headers={this.state.tableHeaders}
                                tableData={this.state.tableData}
                            />
                            }

                        </div>
                    </>
                }

                <Modal isOpen={this.state.showModalOpen} onClose={() => this.closeModal()} hideCloseButton={true}
                       boxStyle={{maxWidth: '38rem'}}>
                    <div className="text-center">
                        <h2 className="text-xl mt-2  mb-6 font-bold text-essilor-navy uppercase">Новый доступ</h2>
                        <p className="text-center">
                            Ссылка на регистрацию для пользователя <b>{this.state.selectedEmail}</b> -
                            <span className="break-all">{this.state.showModalText}</span>
                        </p>

                        <Button className="btn btn-blue btn-narrow w-56 mt-10" style={{fontWeight: 'normal'}}
                                onClick={() => this.closeModal()}>Закрыть</Button>
                    </div>
                </Modal>
            </Layout>
        );
    }
}



export default Page;

import React from "react";

const ProfileProgressBarSvg = (props) => {

    const width = props.width || 369.8,
        endPoint = width/3,
    currentPosition = props.currentPosition;

    const arrowFirst = <path className={currentPosition === 1 ? `ppbl-active` : `ppbl`}
                        d={`M9.32,2H${endPoint - 35.6}a10,10,0,0,1,7.6,3.5l25.64,30a10,10,0,0,1,0,13l-25.64,30a10,10,0,0,1-7.6,3.5H9.32A9.32,9.32,0,0,1,0,70.68V9.32A9.32,9.32,0,0,1,9.32,2Z`}
                             transform={`translate(2)`}/>;

    const arrowSecond = <path  className={currentPosition === 1 ? `ppbl-inactive` : (currentPosition === 2 ? `ppbl-active` : `ppbl`)}
                               d={`M0,2H${endPoint + 15}c2.9,0,5.7,1.3,7.6,3.5l25.6,30c3.2,3.7,3.2,9.3,0,13l-25.6,30c-1.9,2.2-4.7,3.5-7.6,3.5H0
l29.1-33.4c3.3-3.8,3.3-9.4,0-13.1L0,2z`} transform={`translate(${endPoint - 30})`}/>;

    const arrowThird = <path className={currentPosition === 3 ? `ppbl-active` : `ppbl-inactive`}
                             d={`M0,2H${endPoint - 35.6 + 15}c2.9,0,5.7,1.3,7.6,3.5l25.6,30c3.2,3.7,3.2,9.3,0,13l-25.6,30c-1.9,2.2-4.7,3.5-7.6,3.5H0
l29.1-33.4c3.3-3.8,3.3-9.4,0-13.1L0,2z`} transform={`translate(${2*endPoint + 2 - 15})`}/>;

    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
             x="0px" y="0px" viewBox={`0 0 ${width + 10} 90`}>
            <style type="text/css">
                {`.ppbl {fill:url(#ppbl-gradient); stroke: #3A84D0; stroke-width: 2px}`}
                {`.ppbl-active {fill:url(#ppbl-active-gradient); filter:url(#ppbl-filter-shadow); stroke: #2253A0; stroke-width: 3px}`}
                {`.ppbl-inactive {fill:#DBDADA; stroke:#B2B2B2; stroke-width: 2px}`}
            </style>
            <filter id="ppbl-filter-shadow" height="130%">
                <feGaussianBlur in="SourceAlpha" stdDeviation="3"/>
                <feOffset dx="2" dy="2" result="offsetblur"/>
                <feComponentTransfer>
                    <feFuncA type="linear" slope="0.5"/>
                </feComponentTransfer>
                <feMerge>
                    <feMergeNode/>
                    <feMergeNode in="SourceGraphic"/>
                </feMerge>
            </filter>
            <linearGradient id="ppbl-gradient" gradientUnits="userSpaceOnUse" x1="184.9231" y1="85.2756" x2="184.9231"
                            y2="5.2756" gradientTransform="matrix(1 0 0 -1 0 85.2756)">
                <stop  offset="0" style={{stopColor: '#3A84D0'}}/>
                <stop  offset="1" style={{stopColor: '#3A84D0'}}/>
            </linearGradient>
            <linearGradient id="ppbl-active-gradient" gradientUnits="userSpaceOnUse" x1="184.9231" y1="85.2756" x2="184.9231"
                            y2="5.2756" gradientTransform="matrix(1 0 0 -1 0 85.2756)">
                <stop  offset="0" style={{stopColor: '#2253A0'}}/>
                <stop  offset="1" style={{stopColor: '#2253A0'}}/>
            </linearGradient>
            <g>
                {
                    currentPosition === 1
                        ? <>{arrowSecond} {arrowThird} {arrowFirst}</>
                            : currentPosition === 2
                                ? <>{arrowFirst} {arrowThird} {arrowSecond}</>
                                    : <>{arrowFirst} {arrowSecond} {arrowThird}</>

                }
            </g>
        </svg>


    )
};

export default ProfileProgressBarSvg;

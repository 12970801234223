import React from 'react';
import './education.scss';
import Iframe from "../../../components/iframe/iframe";
import Button from "../../../components/UI/Button/Button"


class Page extends React.Component {


    render() {
        return (
            <>
                <h3 className="text-white uppercase text-lg mb-6">Наше обучение / Eyezen</h3>

                <div className="w-full flex">
                    <Iframe
                        offsetWidth={document.querySelector('.Menu').clientWidth + 50}
                        offsetHeight={250}
                        src="https://essilor.crm3d.ru/iframes/detailing/eyezen_20200131_Library/index.html"/>
                </div>
                <Button to="/lk/education/tests/eyezen" className="btn-blue mt-4">Перейти к тестированию</Button>
            </>
        );
    }
}


export default Page;


import React from 'react'
import './InputFile.scss';
import queryString from 'query-string';

class InputFile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            fileNames: [],
            hasError: false
        }
    }

    fillInput(event) {
        let fileNames = Array.prototype.map.call(event.target.files, file => file['name']);

        this.setState({fileNames});

        if (this.props.onChange) {
            this.props.onChange(event);
        }
    }


    render() {
        let hasError = false,
            propsNew = {...this.props};
        propsNew.id = this.props.name;

        if (this.props.hasOwnProperty('errors') && this.props.errors.hasOwnProperty(this.props.name) && this.props.errors[this.props.name]) {
            propsNew.className += ' invalid';
            hasError = true;
        }

        return (
            <div className={`InputFile ${propsNew.className}`}>
                <label htmlFor={propsNew.id}>
                    {propsNew.label}
                    {hasError ? (<i className="icon-alert-triangle error-icon"/>) : ''}
                </label>
                <div className="md:flex w-full">
                    <div className={`InputFile__input ${propsNew.showInfoAboutFileSize ? 'mr-5 w-2/4' :  'w-full'}`}>
                        <input {...propsNew} className="input-file" type="file"
                               onChange={(event) => this.fillInput(event)}
                               ref={this.props.fileref}
                        />
                        <div className="input-fake">
                            {
                                this.state.fileNames.map(
                                    (filename, index)  => (
                                        <div key={`input-fake__text_${index}`} className="input-fake__text">{filename}</div>
                                    )
                                )
                            }
                            <div className="btn btn-blue input-fake__button">Выбрать</div>
                        </div>
                    </div>
                    {
                        propsNew.showInfoAboutFileSize &&
                        <div className="w-2/4 pt-2"> *максимальный допустимый размер файла 3 Мб </div>
                    }
                </div>
            </div>
        )
    }
}

export default InputFile;

import React from 'react';
import {NavLink} from 'react-router-dom';
import {apiHost} from '../../../common/config';
import store from '../../../store/store';
import Layout from '../../../layouts/authorized';
import Select from '../../../components/UI/Select/Select';
import CustomTable from '../../../components/table/table.2.0';
import axios from 'axios';
import _ from "lodash";

class Page extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            responseError: '',
            headers: [
                {code: 'regionalManager', caption: 'Региональный менеджер', type: 'text'},
                {code: 'saleForce', caption: 'Sales Force', type: 'text'},
                {code: 'legalEntity', caption: 'Партнёр', type: 'text'},
                {code: 'promoName', caption: 'Название акциии', type: 'text'},
                {code: 'promoDate', caption: 'Срок проведения акции', type: 'text'},
                {code: 'inviteSent', caption: 'Приглашение отправлено', type: 'text'},
                {code: 'inviteJoined', caption: 'Участвует (дата подтверждения)', type: 'text'},
                {code: 'condition', caption: 'Механика', type: 'text'},
                {code: 'optic', caption: 'Участвующая оптика', type: 'text'},
            ],
            sf: [
                {value: '', label: 'Все'},
            ],
            rm: [
                {value: '', label: 'Все'},
            ],
            tableData: [],
            entities: [
                {value: '', label: 'Все'},
            ],
            promoName: [
                {value: '', label: 'Все'},
            ],
            inviteSent: [
                {value: '', label: 'Все'},
                {value: 'Y', label: 'Отправлено'},
                {value: 'N', label: 'Не отправлено'},
            ],
            inviteJoined: [
                {value: '', label: 'Все'},
                {value: 'Y', label: 'Участвует'},
                {value: 'N', label: 'Не участвует'},
            ],
            filter: {
                legalEntity: '',
                sf: '',
                rm: '',
                promoName: [],
                inviteSent: '',
                inviteJoined: '',
                limit: 10,
                page: 1,
            },
            searching: true,
            allRecords: 0,
            selectedPromo: null
        }
    }

    componentDidMount = () => {
        this.getEntities();
        this.getSalesForceUsers();
        this.getRegionalManagerUsers();
        const promoPromise = this.getPromo();

        Promise.all([promoPromise])
            .then(() => {
                const {filter, promoName} = this.state;
                const firstPromo = promoName[1];

                this.handleChangePromoName(firstPromo);
                filter.promoName = firstPromo;
                this.getTableData(filter);
            });
    };

    getEntities() {
        axios.get(
            `${apiHost}/entity/getlist`,
            {withCredentials: true,}
        ).then(res => {
            const { entities } = this.state;
            this.setState({
                entities: [...entities, ...res.data]
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });

    }

    getSalesForceUsers() {
        axios.get(
            `${apiHost}/user/get/sf/users`,
            {withCredentials: true,}
        ).then(res => {
            const { sf } = this.state;
            this.setState({
                sf: [...sf, ...res.data]
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });

    }

    getRegionalManagerUsers() {
        axios.get(
            `${apiHost}/user/get/rm/users`,
            {withCredentials: true,}
        ).then(res => {
            const { rm } = this.state;
            this.setState({
                rm: [...rm, ...res.data]
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });

    }
    getPromo() {
        return (
            axios.get(
                `${apiHost}/promotions/get/list`,
                {
                            withCredentials: true,
                            params: {
                                excludeInactive: false
                            }
                        }
            ).then(res => {
                const { promoName } = this.state;
                const sortedPromos = _.sortBy(res.data, ['activeStatus', 'name']);

                this.setState({
                    promoName: [
                        ...promoName,
                        ...sortedPromos.map(item => {
                            const promoName = item.activeStatus === 'active' ? item.name : item.name + ' (не активная)';
                            return {
                                value: item.id, label: promoName
                            }
                        })
                    ]
                });
            }).catch(error => {
                store.dispatch({
                    type: 'addResponseStatus',
                    status: error.response.data.error
                })
            })
        )
    }

    getTableData(filter) {
        axios.get(
            `${apiHost}/promotions/get/activity`,
            {
                withCredentials: true,
                params: {
                    filter: JSON.stringify(filter)
                }
            }
        ).then(res => {
            this.setState({
                tableData: res.data.data,
                allRecords: res.data.allRecords
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        }).finally(() => {
            this.setState({
                searching: false
            });
        });
    }

    handleChangeEntity(selectedOption) {
        const {filter} = this.state;
        filter.legalEntity = selectedOption.value;
        this.setState({filter});
    }

    handleChangeSalesForce(selectedOption) {
        const {filter} = this.state;
        filter.sf = selectedOption.value;
        this.setState({filter});
    }

    handleChangeRegionalManager(selectedOption) {
        const {filter} = this.state;
        filter.rm = selectedOption.value;
        this.setState({filter});
    }

    handleChangePromoName(selectedOption) {
        if (selectedOption) {
            const {filter} = this.state;
            if (Array.isArray(selectedOption)) {
                filter.promoName = selectedOption.map((option) => option.value);
            } else {
                filter.promoName = selectedOption.value;
            }
            this.setState({filter, selectedPromo: selectedOption});
        }
    }

    handleChangeInviteSent(selectedOption) {
        const {filter} = this.state;
        filter.inviteSent = selectedOption.value;
        this.setState({filter});
    }

    handleChangeInviteJoin(selectedOption) {
        const {filter} = this.state;
        filter.inviteJoined = selectedOption.value;
        this.setState({filter});
    }

    handleChangePage = (page) => {
        const {filter} = this.state
        filter.page = page;
        this.setState({
            searching: true
        });

        this.getTableData(filter);
    }

    handleSearch = () => {
        const {filter} = this.state;
        filter.page = 1;

        this.setState({
            searching: true
        });

        this.getTableData(filter);
    }

    render() {

        return (
            <Layout>

                <h2 className="page-title">Статистика по Акциям</h2>

                <div className="w-full md:flex md:justify-between md:w-3/4">
                    <div className="md:w-4/12 md:mr-5">
                        <Select
                            name="rm"
                            label="Выберите РМ"
                            placeholder="Все"
                            options={this.state.rm}
                            className="w-full"
                            onChange={(selectedOption) => this.handleChangeRegionalManager(selectedOption)}
                        />
                    </div>
                    <div className="md:w-4/12 md:ml-5 md:mr-5">
                        <Select
                            name="sf"
                            label="Выберите Sales Force"
                            placeholder="Все"
                            options={this.state.sf}
                            className="w-full"
                            onChange={(selectedOption) => this.handleChangeSalesForce(selectedOption)}
                        />
                    </div>
                    <div className="md:w-4/12 md:ml-5">
                        <Select
                            name="entity"
                            label="Выберите партнёра"
                            placeholder="Все"
                            options={this.state.entities}
                            className="w-full"
                            onChange={(selectedOption) => this.handleChangeEntity(selectedOption)}
                        />
                    </div>
                </div>
                <div className="w-full md:flex md:justify-between md:w-3/4">
                    <div className="md:w-4/12 md:mr-5">
                        <Select
                            name="promoName"
                            label="Выберите акцию"
                            placeholder="Все"
                            options={this.state.promoName}
                            value={this.state.selectedPromo}
                            className="w-full"
                            onChange={(selectedOption) => this.handleChangePromoName(selectedOption)}
                            isMulti={true}
                        />
                    </div>
                    <div className="md:w-4/12 md:ml-5 md:mr-5">
                        <Select
                            name="inviteSent"
                            label="Статус приглашения"
                            placeholder="Все"
                            options={this.state.inviteSent}
                            className="w-full"
                            onChange={(selectedOption) => this.handleChangeInviteSent(selectedOption)}
                        />
                    </div>
                    <div className="md:w-4/12 md:ml-5">
                        <Select
                            name="inviteJoined"
                            label="Статус участия"
                            placeholder="Все"
                            options={this.state.inviteJoined}
                            className="w-full"
                            onChange={(selectedOption) => this.handleChangeInviteJoin(selectedOption)}
                        />
                    </div>
                </div>

                <div className="w-full md:flex md:justify-between md:w-3/4 mb-10 mt-3">
                    <div className="md:w-6/12 md:mr-5">
                        <div className="w-full md:flex md:justify-between">
                            <button disabled={this.state.searching ? "disabled" : ""}
                                    className="btn btn-narrow btn-blue w-full md:w-6/12 md:mr-5"
                                    onClick={this.handleSearch}>Найти</button>

                        </div>
                    </div>

                    <div className="md:w-6/12 md:ml-5"></div>
                </div>

                {  this.state.tableData.length > 0 &&
                <CustomTable
                    headers={this.state.headers}
                    tableData={this.state.tableData}
                    showSpin={this.state.searching}
                    allRecords={this.state.allRecords}
                    perPage={this.state.filter.limit}
                    currentPage={this.state.filter.page}
                    onChangePage={this.handleChangePage}
                    exportLink={`${apiHost}/export/promotions?filter=${JSON.stringify(this.state.filter)}`}
                />
                }
            </Layout>
        );
    }
}



export default Page;

import React from 'react';
import Checkbox from "../UI/Checkbox/Checkbox";
import './table-intity.scss';
import TableEntityAbstract from "./table-entity.abstract";
import Pagination from "../pagination/pagination";
import Button from "../UI/Button/Button";

class TableEntitySaleForce extends TableEntityAbstract {

    constructor(props) {
        super(props);
    }

    render() {
        const checkedAll = this.checkCheckedAll(),
            startSlice = (this.state.page - 1) * this.perPage,
            endSlice = startSlice + this.perPage;

        return (
            this.props.data.length > 0 &&
            <>
                <div className="table-entity">
                    {
                        this.props.data.map((item, index) =>
                            <input
                                key={`tiesf-${index}`}
                                name={`userIds[${item.userId}]`}
                                value={this.state.checkboxes[index] ? 1 : 0}
                                type="hidden"/>
                        )
                    }
                    <table>
                        <thead>
                        <tr>
                            <td className="table-entity__first-column">
                                <Checkbox checked={checkedAll} onChange={() => this.setAll(!checkedAll)}/>
                            </td>
                            <td>Все клиенты</td>
                            <td className="text-center w-1">Приглашение<br/>отправлено</td>
                            <td className="text-center w-1">Участвует</td>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.props.data.slice(startSlice, endSlice).map((item, index) => {
                                index = startSlice + index;
                                    return (
                                        <tr key={`tre-${index}`}>
                                            <td className="text-center">
                                                <Checkbox checked={!!this.state.checkboxes[index]}
                                                          name={`userIds[${item.userId}]`}
                                                          onChange={() => this.handleCheckedItem(index)}
                                                          value={this.state.checkboxes[index] ? 1 : 0}/>
                                            </td>
                                            <td>{index + 1}. {item.entityName} ({item.userName})</td>
                                            <td className="text-center">
                                                {item.isSent && <i className="icon-check text-4xl"/>}
                                            </td>
                                            <td className="text-center">
                                                {item.isJoined && <i className="icon-check text-4xl"/>}
                                            </td>
                                        </tr>
                                    )
                                }
                            )
                        }
                        </tbody>
                    </table>
                </div>

                <Pagination totalPages={this.props.data.length / this.perPage} invertColor={true}
                            onNavigate={(page) => this.changePage(page)} className="mt-6"/>

                <Button type="submit"
                        className="btn btn-blue mt-6"
                        disabled={this.state.checkboxes.filter(item => item).length === 0}>
                    Отправить</Button>
            </>
        )
    }
}

export default TableEntitySaleForce;

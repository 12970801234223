import {combineReducers} from 'redux';
import news from './news';
import user from './user';
import menu from './menu';
import axios from './axios';
import form from './form';

export default combineReducers(
    {
        news,
        user,
        menu,
        axios,
        form,
    }
);

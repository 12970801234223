import React from 'react';
import Layout from '../../../layouts/authorized';
import SalesUploadForm from './sales-upload-form';
import Modal from "../../../components/modal/modal";
import Button from "../../../components/UI/Button/Button";

class Page extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showModalOpen: false
        }
    }

    componentDidMount() {
        const shouldBeShowModal = localStorage.getItem('shouldBeShowModal');

        if (shouldBeShowModal === 'yes') {
            localStorage.setItem('shouldBeShowModal', 'no');
            this.setState({showModalOpen: true});
        }
    }

    closeModal() {
        this.setState({showModalOpen: false});
    }

    render() {

        return (
            <Layout>
                <h2 className="page-title">Продажи</h2>

                <div className="blue-light-box rounded-lg max-w-2/3 mb-20">
                    <h3 className="custom-grey-color text-2xl">
                        <i className="icon-alert-circle text-essilor-green mr-5 text-5xl"></i>
                        Акция: Удвоенные баллы за линзы Varilux Comfort Max
                    </h3>
                    <p>
                        В период с 7 февраля по 31 марта 2022 г. за проданные
                        мультифокусные линзы Varilux Comfort Max будут начисляться удвоенные баллы.
                    </p>
                </div>

                <SalesUploadForm role="consultant" />

                <Modal isOpen={this.state.showModalOpen} onClose={() => this.closeModal()} hideCloseButton={true}
                       boxStyle={{maxWidth: '48rem'}}>
                    <div className="text-center">
                        <h2 className="text-xl mt-2  mb-6 font-bold text-essilor-navy uppercase">
                            Акция по линзам Varilux<br/>comfort max
                        </h2>
                        <p className="text-left">
                            В период <b>7 февраля по 31 марта 2022 г. </b>
                            за проданные мультифокусные линзы Varilux Comfort
                            Max будут начисляться удвоенные баллы.
                        </p>
                    </div>
                    <div className="text-center mt-10">
                        <Button onClick={() => this.closeModal()}
                                className="btn-blue uppercase">
                            Понятно
                        </Button>
                    </div>
                </Modal>
            </Layout>
        );
    }
}



export default Page;
import React from "react";

const ProfileProgressCupSvg = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75 75">
            <defs>
                <style>
                    {`
                    .ppcup-white, .cls-3 {
                        fill: #fff;
                    }
                    
                    .ppcup-dark-grey {
                        fill: #B2B2B2;
                    }

                    .ppcup-orange-gradient {
                        fill: url(#ppcup-orange-gradient);
                        filter:url(#ppbcup-filter-shadow);
                    }
                    .ppcup-inactive {
                        fill: #DBDADA;
                    }
                    `}
                </style>
                <linearGradient id="ppcup-orange-gradient" x1="18.47" y1="30.87" x2="51.83" y2="57.94"
                                gradientTransform="matrix(1, 0, 0, -1, 0, 79.28)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" style={{stopColor: '#56A7DB'}}/>
                    <stop offset="1" style={{stopColor: '#56A7DB'}}/>
                </linearGradient>

                <filter id="ppbcup-filter-shadow" height="130%">
                    <feGaussianBlur in="SourceAlpha" stdDeviation="1.5"/>
                    <feOffset dx="1" dy="1" result="offsetblur"/>
                    <feComponentTransfer>
                        <feFuncA type="linear" slope="0.2"/>
                    </feComponentTransfer>
                    <feMerge>
                        <feMergeNode/>
                        <feMergeNode in="SourceGraphic"/>
                    </feMerge>
                </filter>
            </defs>
            <circle className="ppcup-white" cx="35" cy="35" r="34.66"/>
            <circle className={props.inactive ? `ppcup-inactive` : `ppcup-orange-gradient`} cx="35" cy="35" r="21.88"/>
            <g>
                <path className={props.inactive ? `ppcup-dark-grey` : `ppcup-white`}
                      d="M41.87,24.58a2.92,2.92,0,0,0-.71.08l.18.72a2.17,2.17,0,0,1,2.73,2c0,3.11-3.69,5.4-5.23,5.83l.21.71c2-.55,5.79-3.11,5.79-6.54A2.91,2.91,0,0,0,41.87,24.58Z"/>
                <path className={props.inactive ? `ppcup-dark-grey` : `ppcup-white`}
                      d="M25.27,27.42a2.16,2.16,0,0,1,2.73-2l.19-.72a3,3,0,0,0-3.69,2.76c0,3.43,3.83,6,5.79,6.54l.22-.71C29,32.82,25.27,30.53,25.27,27.42Z"/>
                <path className={props.inactive ? `ppcup-dark-grey` : `ppcup-white`}
                      d="M36,37.37H33.33a.37.37,0,0,0-.38.37v1.4a.35.35,0,0,0,.14.28.38.38,0,0,0,.24.09h.09a6.24,6.24,0,0,1,2.51,0,.41.41,0,0,0,.32-.08.36.36,0,0,0,.15-.28v-1.4A.38.38,0,0,0,36,37.37Z"/>
                <path className={props.inactive ? `ppcup-dark-grey` : `ppcup-white`}
                      d="M39.88,45.15H29.46a.37.37,0,0,0-.38.37v1.36a.38.38,0,0,0,.38.37H39.88a.38.38,0,0,0,.38-.37V45.52A.37.37,0,0,0,39.88,45.15Z"/>
                <path className={props.inactive ? `ppcup-dark-grey` : `ppcup-white`}
                      d="M39.21,40H30.13a.37.37,0,0,0-.38.37v5.2a.38.38,0,0,0,.38.37h9.08a.39.39,0,0,0,.39-.37v-5.2A.38.38,0,0,0,39.21,40Zm-.38,5.2H30.52V40.69h8.31Z"/>
                <path className={props.inactive ? `ppcup-dark-grey` : `ppcup-white`}
                      d="M37.67,41.42h-6a.38.38,0,0,0-.39.37V44a.38.38,0,0,0,.39.37h6a.38.38,0,0,0,.38-.37V41.79A.38.38,0,0,0,37.67,41.42Z"/>
                <path className={props.inactive ? `ppcup-dark-grey` : `ppcup-white`}
                      d="M36,35.75H33.32c-.65,0-.72,1.21-.72,1.22s.08,1.13.73,1.13H36c.64,0,.71-1.12.71-1.13S36.68,35.75,36,35.75Zm-.14,1.62h-2.4a2.11,2.11,0,0,1-.1-.4,2.23,2.23,0,0,1,.12-.48h2.37A2.14,2.14,0,0,1,36,37,1.55,1.55,0,0,1,35.87,37.37Z"/>
                <path className={props.inactive ? `ppcup-dark-grey` : `ppcup-white`}
                      d="M36.09,38.78a7.15,7.15,0,0,0-2.84,0c-1.28.26-2,.84-2,1.54a.38.38,0,0,0,.39.37h6.15a.38.38,0,0,0,.38-.37C38.13,39.62,37.37,39,36.09,38.78ZM32.26,40a3,3,0,0,1,1.16-.45,6.24,6.24,0,0,1,2.51,0,3.17,3.17,0,0,1,1.16.45Z"/>
                <path className={props.inactive ? `ppcup-dark-grey` : `ppcup-white`}
                      d="M41,23.62a.66.66,0,0,1,.46.19.64.64,0,0,1,.19.45c0,.26,0,.52,0,.77l0,.68a19.17,19.17,0,0,1-1.85,7.23c-.15.29-.31.58-.47.85a7.29,7.29,0,0,1-2.64,2.68.37.37,0,0,1-.18,0,.32.32,0,0,1-.14,0H33a.42.42,0,0,1-.33,0,7.19,7.19,0,0,1-2.63-2.68c-.17-.27-.33-.56-.48-.85a19.17,19.17,0,0,1-1.84-7.23c0-.22,0-.45,0-.68s0-.52,0-.78a.63.63,0,0,1,.19-.44.68.68,0,0,1,.47-.19Zm-1.72,1.94c0-.11,0-.22,0-.34l.77,0c0,.12,0,.23,0,.35A17,17,0,0,1,38.45,32c-.13.24-.26.49-.41.72a6.09,6.09,0,0,1-1.69,1.89L35.9,34a5.5,5.5,0,0,0,1.48-1.67c.13-.21.26-.44.37-.66A16.45,16.45,0,0,0,39.29,25.56Z"/>
                <path className={props.inactive ? `ppcup-inactive` : `ppcup-orange-gradient`}
                      d="M39.29,25.56c0-.11,0-.22,0-.34l.77,0c0,.12,0,.23,0,.35A17,17,0,0,1,38.45,32c-.13.24-.26.49-.41.72a6.09,6.09,0,0,1-1.69,1.89L35.9,34a5.5,5.5,0,0,0,1.48-1.67c.13-.21.26-.44.37-.66A16.45,16.45,0,0,0,39.29,25.56Z"/>
            </g>
            <path className={props.inactive ? `ppcup-inactive` : `ppcup-orange-gradient`}
                  d="M35,0A35,35,0,1,0,70,35,35,35,0,0,0,35,0Zm0,65.62A30.62,30.62,0,1,1,65.62,35,30.62,30.62,0,0,1,35,65.62Z"/>
        </svg>
    )
};

export default ProfileProgressCupSvg;

import React from 'react';
import Layout from '../../../layouts/authorized';
import axios from "axios";
import Button from "../../../components/UI/Button/Button";
import {apiHost, host} from "../../../common/config";
import store from "../../../store/store";
import RadioInput from "../../../components/UI/Radio/Radio";
import _ from 'lodash';
import ResizeDetector from "react-resize-detector";
import './podbirator.scss';
import ReactPlayer from 'react-player'
import { goToTop } from 'react-scrollable-anchor'

class Page extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            steps: {},
            currentStep: '',
            currentLenses: [],
            nextStep: '',
            typeAnswer: '',
            ownerOrderLenses: [],
            courseStepNavWidth: '100%',
            detailPageCode: '',
            detailPageGroup: ''
        };

        this.radioInputYes = React.createRef();
        this.radioInputNo = React.createRef();
        this.layout = React.createRef();
    }

    componentDidMount() {
        axios.get(
            `${apiHost}/helper/get/questions`,
            {withCredentials: true}
        ).then(res => {
            this.setState({steps: res.data, currentStep: 'stepAddOne'});
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });

        axios.get(
            `${apiHost}/lenses/get/grouped/list`,
            {withCredentials: true}
        ).then(res => {
            this.setState({ownerOrderLenses: res.data});
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });

        setTimeout(() => {this.addActivity()}, 3000);
    }

    goStartStep() {
        this.setState({currentStep: 'stepAddOne', currentLenses: [], detailPageCode: '', detailPageGroup: ''});
    }

    goNextStep(nextStep, typeAnswer) {
        const {currentStep, steps, currentLenses} = this.state;
        this.radioInputYes.current.checked = false;
        this.radioInputNo.current.checked = false;

        if (_.has(steps, [currentStep, 'answers', typeAnswer, 'add'])) {
            currentLenses.push(...steps[currentStep]['answers'][typeAnswer]['add']);
        }

        this.setState({currentStep: nextStep, currentLenses});
    }


    handleAnswer(value, typeAnswer) {
        const nextStep = typeof value !== 'undefined' ? value : '';

        this.goNextStep(nextStep, typeAnswer);
    }


    openDetailPage(groupId, code) {
        this.setState({detailPageCode: code, detailPageGroup: groupId});
        goToTop();
    }

    renderFinishPage() {
        const {detailPageCode, detailPageGroup} = this.state;

        return (
            <>
                { !detailPageCode && !detailPageGroup
                    ?
                        <>
                            <h2 className="page-title">Наши персональные рекомендации</h2>
                            <div className="w-full flex flex-wrap mx-2">
                                {
                                    this.renderDesignAgeBlock()
                                }
                                {
                                    this.renderMultyCoverBlock()
                                }
                                {
                                    this.renderHarmfulLightBlock()
                                }
                                {
                                    this.renderGlareBlock()
                                }

                                <div className="fixed bottom-0 right-0 w-full bg-white p-5 course-step-navigation"
                                     style={{width: this.state.courseStepNavWidth}}>
                                    < Button onClick={() => this.goStartStep()}
                                             className="btn-thin btn-dark-blue float-left">Начать сначала</Button>
                                </div>
                            </div>
                        </>
                    :
                        this.renderDetailPage()
                }
            </>
        )
    }

    renderDesignAgeBlock() {
        const {currentLenses, ownerOrderLenses} = this.state;
        const group = ownerOrderLenses[0];

        if (_.includes(currentLenses, 'varilux') || _.includes(currentLenses, 'varilux-start')) {
            return this.renderSpecialOrderedLensesBlock(group);
        }

        return this.renderLensesBlock(group);
    }

    renderMultyCoverBlock() {
        const {ownerOrderLenses} = this.state;
        const group = ownerOrderLenses[1];

        return this.renderLensesBlock(group);
    }

    renderHarmfulLightBlock() {
        const {ownerOrderLenses} = this.state;
        const group = ownerOrderLenses[2];

        return this.renderLensesBlock(group);
    }

    renderGlareBlock() {
        const {ownerOrderLenses} = this.state;
        const group = ownerOrderLenses[3];

        return this.renderLensesBlock(group);
    }

    renderLensesBlock(group) {
        const {currentLenses} = this.state;
        const classModify = group.modifyByOwner ? `modify-by-owner-${group.code}` : '';

        return (
            <>
                <div className={`md:w-1/2 lenses-group ${group.code} ${classModify}`}
                     key={`group-${group.id}`}>
                    <h3 className="rounded-lg"
                        dangerouslySetInnerHTML={{__html: group.name}}
                    />
                    <div className="lenses rounded-lg blue-box">
                        {
                            group.lenses.filter(item => _.includes(currentLenses, item.code))
                                .map(item => {
                                    return (
                                        <div className="lens" key={`lense-${item.id}`}>
                                            <img alt={item.code} className="lens__img" src={item.img}/>
                                            <div className="mb-6 lens__content">
                                                <p className="text-white mb-5 lens__text">{item.text}</p>
                                                { item.text_detail &&
                                                    <a className="cursor-pointer lens__more"
                                                       onClick={() => this.openDetailPage(group.id, item.code)}>
                                                        Подробнее
                                                    </a>
                                                }

                                            </div>
                                            <div className="float-none"/>
                                        </div>

                                    )
                                })
                        }
                    </div>
                </div>
            </>
        );
    }

    renderSpecialOrderedLensesBlock(group) {
        const {currentLenses} = this.state;
        const classModify = `modify-by-owner-${group.code}`;
        let specialOrderedLenses = [];

        if (_.includes(currentLenses, 'varilux')) {
            specialOrderedLenses = ['varilux', 'varilux-start'];
        } else if (_.includes(currentLenses, 'varilux-start'))  {
            specialOrderedLenses = ['varilux-start', 'varilux', 'odnofokalnye-linzy'];
        }

        return (
            <>
                <div className={`md:w-1/2 lenses-group ${group.code} ${classModify}`}
                     key={`group-${group.id}`}>
                    <h3 className="rounded-lg"
                        dangerouslySetInnerHTML={{__html: group.name}}
                    />
                    <div className="lenses rounded-lg blue-box">
                        {
                            specialOrderedLenses.map(item => {
                                const lense = _.find(group.lenses, { 'code': item });

                                return (
                                    <div className="lens" key={`lense-${lense.id}`}>
                                        <img alt={lense.code} className="lens__img" src={lense.img}/>
                                        <div className="mb-6 lens__content">
                                            <p className="text-white mb-5 lens__text">{lense.text}</p>
                                            { lense.text_detail &&
                                                <a className="cursor-pointer lens__more"
                                                   onClick={() => this.openDetailPage(group.id, lense.code)}>
                                                    Подробнее
                                                </a>
                                            }
                                        </div>
                                        <div className="float-none"/>
                                    </div>

                                )
                            })
                        }
                    </div>
                </div>
            </>
        );
    }

    renderDetailPage() {
        const {ownerOrderLenses, detailPageCode, detailPageGroup} = this.state;
        const currentGroup = _.find(ownerOrderLenses, {'id': detailPageGroup});
        const detailPageData = _.find(currentGroup.lenses, { 'code': detailPageCode });

        return (
            <>
                <h3 className="page-title">{detailPageData.name}</h3>
                <div className="w-full mx-2 pb-24 podbirator-detail">
                    <div className="podbirator-detail__content">
                        <img alt={detailPageData.name} className="float-right" src={detailPageData.detail_img} />
                        <h3 className="podbirator-detail__title">{detailPageData.name}</h3>
                        <p className="podbirator-detail__under-title">{detailPageData.text_below_title}</p>
                        <p className="podbirator-detail__text mt-6" dangerouslySetInnerHTML={{__html: detailPageData.text_detail}} />
                        <div className="float-none"/>
                    </div>

                    { detailPageData.video &&
                        <>
                            <h3 className="page-title page-title--withoutborder page-title--second">
                                Видео о {detailPageData.name}
                            </h3>
                            <ReactPlayer width={'100%'} url={detailPageData.video} controls/>
                        </>
                    }
                </div>
                <div className="fixed bottom-0 right-0 w-full bg-white p-5 course-step-navigation"
                     style={{width: this.state.courseStepNavWidth}}>
                    < Button onClick={() => this.goBackToRecomendations()}
                             className="btn-thin btn-blue float-left mb-5 mr-5">Вернуться</Button>
                    <Button onClick={() => this.goStartStep()}
                            className="btn-thin btn-blue md:float-right">Пройти опрос ещё раз</Button>
                </div>
            </>
        )
    }

    checkCourseStepNav() {
        if (this.layout.current) {
            this.setState({courseStepNavWidth: this.layout.current.offsetWidth})
        }
    }

    addActivity()
    {
        axios.get(
            `${apiHost}/activity/add/event/use/helper`,
            {
                withCredentials: true,
            }
        ).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });
    }

    goBackToRecomendations() {
        this.setState({detailPageCode: '', detailPageGroup: ''});
    }

    render() {
        const {currentStep, steps, nextStep} = this.state;
        const step = _.has(steps, currentStep) ? steps[currentStep] : null;

        return (
            <Layout layoutContentRef={this.layout}>

                { (step && currentStep !== 'stepFinish') &&
                    <>
                        <h2 className="page-title">Подбиратор</h2>
                        <div className="w-full">
                            <div className="white-box box-shadow w-auto mb-6">
                                <div className="mb-6 white-box__header">
                                    <span>{step.question}</span>
                                </div>

                                <div >
                                    <RadioInput onClick={() => this.handleAnswer(step.answers.yes.go, 'yes')}
                                                ref={this.radioInputYes}
                                                name={currentStep}
                                                label={step.answers.yes.caption || 'Да'}/>
                                </div>
                                <div >
                                    <RadioInput onClick={() => this.handleAnswer(step.answers.no.go, 'no')}
                                                ref={this.radioInputNo}
                                                name={currentStep}
                                                label={step.answers.no.caption || 'Нет'}/>
                                </div>


                                <img className="mt-5" src={`${host}/files/helper${step.image}`} />
                            </div>
                        </div>
                        <div className="fixed bottom-0 right-0 w-full bg-light-navy p-5 course-step-navigation"
                            style={{width: this.state.courseStepNavWidth}}>
                            < Button onClick={() => this.goStartStep()}
                                    className="btn-thin btn-blue float-left">Начать сначала</Button>
                        </div>
                    </>
                }

                { currentStep === 'stepFinish' &&
                    this.renderFinishPage()
                }

                <ResizeDetector
                    handleWidth
                    onResize={() => this.checkCourseStepNav()}
                />
            </Layout>
        );
    }
}



export default Page;

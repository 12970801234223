import React from 'react';
import Layout from '../../../layouts/authorized';
import {apiHost} from '../../../common/config';
import axios from "axios";
import store from "../../../store/store";
import Select from "../../../components/UI/Select/Select";
import CustomTable from '../../../components/table/table.2.0';
import DatePickerRange from '../../../components/UI/DatePicker/DatePickerRange';

class Page extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            headers: [
                {code: 'regionalManager', caption: 'Региональный менеджер', type: 'text'},
                {code: 'saleForce', caption: 'Sales Force', type: 'text'},
                {code: 'date', caption: 'Дата', type: 'date'},
                {code: 'personalName', caption: 'ФИО', type: 'text'},
                {code: 'legalEntity', caption: 'Партнёр', type: 'text'},
                {code: 'legalAdresses', caption: 'Адрес', type: 'text'},
                {code: 'group', caption: 'Роль', type: 'text'},
                {code: 'source', caption: 'Источник', type: 'text'},
                {code: 'lens_group', caption: 'Группа', type: 'text'},
                {code: 'name', caption: 'Наименование', type: 'text'},
                {code: 'design', caption: 'Дизайн', type: 'text'},
                {code: 'covering', caption: 'Покрытие', type: 'text'},
                {code: 'count', caption: 'Кол-во линз', type: 'number'},
                {code: 'technology', caption: 'Технология', type: 'text'},
                {code: 'points', caption: 'Баллы', type: 'number'},
                {code: 'status', caption: 'Статус', type: 'text'},
            ],
            tableData: [],
            addresses: [
                {value: '', label: 'Все оптики'},
            ],
            sf: [
                {value: '', label: 'Все'},
            ],
            rm: [
                {value: '', label: 'Все'},
            ],
            entities: [
                {value: '', label: 'Все партнёры'},
            ],
            groups: [
                {value: '', label: 'Все роли'},
                {value: 'DOCTOR_USERS', label: 'Врач'},
                {value: 'CONSULTANT_USERS', label: 'Консультант'}
            ],
            filter: {
                legalEntity: '',
                group: '',
                address: '',
                sf: '',
                rm: '',
                dateStart: '',
                dateEnd: '',
                limit: 10,
                page: 1,
            },
            searching: true,
            allRecords: 0,
        }

    }

    componentDidMount() {
        const {filter} = this.state;
        this.getTableData(filter);
        this.getAddresses();
        this.getEntities();
        this.getSalesForceUsers();
        this.getRegionalManagerUsers();
    }

    getTableData(filter) {
        axios.get(
            `${apiHost}/sale/get/sales`,
            {
                withCredentials: true,
                params: {
                    filter: JSON.stringify(filter)
                }
            }
        ).then(res => {
            this.setState({
                tableData: res.data.data,
                allRecords: res.data.allRecords
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        }).finally(() => {
            this.setState({
                searching: false
            });
        });

    }

    getAddresses() {
        axios.get(
            `${apiHost}/entity/get/addresses/forSF`,
            {withCredentials: true,}
        ).then(res => {
            const { addresses } = this.state;
            this.setState({
                addresses: [...addresses, ...res.data]
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });

    }

    getEntities() {
        axios.get(
            `${apiHost}/entity/getlist`,
            {withCredentials: true,}
        ).then(res => {
            const { entities } = this.state;
            this.setState({
                entities: [...entities, ...res.data]
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });

    }

    getSalesForceUsers() {
        axios.get(
            `${apiHost}/user/get/sf/users`,
            {withCredentials: true,}
        ).then(res => {
            const { sf } = this.state;
            this.setState({
                sf: [...sf, ...res.data]
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });

    }

    getRegionalManagerUsers() {
        axios.get(
            `${apiHost}/user/get/rm/users`,
            {withCredentials: true,}
        ).then(res => {
            const { rm } = this.state;
            this.setState({
                rm: [...rm, ...res.data]
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        });

    }

    handleChangeGroup(selectedOption) {
        const {filter} = this.state;
        filter.group = selectedOption.value;
        this.setState({filter});
    }

    handleChangeAddress(selectedOption) {
        const {filter} = this.state;
        filter.address = selectedOption.value;
        this.setState({filter});
    }

    handleChangeEntity(selectedOption) {
        const {filter} = this.state;
        filter.legalEntity = selectedOption.value;
        this.setState({filter});
    }

    handleChangeSalesForce(selectedOption) {
        const {filter} = this.state;
        filter.sf = selectedOption.value;
        this.setState({filter});
    }

    handleChangeRegionalManager(selectedOption) {
        const {filter} = this.state;
        filter.rm = selectedOption.value;
        this.setState({filter});
    }

    handleChangeDatePickerRange = (startDate, endDate) => {
        const {filter} = this.state;
        filter.dateStart = startDate;
        filter.dateEnd = endDate;
        this.setState({filter});
    }

    handleChangePage = (page) => {
        const {filter} = this.state
        filter.page = page;
        this.setState({
            searching: true
        });

        this.getTableData(filter);
    }

    handleSearch = () => {
        const {filter} = this.state;
        filter.page = 1;

        this.setState({
            searching: true
        });

        this.getTableData(filter);
    }

    render() {

        return (
            <Layout>
                <h2 className="page-title">Продажи</h2>

                <div className="blue-light-box rounded-lg max-w-2/3 mb-20">
                    <h3 className="custom-grey-color text-2xl">
                        <i className="icon-alert-circle text-essilor-green mr-5 text-5xl"></i>
                        Акция: Удвоенные баллы за линзы Varilux Comfort Max
                    </h3>
                    <p>
                        В период с 7 февраля по 31 марта 2022 г. за проданные
                        мультифокусные линзы Varilux Comfort Max будут начисляться удвоенные баллы.
                    </p>
                </div>

                <div className="w-full md:flex md:justify-between md:w-3/4">
                    <div className="md:w-6/12 md:mr-5">
                        <Select
                            name="sf"
                            label="Выберите Sales Force"
                            placeholder="Все"
                            options={this.state.sf}
                            className="w-full"
                            onChange={(selectedOption) => this.handleChangeSalesForce(selectedOption)}
                        />
                    </div>
                    <div className="md:w-6/12 md:ml-5">
                        <Select
                            name="rm"
                            label="Выберите РМ"
                            placeholder="Все"
                            options={this.state.rm}
                            className="w-full"
                            onChange={(selectedOption) => this.handleChangeRegionalManager(selectedOption)}
                        />
                    </div>
                </div>

                <div className="w-full md:flex md:justify-between md:w-3/4">
                    <div className="md:w-6/12 md:mr-5">
                        <Select
                            name="addresses"
                            label="Выберите партнёра"
                            placeholder="Все партнёры"
                            options={this.state.entities}
                            className="w-full"
                            onChange={(selectedOption) => this.handleChangeEntity(selectedOption)}
                        />
                    </div>
                    <div className="md:w-6/12 md:ml-5">
                        <Select
                            name="addresses"
                            label="Выберите адрес"
                            placeholder="Все оптики"
                            options={this.state.addresses}
                            className="w-full"
                            onChange={(selectedOption) => this.handleChangeAddress(selectedOption)}
                        />
                    </div>
                </div>

                <div className="w-full md:flex md:justify-between md:w-3/4">
                    <div className="md:w-6/12 md:mr-5">
                        <Select
                            name="groups"
                            label="Выберите роль"
                            placeholder="Все роли"
                            options={this.state.groups}
                            className="w-full"
                            onChange={(selectedOption) => this.handleChangeGroup(selectedOption)}
                        />
                    </div>

                    <div className="md:w-6/12 md:ml-5">
                        <DatePickerRange onChange={this.handleChangeDatePickerRange} />
                    </div>
                </div>
                <div className="w-full md:flex md:justify-between md:w-3/4 mb-10 mt-3">
                    <div className="md:w-6/12 md:mr-5">
                        <div className="w-full md:flex md:justify-between">
                            <button disabled={this.state.searching ? "disabled" : ""}
                                    className="btn btn-narrow btn-blue w-full md:w-6/12 md:mr-5"
                                    onClick={this.handleSearch}>Найти</button>

                        </div>
                    </div>

                    <div className="md:w-6/12 md:ml-5"></div>
                </div>


                {  this.state.tableData.length > 0 &&
                <CustomTable
                    headers={this.state.headers}
                    tableData={this.state.tableData}
                    showSpin={this.state.searching}
                    allRecords={this.state.allRecords}
                    perPage={this.state.filter.limit}
                    currentPage={this.state.filter.page}
                    onChangePage={this.handleChangePage}
                    exportLink={`${apiHost}/export/sales?filter=${JSON.stringify(this.state.filter)}`}
                />
                }

            </Layout>
        );
    }
}

export default Page;

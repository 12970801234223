import React from 'react';

import Modal from '../../components/modal/modal';
import './notifications.scss';
import {apiHost} from "../../common/config";
import _ from 'lodash';
import axios from "axios";
import store from "../../store/store";
import NotificationItem from "./notification-item";

class Notifications extends React.Component {

    requestUrl = `${apiHost}/feedback/send`;

    constructor(props) {
        super(props);

        this.state = {
            notifications: [],
            notificationModalOpen: false,
            showButtonClose: true
        };
    }

    componentDidMount() {
        this.getNotifications();
    }

    getNotifications() {
        axios.get(
            `${apiHost}/notification/all`,
            {
                withCredentials: true,
            }
        ).then(res => {
            const {filter} = this.state;

            this.setState({
                notifications: res.data,
            });
        }).catch(error => {
            store.dispatch({
                type: 'addResponseStatus',
                status: error.response.data.error
            })
        }).finally(() => {});

    }

    logNotifications() {
        const { notifications } = this.state;

        if (notifications.length > 0) {

            notifications
                .filter((notification) => notification.status === 'new')
                .map((notification) => {
                    axios.get(
                        `${apiHost}/notification/log/view`,
                        {
                            withCredentials: true,
                            params: {
                                id: notification.id
                            }
                        }
                    ).catch(error => {
                        store.dispatch({
                            type: 'addResponseStatus',
                            status: error.response.data.error
                        })
                    }).finally(() => {});
                });

        }
    }

    showModal(event) {
        event.preventDefault();
        this.setState({notificationModalOpen: true});
        this.logNotifications();
    }

    closeModal = () => {
        this.setState({
            notificationModalOpen: false,
        })
    };

    getNewNotifications(notifications) {
        return notifications.filter((notification) => notification.status === 'new');
    }

    getOldNotifications(notifications) {
        return notifications.filter((notification) => notification.status !== 'new');
    }

    render() {
        const { notifications } = this.state;
        const newNotifications = this.getNewNotifications(notifications);
        const oldNotifications = this.getOldNotifications(notifications);

        return (
            <>
                <a onClick={(event) => this.showModal(event)} className="notifications-bell px-3 cursor-pointer">
                    <i className="icon-bell text-2xl md:text-lg"/>
                    {
                        newNotifications.length > 0 &&
                        <span className='notifications-bell-count'>{newNotifications.length}</span>
                    }
                </a>

                <Modal isOpen={this.state.notificationModalOpen} onClose={this.closeModal} title="Уведомления" >
                    {
                        newNotifications.length > 0 &&
                            <>
                                <h3 className="custom-grey-color text-xl uppercase">Новые</h3>
                                {
                                    newNotifications.map((notification) => {
                                        return (
                                            <NotificationItem
                                                title={notification.title}
                                                text={notification.text}
                                                link={notification.link}
                                                date={notification.date}
                                                section={notification.section}
                                                location={notification.location}
                                            />
                                        );
                                    })
                                }
                            </>
                    }

                    <h3 className="custom-grey-color text-xl uppercase">Прочитанные</h3>
                    {
                        oldNotifications.length > 0 &&
                        oldNotifications.map((notification) => {
                            return (
                                <NotificationItem
                                    title={notification.title}
                                    text={notification.text}
                                    link={notification.link}
                                    date={notification.date}
                                    section={notification.section}
                                    location={notification.location}
                                />
                            );
                        })
                    }
                </Modal>
            </>
        );
    }
}

export default Notifications;
